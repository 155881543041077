<template>
  <div class="expander">
    <div class="query mt-3 p-3 container" id="query-container">
      <div class="statSelectUi">
        <svg
          @click="$emit('remove')"
          width="1em"
          height="1em"
          viewBox="0 0 16 16"
          id="close"
          class="bi bi-x btn-outline-danger x-button"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
        <div class="row mt-1 mb-1">
          <div class="col-sm">
            <div class="form-group">
              <label>Sport</label>
              <select
                class="form-select"
                v-model="selected.sport"
                @change="getYears(); changeSportClear();"
              >
                <option value="1698">Baseball</option>
                <option value="1699">Men's Basketball</option>
                <option value="1707">Women's Basketball</option>
                <option value="1701">Football</option>
                <option value="1711">Soccer</option>
                <option value="1712">Softball</option>
                <option value="1706">Men's Volleyball</option>
                <option value="1716">Women's Volleyball</option>
              </select>
            </div>
          </div>

          <div class="col-sm">
            <div class="form-group">
              <label>Category</label>
              <select
                id="category"
                class="form-select"
                v-model="selected.category"
                @change="changeCatagoryClear(); getAthleteOrGamesOrCoach(); getSeasonStats();"
              >
                <option value="athlete">Athlete</option>
                <!-- <option value="coach">Coach</option> -->
                <option value="game">Game</option>
                <option value="season-stats">Season Stats</option>
              </select>
            </div>
          </div>

          <div class="col-sm">
            <div class="form-group">
              <label>Year</label>
              <select
                id="year"
                class="form-select"
                v-model="selected.year"
                @change="getAthleteOrGamesOrCoach(); getSeasonStats();"
              >
                <option
                  v-for="(y, index) in years"
                  :key="index"
                  v-bind:value="y.Year"
                >
                  {{ y.Year }}
                </option>
              </select>
            </div>
          </div>

          <div
            v-if="selected.category === 'athlete'"
            @change="changeAthleteGameCoachClear(); getData();"
            class="col-sm"
          >
            <div class="form-group">
              <label>Athlete</label>
              <select class="form-select" v-model="selected.athleteNid">
                <option
                  v-for="(ath, index) in athletes"
                  :key="index"
                  v-bind:value="ath.nid"
                >
                  {{ ath.field_last_name }} {{ ath.field_first_name }}
                </option>
              </select>
            </div>
          </div>

          <div
            v-if="selected.category === 'game'"
            @change="changeAthleteGameCoachClear(); getData();"
            class="col-sm"
          >
            <div class="form-group">
              <label>Game</label>
              <select class="form-select" v-model="selected.gameNid">
                <option
                  v-for="(game, index) in games"
                  :key="index"
                  v-bind:value="game.nid"
                >
                  {{ game.title }}
                </option>
              </select>
            </div>
          </div>

          <!-- <div
            v-if="selected.category === 'coach'"
            @change="changeAthleteGameCoachClear(); getData();"
            class="col-sm"
          >
            <div class="form-group">
              <label>Coach</label>
              <select class="form-select" v-model="selected.coach">
                <option
                  v-for="(coach, index) in coaches"
                  :key="index"
                  v-bind:value="coach.nid"
                >
                  {{ coach.title }}
                </option>
              </select>
            </div>
          </div> -->

          <div class="col-sm">
            <div class="form-group">
              <label></label><br/>
              <button
                id="submit"
                type="submit"
                :disabled="!isBoxScoreTableReady && gamesRecordPlayerIn === null && seasonData === null"
                class="btn btn-outline-primary"
                @click="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="drupal-hide" id="header-main-div">
        <div v-if="showTable" >
          <br>
          <h1 class="header-main">{{this.header}}</h1>
          <br>
        </div>
      </div>
      <FootballAthlete
        ref="myFootball"
        v-if="selected.sport == '1701' && gamesRecordPlayerIn"
        :selected="selected"
        :gamesRecordPlayerIn="gamesRecordPlayerIn"
      />
      <VolleyballAthlete
        ref="myVolleyball"
        v-if="
          (selected.sport == '1706' || selected.sport == '1716') &&
          gamesRecordPlayerIn
        "
        :selected="selected"
        :gamesRecordPlayerIn="gamesRecordPlayerIn"
      />
      <BaseballAthlete
        ref="myBaseball"
        v-if="(selected.sport == '1698' || selected.sport == '1712') && gamesRecordPlayerIn"
        :selected="selected"
        :gamesRecordPlayerIn="gamesRecordPlayerIn"
      />
      <BasketballAthlete
        ref="myBasketball"
        v-if="(selected.sport == '1699' || selected.sport == '1707') && gamesRecordPlayerIn"
        :selected="selected"
        :gamesRecordPlayerIn="gamesRecordPlayerIn"
      />
      <SoccerAthlete
        ref="mySoccer"
        v-if="selected.sport == '1711' && gamesRecordPlayerIn"
        :selected="selected"
        :gamesRecordPlayerIn="gamesRecordPlayerIn"
      />
      <div v-if="showTable">
        <VolleyballBoxScore
          v-if="(selected.sport == '1706' || selected.sport == '1716') && stats"
          :selected="selected"
          :stats="stats"
        />
        <SoccerBoxScore
          v-if="selected.sport == '1711' && stats"
          :selected="selected"
          :stats="stats"
        />
        <FootballBoxScore
          v-if="selected.sport == '1701' && stats"
          :selected="selected"
          :stats="stats"
        />
        <SoftballBoxScore
          v-if="selected.sport == '1712' && stats"
          :selected="selected"
          :stats="stats"
        />
        <BaseballBoxScore
          v-if="selected.sport == '1698' && stats"
          :selected="selected"
          :stats="stats"
        />
        <MensBasketballBoxScore
          v-if="selected.sport == '1699' && stats"
          :selected="selected"
          :stats="stats"
        />
        <WomensBasketballBoxScore
          v-if="selected.sport == '1707' && stats"
          :selected="selected"
          :stats="stats"
        />
        <BaseballSeason
          v-if="(selected.sport == '1698' || selected.sport == '1712') && seasonData"
          :selected="selected"
          :seasonData="seasonData"
        />
        <VolleyballSeason
          v-if="(selected.sport == '1706' || selected.sport == '1716') && seasonData"
          :selected="selected"
          :seasonData="seasonData"
        />
        <SoccerSeason
          v-if="selected.sport == '1711' && seasonData"
          :selected="selected"
          :seasonData="seasonData"
        />
        <BasketballSeason
          v-if="(selected.sport == '1699' || selected.sport == '1707') && seasonData"
          :selected="selected"
          :seasonData="seasonData"
        />
        <FootballSeason
          v-if="selected.sport == '1701' && seasonData"
          :selected="selected"
          :seasonData="seasonData"
        />
      </div>
      <h1 v-if="!isStatsExist">There are no stats.</h1>
    </div>
  </div>
</template>

<script>
import FootballAthlete from '@/components/football_tables/FootballAthlete.vue'
import VolleyballAthlete from '@/components/volleyball_tables/VolleyballAthlete.vue'
import BaseballAthlete from '@/components/baseball_tables/BaseballAthlete.vue'
import BasketballAthlete from '@/components/basketball_tables/BasketballAthlete.vue'
import SoccerAthlete from '@/components/soccer_tables/SoccerAthlete.vue'
import VolleyballBoxScore from '@/components/boxscore_tables/VolleyballBoxScore.vue'
import SoccerBoxScore from '@/components/boxscore_tables/SoccerBoxScore.vue'
import FootballBoxScore from '@/components/boxscore_tables/FootballBoxScore.vue'
import SoftballBoxScore from '@/components/boxscore_tables/SoftballBoxScore.vue'
import BaseballBoxScore from '@/components/boxscore_tables/BaseballBoxScore.vue'
import MensBasketballBoxScore from '@/components/boxscore_tables/MensBasketballBoxScore.vue'
import WomensBasketballBoxScore from '@/components/boxscore_tables/WomensBasketballBoxScore.vue'
import BaseballSeason from '@/components/season_tables/Baseball.vue'
import VolleyballSeason from '@/components/season_tables/Volleyball.vue'
import SoccerSeason from '@/components/season_tables/Soccer.vue'
import BasketballSeason from '@/components/season_tables/Basketball.vue'
import FootballSeason from '@/components/season_tables/Football.vue'

export default {
  name: 'Query',
  props: ['tempYearsUrl', 'tempAthletesUrl', 'tempGamesUrl', 'tempCoachUrl'],
  data () {
    return {
      selected: {
        sport: undefined, // undefined for no input
        category: undefined,
        year: undefined,
        athleteNid: undefined,
        gameNid: undefined,
        coachNid: undefined
      },
      athletes: [],
      gamesRecordPlayerIn: null, // disable and enable the submit button
      stats: null,
      isBoxScoreTableReady: false,
      games: [],
      coaches: [],
      years: [],
      isStatsExist: true,
      seasonData: null,
      header: null,
      showTable: false,
      isDrupal: false
    }
  },
  components: {
    FootballAthlete,
    VolleyballAthlete,
    SoccerAthlete,
    BaseballAthlete,
    BasketballAthlete,
    FootballBoxScore,
    VolleyballBoxScore,
    SoccerBoxScore,
    SoftballBoxScore,
    BaseballBoxScore,
    MensBasketballBoxScore,
    WomensBasketballBoxScore,
    BaseballSeason,
    VolleyballSeason,
    SoccerSeason,
    BasketballSeason,
    FootballSeason
  },
  computed: {
    getSportId: {
      // setter
      set: function (newValue) {
        this.selected.sport = newValue
      }
    },
    getPlayerId: {
      // setter
      set: function (newValue) {
        this.selected.athleteNid = newValue
      }
    },
    getGameId: {
      // setter
      set: function (newValue) {
        this.selected.gameNid = newValue
      }
    }
  },
  async mounted () {
    console.log('begin mounted')
    if (document.getElementById('sportId')) {
      this.changeSportIdSetter()
      this.isDrupal = true
    }
    if (document.getElementById('playerId')) {
      this.selected.category = 'athlete'
      this.changePlayerIdSetter()
      this.isDrupal = true
    }
    if (document.getElementById('gameId')) {
      this.selected.category = 'game'
      this.changeGameIdSetter()
      this.isDrupal = true
    }
    if (this.isDrupal) {
      this.showTable = true
      console.log('isDrupal')
      if (document.getElementById('yearId') && document.getElementById('sportId')) {
        const header = document.getElementById('header-main-div')
        header.classList.remove('drupal-hide')
        this.selected.year = document.getElementById('yearId').value.toString()
        this.selected.category = 'season-stats'
        this.changeSportIdSetter()
        await this.getSeasonStats()
        this.submit()
      } else {
        await this.getData()
        this.submit()
      }
    }
  },
  methods: {
    changeSportIdSetter () {
      this.getSportId = document.getElementById('sportId').value.toString()
    },
    changePlayerIdSetter () {
      this.getPlayerId = document.getElementById('playerId').value.toString()
    },
    changeGameIdSetter () {
      this.getGameId = document.getElementById('gameId').value.toString()
    },
    async getSeasonStats () {
      this.seasonData = null
      this.showTable = false
      let nids = ''
      console.log('this is from getSeasonStats')
      console.log('this.selected.year: ' + this.selected.year)
      console.log(window.location.href)
      if (this.selected.category === 'season-stats' && this.selected.year && this.selected.sport) {
        var gamesURL = `https://byucougars.com/feeds/game/year/${this.selected.sport}/${this.selected.year}` // https://byucougars.com/feeds/game/year/1698/2019, fetch all baseball(1698) data in a year(2019)
        if (window.location.href.includes('dev') || window.location.href.includes('file') || window.location.href.includes('localhost')) {
          gamesURL = `https://byucougars.byu-dept-athletics-dev.amazon.byu.edu/feeds/game/year/${this.selected.sport}/${this.selected.year}`
        }
        const gameres = await fetch(gamesURL)
        const games = await gameres.json()
        for (let i = 0; i < games.length; i++) {
          if (i === 0) {
            nids = games[0].nid
          }
          nids = nids.concat(',', games[i].nid)
        }
        // console.log('nids', nids)
        var dataURL = `https://gamestats.byucougars.com/seasonstats/${nids}` // 'https://gamestats.byucougars.byu-dept-athletics-dev.amazon.byu.edu/seasonstats/gameNid, gameNid, ...'
        if (window.location.href.includes('dev') || window.location.href.includes('file') || window.location.href.includes('localhost')) {
          dataURL = `https://gamestats.byucougars.byu-dept-athletics-dev.amazon.byu.edu/seasonstats/${nids}`
        }
        const res = await fetch(dataURL)
        const data = await res.json()
        this.seasonData = data
        console.log('just assigned seasonData in getSeasonStats')
        // console.log('data', this.seasonData)
        this.isBoxScoreTableReady = true // temporarily set isBoxScoreTableReady to true to enable submit button
      }
      // console.log(this.selected)
    },
    changeSportClear () {
      // clear category option if sport change
      this.selected.category = undefined
      this.selected.athleteNid = undefined
      // clear gamesRecordPlayerIn array if sport change and disable the submit button
      this.gamesRecordPlayerIn = null
      // clear stats and gameRecords array if sport change and disable the submit button (isBoxScoreTableReady)
      this.stats = null
      this.gameRecords = null
      this.isBoxScoreTableReady = false
      // disable the stats checking table
      this.isStatsExist = true
      this.seasonData = null
      this.showTable = false
    },
    changeCatagoryClear () {
      this.stats = null
      this.seasonData = undefined
      this.isBoxScoreTableReady = false
      this.isStatsExist = true
    },
    changeAthleteGameCoachClear () {
      // clear old data and disable the submit button when data is not received.
      this.gamesRecordPlayerIn = null
      this.seasonData = null
      this.stats = null
      this.isStatsExist = true
      this.showTable = false
    },
    async getData () {
      console.log('begin getData')
      if (this.selected.athleteNid) {
        // ======= fetch from URL API =======
        var athletesUrl = `https://gamestats.byucougars.com/athlete/${this.selected.sport}/${this.selected.athleteNid}`
        if (window.location.href.includes('dev') || window.location.href.includes('file') || window.location.href.includes('localhost')) {
          athletesUrl = `https://gamestats.byucougars.byu-dept-athletics-dev.amazon.byu.edu/athlete/${this.selected.sport}/${this.selected.athleteNid}`
        }
        const res = await fetch(athletesUrl)
        const data = await res.json()
        this.gamesRecordPlayerIn = data
        console.log('gamesRecordPlayerIn', this.gamesRecordPlayerIn)
      }
      if (this.selected.gameNid) {
        // const gamesUrl = 'https://gamestats.byucougars.byu-dept-athletics-dev.amazon.byu.edu/boxscore/1290213'
        var gamesUrl = `https://gamestats.byucougars.com/boxscore/${this.selected.gameNid}`
        if (window.location.href.includes('dev') || window.location.href.includes('file') || window.location.href.includes('localhost')) {
          gamesUrl = `https://gamestats.byucougars.byu-dept-athletics-dev.amazon.byu.edu/boxscore/${this.selected.gameNid}`
        }
        const res = await fetch(gamesUrl)
        const data = await res.json()
        if (data === 'NoGo') { // need to be fixed
          this.statsExist()
        } else {
          this.stats = data[0]
          this.isBoxScoreTableReady = true
        }
      }
    },
    async submit () {
      console.log('begin submit')
      // show info if there is no stats for player
      this.statsExist()
      console.log('just finished this.statsExist()')
      if (this.gamesRecordPlayerIn && this.isStatsExist) {
        console.log('this.gamesRecordPlayerIn && this.isStatsExist')
        // call child function to clear and reorganize the data, and then show table
        if ((this.selected.sport === '1698' || this.selected.sport === '1712') && this.selected.athleteNid) {
          this.$refs.myBaseball.reorganizeGames()
        } else if ((this.selected.sport === '1699' || this.selected.sport === '1707') && this.selected.athleteNid) {
          this.$refs.myBasketball.reorganizeGames()
        } else if (this.selected.sport === '1701' && this.selected.athleteNid) {
          this.$refs.myFootball.reorganizeGames()
        } else if (this.selected.sport === '1711' && this.selected.athleteNid) {
          this.$refs.mySoccer.reorganizeGames()
        } else if ((this.selected.sport === '1706' || this.selected.sport === '1716') && this.selected.athleteNid) {
          this.$refs.myVolleyball.reorganizeGames()
        }
      }
      // show box score table
      // console.log(this.selected)

      // console.log('selected', this.selected)
      this.showTable = true
      // console.log(this.showTable)
      this.header = this.getHeader()
      console.log('end of submit function()')
    },
    statsExist () {
      let data = null
      if (this.gamesRecordPlayerIn) {
        data = this.gamesRecordPlayerIn
      } else if (this.stats) {
        data = this.stats
      } else if (this.seasonData) {
        console.log('this.seasonData exists, it is: ' + JSON.stringify(this.seasonData))
        data = this.seasonData
      }
      if (data === null || data.length === 0) {
        console.log('data was null or length 0')
        this.isStatsExist = false
      } else if (data.length !== 0) {
        this.isStatsExist = true
      }
    },
    async getYears () {
      const yearsUrl = `${this.tempYearsUrl}/${this.selected.sport}`
      // ======= fetch from URL API =======
      const res = await fetch(yearsUrl)
      const data = await res.json()
      this.years = data
    },
    async getAthleteOrGamesOrCoach () {
      if (this.selected.category === 'athlete') {
        this.selected.gameNid = undefined
        this.selected.coachNid = undefined
        if (this.selected.sport && this.selected.year) {
          const athletesUrl = `${this.tempAthletesUrl}/${this.selected.sport}/${this.selected.year}`
          // console.log('athletesUrl', athletesUrl)
          // ======= fetch from URL API =======
          const res = await fetch(athletesUrl)
          const data = await res.json()
          this.athletes = data
          // console.log('this.athletes', this.athletes)
        }
      } else if (this.selected.category === 'game') {
        this.selected.athleteNid = undefined
        this.selected.coachNid = undefined
        if (this.selected.sport && this.selected.year) {
          const gamesUrl = `${this.tempGamesUrl}/${this.selected.sport}/${this.selected.year}`
          // console.log('gamesUrl', gamesUrl)
          // ======= fetch from URL API =======
          const res = await fetch(gamesUrl)
          const data = await res.json()
          this.games = data
          // console.log('this.games', this.games)
        }
      } else if (this.selected.category === 'coach') {
        this.selected.athleteNid = undefined
        this.selected.gameNid = undefined
        if (this.selected.sport && this.selected.year) {
          const coachUrl = `${this.tempCoachUrl}/${this.selected.sport}/${this.selected.year}`
          // console.log('coachUrl', coachUrl)
          // ======= fetch from URL API =======
          const res = await fetch(coachUrl)
          const data = await res.json()
          this.coaches = data
          // console.log('this.coaches', this.coaches)
        }
      }
    },
    getHeader () {
      var sportOptions = {
        1698: 'Baseball',
        1699: 'Men\'s Basketball',
        1707: 'Women\'s Basketball',
        1701: 'Football',
        1711: 'Soccer',
        1712: 'Softball',
        1706: 'Men\'s Volleyball',
        1716: 'Women\'s Volleyball'
      }
      var header
      var sport = sportOptions[this.selected.sport]
      if (this.selected.category === 'season-stats') {
        header = this.selected.year + ' BYU ' + sport + ' Statistics'
      } else if (this.selected.category === 'athlete') {
        header = this.selected.year + ' BYU ' + sport + ' Athlete Statistics'
      } else if (this.selected.category === 'coach') {
        header = this.selected.year + ' BYU ' + sport + ' Coach Statistics'
      } else if (this.selected.category === 'game') {
        header = this.selected.year + ' BYU ' + sport + ' Game Statistics'
      }
      return header
    }
  }
}
</script>

<style scoped>
.query {
  background-color: white;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 20%);
  border-radius: 5px;
  position: relative;
}

.x-button {
  position: absolute;
  top: 0;
  right: 0;
}

#submit:hover {
  color: #003FA2;;
  background-color: white;
}

#submit {
  color: white;
  background-color: #003FA2;
}
</style>
