<template>
  <div>
    <div class="table-responsive mt-3" v-if="showData">
        <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
            <thead>
                <tr>
                    <th colspan="100%">Season Record</th>
                </tr>
                <tr>
                    <th>Record</th>
                    <th>Overall</th>
                    <th>Home</th>
                    <th>Away</th>
                    <th>Neutral</th>
                    <th>Conference</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="col-emphasize">ALL GAMES</td>
                    <td>{{ this.record.overall.wins }} - {{ this.record.overall.losses }}</td>
                    <td>{{ this.record.home.wins }} - {{ this.record.home.losses }}</td>
                    <td>{{ this.record.away.wins }} - {{ this.record.away.losses }}</td>
                    <td>{{ this.record.neutral.wins }} - {{ this.record.neutral.losses }}</td>
                    <td>{{ this.record.wcc.wins }} - {{ this.record.wcc.losses }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- Hitting stats by game -->
    <div class="table-responsive mt-3" v-if="showData">
        <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
            <thead>
                <tr class="table-label">
                    <th colspan="100%">Game Hitting Statistics</th>
                </tr>
                <tr>
                  <th>Opponent</th>
                  <th>Date</th>
                  <th>Result</th>
                  <th>Score</th>
                  <th>AVG</th>
                  <th>AB</th>
                  <th>R</th>
                  <th>H</th>
                  <th>2B</th>
                  <th>3B</th>
                  <th>HR</th>
                  <th>RBI</th>
                  <th>TB</th>
                  <th>SLG %</th>
                  <th>BB</th>
                  <th>HP</th>
                  <th>SO</th>
                  <th>GDP</th>
                  <th>OB %</th>
                  <th>SF</th>
                  <th>SH</th>
                  <th>SB</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="(totals,index) in totalGames" :key="index">
                <td v-if="totals.match" class="col-emphasize">{{ totals.match }}</td><td v-else>-</td>
                <td v-if="totals.date">{{ totals.date }}</td><td v-else>-</td>
                <td v-if="totals.score && totals.score[1]" style="color: green">W</td>
                <td v-if="totals.score && !totals.score[1]" style="color: red">L</td>
                <td v-if="totals.score && totals.score[0]">{{ totals.score[0] }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.avgPct">{{ totals.stats.hitting.avgPct }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.ab">{{ totals.stats.hitting.ab }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.r">{{ totals.stats.hitting.r }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.h">{{ totals.stats.hitting.h }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.double">{{ totals.stats.hitting.double }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.triple">{{ totals.stats.hitting.triple }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.hr">{{ totals.stats.hitting.hr }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.rbi">{{ totals.stats.hitting.rbi }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.tb">{{ totals.stats.hitting.tb }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.slgPct">{{ totals.stats.hitting.slgPct }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.bb">{{ totals.stats.hitting.bb }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.hbp">{{ totals.stats.hitting.hbp }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.so">{{ totals.stats.hitting.so }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.gdp">{{ totals.stats.hitting.gdp }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.obPct">{{ totals.stats.hitting.obPct }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.sf">{{ totals.stats.hitting.sf }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.sh">{{ totals.stats.hitting.sh }}</td><td v-else>-</td>
                <td v-if="totals.stats.hitting && totals.stats.hitting.sb">{{ totals.stats.hitting.sb }}</td><td v-else>-</td>
              </tr>
              <tr class="row-emphasize">
                <td colspan="4">Totals</td>
                <td v-if="this.totalBYU && this.totalBYU.avgPct">{{ this.totalBYU.avgPct }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.ab">{{ this.totalBYU.ab }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.r">{{ this.totalBYU.r }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.h">{{ this.totalBYU.h }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.twob">{{ this.totalBYU.twob }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.threeb">{{ this.totalBYU.threeb }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.hr">{{ this.totalBYU.hr }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.rbi">{{ this.totalBYU.rbi }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.tb">{{ this.totalBYU.tb }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.slgPct">{{ this.totalBYU.slgPct }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.bb">{{ this.totalBYU.bb }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.hp">{{ this.totalBYU.hp }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.so">{{ this.totalBYU.so }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.gdp">{{ this.totalBYU.gdp }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.obPct">{{ this.totalBYU.obPct }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.sf">{{ this.totalBYU.sf }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.sh">{{ this.totalBYU.sh }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.sb">{{ this.totalBYU.sb }}</td><td v-else>-</td>
              </tr>
              <tr class="row-emphasize">
                <td colspan="4">Opponents</td>
                <td v-if="this.totalOpp && this.totalOpp.avgPct">{{ this.totalOpp.avgPct }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.ab">{{ this.totalOpp.ab }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.r">{{ this.totalOpp.r }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.h">{{ this.totalOpp.h }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.twob">{{ this.totalOpp.twob }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.threeb">{{ this.totalOpp.threeb }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.hr">{{ this.totalOpp.hr }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.rbi">{{ this.totalOpp.rbi }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.tb">{{ this.totalOpp.tb }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.slgPct">{{ this.totalOpp.slgPct }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.bb">{{ this.totalOpp.bb }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.hp">{{ this.totalOpp.hp }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.so">{{ this.totalOpp.so }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.gdp">{{ this.totalOpp.gdp }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.obPct">{{ this.totalOpp.obPct }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.sf">{{ this.totalOpp.sf }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.sh">{{ this.totalOpp.sh }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.sb">{{ this.totalOpp.sb }}</td><td v-else>-</td>
              </tr>
            </tbody>
        </table>
    </div>
    <!-- Fielding stats by game -->
    <div class="table-responsive mt-3" v-if="showData">
        <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
            <thead>
                <tr class="table-label">
                    <th colspan="100%">Game Fielding Statistics</th>
                </tr>
                <tr>
                  <th>Opponent</th>
                  <th>Date</th>
                  <th>Result</th>
                  <th>Score</th>
                  <th>C</th>
                  <th>PO</th>
                  <th>A</th>
                  <th>E</th>
                  <th>FLD %</th>
                  <th>DP</th>
                  <th>SBA</th>
                  <th>CSB</th>
                  <th>PB</th>
                  <th>CI</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="(totals,index) in totalGames" :key="index">
                <td v-if="totals.match" class="col-emphasize">{{ totals.match }}</td><td v-else>-</td>
                <td v-if="totals.date">{{ totals.date }}</td><td v-else>-</td>
                <td v-if="totals.score && totals.score[1]" style="color: green">W</td>
                <td v-if="totals.score && !totals.score[1]" style="color: red">L</td>
                <td v-if="totals.score && totals.score[0]">{{ totals.score[0] }}</td><td v-else>-</td>
                <td v-if="totals.stats.fielding && totals.stats.fielding.c">{{ totals.stats.fielding.c }}</td><td v-else>-</td>
                <td v-if="totals.stats.fielding && totals.stats.fielding.po">{{ totals.stats.fielding.po }}</td><td v-else>-</td>
                <td v-if="totals.stats.fielding && totals.stats.fielding.a">{{ totals.stats.fielding.a }}</td><td v-else>-</td>
                <td v-if="totals.stats.fielding && totals.stats.fielding.e">{{ totals.stats.fielding.e }}</td><td v-else>-</td>
                <td v-if="totals.stats.fielding && totals.stats.fielding.fldPct">{{ totals.stats.fielding.fldPct }}</td><td v-else>-</td>
                <td v-if="totals.stats.fielding && totals.stats.fielding.indp">{{ totals.stats.fielding.indp }}</td><td v-else>-</td>
                <td v-if="totals.stats.fielding && totals.stats.fielding.sba">{{ totals.stats.fielding.sba }}</td><td v-else>-</td>
                <td v-if="totals.stats.fielding && totals.stats.fielding.csb">{{ totals.stats.fielding.csb }}</td><td v-else>-</td>
                <td v-if="totals.stats.fielding && totals.stats.fielding.pb">{{ totals.stats.fielding.pb }}</td><td v-else>-</td>
                <td v-if="totals.stats.fielding && totals.stats.fielding.ci">{{ totals.stats.fielding.ci }}</td><td v-else>-</td>
              </tr>
              <tr class="row-emphasize">
                <td colspan="4">Totals</td>
                <td v-if="this.totalBYU && this.totalBYU.c">{{ this.totalBYU.c }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.po">{{ this.totalBYU.po }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.a">{{ this.totalBYU.a }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.e">{{ this.totalBYU.e }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.fldPct">{{ this.totalBYU.fldPct }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.dp">{{ this.totalBYU.dp }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.sba">{{ this.totalBYU.sba }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.csb">{{ this.totalBYU.csb }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.pb">{{ this.totalBYU.pb }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.ci">{{ this.totalBYU.ci }}</td><td v-else>-</td>
              </tr>
              <tr class="row-emphasize">
                <td colspan="4">Opponents</td>
                <td v-if="this.totalOpp && this.totalOpp.c">{{ this.totalOpp.c }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.po">{{ this.totalOpp.po }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.a">{{ this.totalOpp.a }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.e">{{ this.totalOpp.e }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.fldPct">{{ this.totalOpp.fldPct }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.dp">{{ this.totalOpp.dp }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.sba">{{ this.totalOpp.sba }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.csb">{{ this.totalOpp.csb }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.pb">{{ this.totalOpp.pb }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.ci">{{ this.totalOpp.ci }}</td><td v-else>-</td>
              </tr>
            </tbody>
        </table>
    </div>
    <!-- Pitching stats by game -->
    <div class="table-responsive mt-3" v-if="showData">
        <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
            <thead>
                <tr class="table-label">
                    <th colspan="100%">Game Pitching Statistics</th>
                </tr>
                <tr>
                  <th>Opponent</th>
                  <th>Date</th>
                  <th>Result</th>
                  <th>Score</th>
                  <th>AB</th>
                  <th>H</th>
                  <th>R</th>
                  <th>ER</th>
                  <th>BB</th>
                  <th>SO</th>
                  <th>2B</th>
                  <th>3B</th>
                  <th>HR</th>
                  <th>B/AVG</th>
                  <th>WP</th>
                  <th>HP</th>
                  <th>BK</th>
                  <th>SFA</th>
                  <th>SHA</th>
                </tr>
            </thead>
            <tbody>
              <tr v-for="(totals,index) in totalGames" :key="index">
                <td v-if="totals.match" class="col-emphasize">{{ totals.match }}</td><td v-else>-</td>
                <td v-if="totals.date">{{ totals.date }}</td><td v-else>-</td>
                <td v-if="totals.score && totals.score[1]" style="color: green">W</td>
                <td v-if="totals.score && !totals.score[1]" style="color: red">L</td>
                <td v-if="totals.score && totals.score[0]">{{ totals.score[0] }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.ab">{{ totals.stats.pitching.ab }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.h">{{ totals.stats.pitching.h }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.r">{{ totals.stats.pitching.r }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.er">{{ totals.stats.pitching.er }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.bb">{{ totals.stats.pitching.bb }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.so">{{ totals.stats.pitching.so }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.double">{{ totals.stats.pitching.double }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.triple">{{ totals.stats.pitching.triple }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.hr">{{ totals.stats.pitching.hr }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.bavgPct">{{ totals.stats.pitching.bavgPct }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.wp">{{ totals.stats.pitching.wp }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.hbp">{{ totals.stats.pitching.hbp }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.bk">{{ totals.stats.pitching.bk }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.sfa">{{ totals.stats.pitching.sfa }}</td><td v-else>-</td>
                <td v-if="totals.stats.pitching && totals.stats.pitching.sha">{{ totals.stats.pitching.sha }}</td><td v-else>-</td>
              </tr>
              <tr class="row-emphasize">
                <td colspan="4">Totals</td>
                <td v-if="this.totalBYU && this.totalBYU.abPitch">{{ this.totalBYU.abPitch }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.hPitch">{{ this.totalBYU.hPitch }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.rPitch">{{ this.totalBYU.rPitch }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.er">{{ this.totalBYU.er }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.bbPitch">{{ this.totalBYU.bbPitch }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.soPitch">{{ this.totalBYU.soPitch }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.twobPitch">{{ this.totalBYU.twobPitch }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.threebPitch">{{ this.totalBYU.threebPitch }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.hrPitch">{{ this.totalBYU.hrPitch }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.bavgPct">{{ this.totalBYU.bavgPct }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.wp">{{ this.totalBYU.wp }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.hp">{{ this.totalBYU.hp }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.bk">{{ this.totalBYU.bk }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.sfa">{{ this.totalBYU.sfa }}</td><td v-else>-</td>
                <td v-if="this.totalBYU && this.totalBYU.sha">{{ this.totalBYU.sha }}</td><td v-else>-</td>
              </tr>
              <tr class="row-emphasize">
                <td colspan="4">Opponents</td>
                <td v-if="this.totalOpp && this.totalOpp.abPitch">{{ this.totalOpp.abPitch }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.hPitch">{{ this.totalOpp.hPitch }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.rPitch">{{ this.totalOpp.rPitch }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.er">{{ this.totalOpp.er }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.bbPitch">{{ this.totalOpp.bbPitch }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.soPitch">{{ this.totalOpp.soPitch }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.twobPitch">{{ this.totalOpp.twobPitch }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.threebPitch">{{ this.totalOpp.threebPitch }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.hrPitch">{{ this.totalOpp.hrPitch }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.bavgPct">{{ this.totalOpp.bavgPct }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.wp">{{ this.totalOpp.wp }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.hp">{{ this.totalOpp.hp }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.bk">{{ this.totalOpp.bk }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.sfa">{{ this.totalOpp.sfa }}</td><td v-else>-</td>
                <td v-if="this.totalOpp && this.totalOpp.sha">{{ this.totalOpp.sha }}</td><td v-else>-</td>
              </tr>
            </tbody>
        </table>
    </div>
    <div class="table-responsive mt-3" v-if="showData">
      <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
        <thead>
          <tr>
            <th colspan="100%">Batting</th>
          </tr>
          <tr>
            <th>No.</th>
            <th>Player</th>
            <th>AVG</th>
            <th>GP-GS</th>
            <th>AB</th>
            <th>R</th>
            <th>H</th>
            <th>2B</th>
            <th>3B</th>
            <th>HR</th>
            <th>RBI</th>
            <th>TB</th>
            <th>SLG %</th>
            <th>BB</th>
            <th>HP</th>
            <th>SO</th>
            <th>GDP</th>
            <th>OB %</th>
            <th>SF</th>
            <th>SH</th>
            <th>SB</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(totals,index) in orderedBatters" :key="index">
            <td v-if="totals && totals.number" class="col-emphasize">{{ totals.number }}</td><td v-else>-</td>
            <td v-if="totals && totals.name" class="col-emphasize">{{ totals.name }}</td><td v-else>-</td>
            <td v-if="totals && totals.avgPct">{{ totals.avgPct }}</td><td v-else>-</td>
            <td v-if="totals && totals.gpgs">{{ totals.gpgs[0] }} - {{ totals.gpgs[1] }}</td><td v-else>-</td>
            <td v-if="totals && totals.ab">{{ totals.ab }}</td><td v-else>-</td>
            <td v-if="totals && totals.r">{{ totals.r }}</td><td v-else>-</td>
            <td v-if="totals && totals.h">{{ totals.h }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.twob">{{ totals.twob }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.threeb">{{ totals.threeb }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.hr">{{ totals.hr }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.rbi">{{ totals.rbi }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.tb">{{ totals.tb }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.slgPct">{{ totals.slgPct }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.bb">{{ totals.bb }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.hp">{{ totals.hp }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.so">{{ totals.so }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.gdp">{{ totals.gdp }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.obPct">{{ totals.obPct }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.sf">{{ totals.sf }}</td><td v-else>-</td>
            <td v-if="totals &&  totals.sh">{{ totals.sh }}</td><td v-else>-</td>
            <td v-if="totals && totals.sb">{{ totals.sb }}</td><td v-else>-</td>
          </tr>
          <tr class="row-emphasize">
            <td colspan="2">Totals</td>
            <td v-if="this.totalBYU && this.totalBYU.avgPct">{{ this.totalBYU.avgPct }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.gpgs">{{ this.totalBYU.gpgs }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.ab">{{ this.totalBYU.ab }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.r">{{ this.totalBYU.r }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.h">{{ this.totalBYU.h }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.twob">{{ this.totalBYU.twob }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.threeb">{{ this.totalBYU.threeb }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.hr">{{ this.totalBYU.hr }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.rbi">{{ this.totalBYU.rbi }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.tb">{{ this.totalBYU.tb }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.slgPct">{{ this.totalBYU.slgPct }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.bb">{{ this.totalBYU.bb }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.hp">{{ this.totalBYU.hp }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.so">{{ this.totalBYU.so }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.gdp">{{ this.totalBYU.gdp }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.obPct">{{ this.totalBYU.obPct }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.sf">{{ this.totalBYU.sf }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.sh">{{ this.totalBYU.sh }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.sb">{{ this.totalBYU.sb }}</td><td v-else>-</td>
          </tr>
          <tr class="row-emphasize">
            <td colspan="2">Opponents</td>
            <td v-if="this.totalOpp && this.totalOpp.avgPct">{{ this.totalOpp.avgPct }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.gpgs">{{ this.totalOpp.gpgs }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.ab">{{ this.totalOpp.ab }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.r">{{ this.totalOpp.r }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.h">{{ this.totalOpp.h }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.twob">{{ this.totalOpp.twob }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.threeb">{{ this.totalOpp.threeb }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.hr">{{ this.totalOpp.hr }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.rbi">{{ this.totalOpp.rbi }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.tb">{{ this.totalOpp.tb }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.slgPct">{{ this.totalOpp.slgPct }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.bb">{{ this.totalOpp.bb }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.hp">{{ this.totalOpp.hp }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.so">{{ this.totalOpp.so }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.gdp">{{ this.totalOpp.gdp }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.obPct">{{ this.totalOpp.obPct }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.sf">{{ this.totalOpp.sf }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.sh">{{ this.totalOpp.sh }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.sb">{{ this.totalOpp.sb }}</td><td v-else>-</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive mt-3" v-if="showData">
      <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
        <thead>
          <tr>
            <th colspan="100%">Fielding</th>
          </tr>
          <tr>
            <th>No.</th>
            <th>Player</th>
            <th>C</th>
            <th>PO</th>
            <th>A</th>
            <th>E</th>
            <th>FLD %</th>
            <th>DP</th>
            <th>SBA</th>
            <th>CSB</th>
            <!-- <th>SBA %</th> -->
            <th>PB</th>
            <th>CI</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(totals,index) in orderedPlayers" :key="index">
            <td v-if="totals && totals.number" class="col-emphasize">{{ totals.number }}</td><td v-else>-</td>
            <td v-if="totals && totals.name" class="col-emphasize">{{ totals.name }}</td><td v-else>-</td>
            <td v-if="totals && totals.c">{{ totals.c }}</td><td v-else>-</td>
            <td v-if="totals && totals.po">{{ totals.po }}</td><td v-else>-</td>
            <td v-if="totals && totals.a">{{ totals.a }}</td><td v-else>-</td>
            <td v-if="totals && totals.e">{{ totals.e }}</td><td v-else>-</td>
            <td v-if="totals && totals.fldPct">{{ totals.fldPct }}</td><td v-else>-</td>
            <td v-if="totals && totals.dp">{{ totals.dp }}</td><td v-else>-</td>
            <td v-if="totals && totals.sba">{{ totals.sba }}</td><td v-else>-</td>
            <td v-if="totals && totals.csb">{{ totals.csb }}</td><td v-else>-</td>
            <!-- <td v-if="totals && totals.sbaPct">{{ totals.sbaPct }}</td><td v-else>-</td> -->
            <td v-if="totals && totals.pb">{{ totals.pb }}</td><td v-else>-</td>
            <td v-if="totals && totals.ci">{{ totals.ci }}</td><td v-else>-</td>
          </tr>
          <tr class="row-emphasize">
            <td colspan="2">Totals</td>
            <td v-if="this.totalBYU && this.totalBYU.c">{{ this.totalBYU.c }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.po">{{ this.totalBYU.po }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.a">{{ this.totalBYU.a }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.e">{{ this.totalBYU.e }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.fldPct">{{ this.totalBYU.fldPct }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.dp">{{ this.totalBYU.dp }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.sba">{{ this.totalBYU.sba }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.csb">{{ this.totalBYU.csb }}</td><td v-else>-</td>
            <!-- <td v-if="this.totalBYU && this.totalBYU.sbaPct">{{ this.totalBYU.sbaPct }}</td><td v-else>-</td> -->
            <td v-if="this.totalBYU && this.totalBYU.pb">{{ this.totalBYU.pb }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.ci">{{ this.totalBYU.ci }}</td><td v-else>-</td>
          </tr>
          <tr class="row-emphasize">
            <td colspan="2">Opponents</td>
            <td v-if="this.totalOpp && this.totalOpp.c">{{ this.totalOpp.c }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.po">{{ this.totalOpp.po }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.a">{{ this.totalOpp.a }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.e">{{ this.totalOpp.e }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.fldPct">{{ this.totalOpp.fldPct }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.dp">{{ this.totalOpp.dp }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.sba">{{ this.totalOpp.sba }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.csb">{{ this.totalOpp.csb }}</td><td v-else>-</td>
            <!-- <td v-if="this.totalOpp && this.totalOpp.sbaPct">{{ this.totalOpp.sbaPct }}</td><td v-else>-</td> -->
            <td v-if="this.totalOpp && this.totalOpp.pb">{{ this.totalOpp.pb }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.ci">{{ this.totalOpp.ci }}</td><td v-else>-</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive mt-3" v-if="showData">
      <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
        <thead>
          <tr>
            <th colspan="100%">Pitching</th>
          </tr>
          <tr>
            <th>No.</th>
            <th>Player</th>
            <th>ERA</th>
            <th>WIN</th>
            <th>APP</th>
            <th>GS</th>
            <th>CG</th>
            <th>SHO</th>
            <th>SV</th>
            <th>IP</th>
            <th>H</th>
            <th>R</th>
            <th>ER</th>
            <th>BB</th>
            <th>SO</th>
            <th>2B</th>
            <th>3B</th>
            <th>HR</th>
            <th>B/AVG</th>
            <th>WP</th>
            <th>HP</th>
            <th>BK</th>
            <th>SFA</th>
            <th>SHA</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(totals,index) in orderedPitchers" :key="index">
            <td v-if="totals && totals.number" class="col-emphasize">{{ totals.number }}</td><td v-else>-</td>
            <td v-if="totals && totals.name" class="col-emphasize">{{ totals.name }}</td><td v-else>-</td>
            <td v-if="totals && totals.era">{{ totals.era }}</td><td v-else>-</td>
            <td v-if="totals && totals.wl">{{ totals.wl }}</td><td v-else>-</td>
            <td v-if="totals && totals.app">{{ totals.app }}</td><td v-else>-</td>
            <td v-if="totals && totals.gs">{{ totals.gs }}</td><td v-else>-</td>
            <td v-if="totals && totals.cg">{{ totals.cg }}</td><td v-else>-</td>
            <td v-if="totals && totals.sho">{{ totals.sho }}</td><td v-else>-</td>
            <td v-if="totals && totals.sv">{{ totals.sv }}</td><td v-else>-</td>
            <td v-if="totals && totals.ip">{{ totals.ip }}</td><td v-else>-</td>
            <td v-if="totals && totals.hPitch">{{ totals.hPitch }}</td><td v-else>-</td>
            <td v-if="totals && totals.rPitch">{{ totals.rPitch }}</td><td v-else>-</td>
            <td v-if="totals && totals.er">{{ totals.er }}</td><td v-else>-</td>
            <td v-if="totals && totals.bbPitch">{{ totals.bbPitch }}</td><td v-else>-</td>
            <td v-if="totals && totals.soPitch">{{ totals.soPitch }}</td><td v-else>-</td>
            <td v-if="totals && totals.twobPitch">{{ totals.twobPitch }}</td><td v-else>-</td>
            <td v-if="totals && totals.threebPitch">{{ totals.threebPitch }}</td><td v-else>-</td>
            <td v-if="totals && totals.hrPitch">{{ totals.hrPitch }}</td><td v-else>-</td>
            <td v-if="totals && totals.bavgPct">{{ totals.bavgPct }}</td><td v-else>-</td>
            <td v-if="totals && totals.wp">{{ totals.wp }}</td><td v-else>-</td>
            <td v-if="totals && totals.hpPitch">{{ totals.hpPitch }}</td><td v-else>-</td>
            <td v-if="totals && totals.bk">{{ totals.bk }}</td><td v-else>-</td>
            <td v-if="totals && totals.sfa">{{ totals.sfa }}</td><td v-else>-</td>
            <td v-if="totals && totals.sha">{{ totals.sha }}</td><td v-else>-</td>
          </tr>
          <tr class="row-emphasize">
            <td colspan="2">Totals</td>
            <td v-if="this.totalBYU && this.totalBYU.era">{{ this.totalBYU.era }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.wl">{{ this.record[0] }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.app">{{ this.totalBYU.app }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.gs">{{ this.totalBYU.gs }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.cg">{{ this.totalBYU.cg }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.sho">{{ this.totalBYU.sho }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.sv">{{ this.totalBYU.sv }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.ip">{{ this.totalBYU.ip }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.hPitch">{{ this.totalBYU.hPitch }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.rPitch">{{ this.totalBYU.rPitch }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.er">{{ this.totalBYU.er }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.bbPitch">{{ this.totalBYU.bbPitch }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.soPitch">{{ this.totalBYU.soPitch }}</td><td v-else>-</td>
            <td  v-if="this.totalBYU && this.totalBYU.twobPitch">{{ this.totalBYU.twobPitch }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.threebPitch">{{ this.totalBYU.threebPitch}}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.hrPitch">{{ this.totalBYU.hrPitch }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.bavgPct">{{ this.totalBYU.bavgPct }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.wp">{{ this.totalBYU.wp }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.hpPitch">{{ this.totalBYU.hpPitch }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.bk">{{ this.totalBYU.bk }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.sfa">{{ this.totalBYU.sfa }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.sha">{{ this.totalBYU.sha }}</td><td v-else>-</td>
          </tr>
          <tr class="row-emphasize">
            <td colspan="2">Opponents</td>
            <td v-if="this.totalOpp && this.totalOpp.era">{{ this.totalOpp.era }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.wl">{{ this.record[1] }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.app">{{ this.totalOpp.app }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.gs">{{ this.totalOpp.gs }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.cg">{{ this.totalOpp.cg }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.sho">{{ this.totalOpp.sho }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.sv">{{ this.totalOpp.sv }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.ip">{{ this.totalOpp.ip }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.hPitch">{{ this.totalOpp.hPitch }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.rPitch">{{ this.totalOpp.rPitch }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.er">{{ this.totalOpp.er }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.bbPitch">{{ this.totalOpp.bbPitch }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.soPitch">{{ this.totalOpp.soPitch }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.twobPitch">{{ this.totalOpp.twobPitch }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.threebPitch">{{ this.totalOpp.threebPitch}}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.hrPitch">{{ this.totalOpp.hrPitch }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.bavgPct">{{ this.totalOpp.bavgPct }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.wp">{{ this.totalOpp.wp }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.hpPitch">{{ this.totalOpp.hpPitch }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.bk">{{ this.totalOpp.bk }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.sfa">{{ this.totalOpp.sfa }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.sha">{{ this.totalOpp.sha }}</td><td v-else>-</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  props: ['selected', 'gameYears', 'seasonData'],
  data () {
    return {
      totalBYU: null,
      totalOpp: null,
      totalPlayers: null,
      totalBatters: null,
      totalPitchers: null,
      totalGames: null,
      record: null,
      innings: null,
      showData: false
    }
  },
  mounted () {
    // console.log(this.seasonData)
    this.innings = this.setInnings(this.selected.sport)
    this.totalBYU = this.setTeamTotal(this.getBYUData, this.getOppData)
    this.totalOpp = this.setTeamTotal(this.getOppData, this.getBYUData)
    // console.log(this.totalBYU)
    // console.log(this.totalOpp)
    this.totalPlayers = this.setPlayerTotal()
    this.totalBatters = this.filterBatters(this.totalPlayers)
    this.totalPitchers = this.filterPitchers(this.totalPlayers)
    this.totalGames = this.setGameData()
    this.record = this.getRecord(this.fixData(this.seasonData))
    this.showData = true
  },
  computed: {
    orderedPitchers: function () {
      return _.orderBy(this.totalPitchers, 'number')
    },
    orderedPlayers: function () {
      return _.orderBy(this.totalPlayers, 'number')
    },
    orderedBatters: function () {
      return _.orderBy(this.totalBatters, 'number')
    }
  },
  methods: {
    setTeamTotal (getHomeData, getOppData) {
      const data = getHomeData(this.fixData(this.seasonData))
      const oppData = getOppData(this.fixData(this.seasonData))
      const totals = {
        avgPct: this.getPct(this.getSumStat(data, 'hitting', 'h'), this.getSumStat(data, 'hitting', 'ab')),
        gpgs: data.length,
        ab: this.getSumStat(data, 'hitting', 'ab'),
        r: this.getSumStat(data, 'hitting', 'r'),
        h: this.getSumStat(data, 'hitting', 'h'),
        twob: this.getSumStat(data, 'hitting', 'double'),
        threeb: this.getSumStat(data, 'hitting', 'triple'),
        hr: this.getSumStat(data, 'hitting', 'hr'),
        rbi: this.getSumStat(data, 'hitting', 'rbi'),
        tb: this.getTB(data),
        slgPct: this.getPct(this.getTB(data), this.getSumStat(data, 'hitting', 'ab')),
        bb: this.getSumStat(data, 'hitting', 'bb'),
        hp: this.getSumStat(data, 'hitting', 'hbp'),
        so: this.getSumStat(data, 'hitting', 'so'),
        gdp: this.getSumStat(data, 'hitting', 'gdp'),
        obPct: this.getPct((this.getSumStat(data, 'hitting', 'h') + this.getSumStat(data, 'hitting', 'bb') + this.getSumStat(data, 'hitting', 'hbp')), (this.getSumStat(data, 'hitting', 'ab') + this.getSumStat(data, 'hitting', 'bb') + this.getSumStat(data, 'hitting', 'hbp') + this.getSumStat(data, 'hitting', 'sf'))),
        sf: this.getSumStat(data, 'hitting', 'sf'),
        sh: this.getSumStat(data, 'hitting', 'sh'),
        sb: this.getSumStat(data, 'hitting', 'sb'), // instead of sb-att
        // start of fielding stats
        c: this.getC(data),
        po: this.getSumStat(data, 'fielding', 'po'),
        a: this.getSumStat(data, 'fielding', 'a'),
        e: this.getSumStat(data, 'fielding', 'e'),
        fldPct: this.getPct((this.getSumStat(data, 'fielding', 'po') + this.getSumStat(data, 'fielding', 'a')), (this.getSumStat(data, 'fielding', 'po') + this.getSumStat(data, 'fielding', 'a') + this.getSumStat(data, 'fielding', 'e'))),
        dp: this.getSumStat(oppData, 'hitting', 'gdp') + (this.getSumStat(oppData, 'hitting', 'hitdp') - this.getSumStat(oppData, 'hitting', 'gdp')),
        sba: this.getSumStat(data, 'fielding', 'sba'),
        csb: this.getSumStat(data, 'fielding', 'csb'),
        sbaPct: null,
        pb: this.getSumStat(data, 'fielding', 'pb'),
        ci: this.getSumStat(data, 'fielding', 'ci'),
        // start of pitching stats
        abPitch: this.getSumStat(data, 'pitching', 'ab'),
        era: this.getPct((this.innings * this.getSumStat(data, 'pitching', 'er')), this.getIP(data)[1]),
        wl: 'placeholder',
        app: data.length,
        gs: data.length,
        cg: this.getSumStat(data, 'pitching', 'cg'),
        sho: this.getSumStat(data, 'pitching', 'sho'),
        sv: this.getSumStat(data, 'pitching', 'save'),
        ip: this.getIP(data)[0],
        hPitch: this.getSumStat(data, 'pitching', 'h'),
        rPitch: this.getSumStat(data, 'pitching', 'r'),
        er: this.getSumStat(data, 'pitching', 'er'),
        bbPitch: this.getSumStat(data, 'pitching', 'bb'),
        soPitch: this.getSumStat(data, 'pitching', 'so'),
        twobPitch: this.getSumStat(data, 'pitching', 'double'),
        threebPitch: this.getSumStat(data, 'pitching', 'triple'),
        hrPitch: this.getSumStat(data, 'pitching', 'hr'),
        bavgPct: this.getPct(this.getSumStat(data, 'pitching', 'h'), this.getSumStat(data, 'pitching', 'ab')),
        wp: this.getSumStat(data, 'pitching', 'wp'),
        hpPitch: this.getSumStat(data, 'pitching', 'hbp'),
        bk: this.getSumStat(data, 'pitching', 'bk'),
        sfa: this.getSumStat(data, 'pitching', 'sfa'),
        sha: this.getSumStat(data, 'pitching', 'sha')
      }
      return totals
    },
    fixData (data) {
      var fixedData = []
      for (let i = 0; i < data.length; i++) {
        // hopefully handles the issue where game arrays have an extra empty array before the actual info
        if (data[i][0]) {
          data[i] = data[i][0]
        }
        // removes all games that don't include bsgame objects
        if (data[i].bsgame) {
          fixedData.push(data[i])
        } else {
          // console.log('found an object without bsgame')
        }
      }
      return fixedData
    },
    getAllPlayerData (data) {
      var AllPlayerData = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].bsgame.team[0].id === 'BYU' || data[i].bsgame.team[0].id === 'BY' || data[i].bsgame.team[0].name === 'Brigham Young University') {
          AllPlayerData[i] = (data[i].bsgame.team[0].player)
        } else {
          AllPlayerData[i] = (data[i].bsgame.team[1].player)
        }
      }
      // console.log('AllPlayerData: ')
      // console.log(AllPlayerData)
      return AllPlayerData
    },
    getPlayerData (data, nid) {
      var playerData = []
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          if (data[i][j].player_nid === nid) {
            playerData.push(data[i][j])
          }
        }
      }
      return playerData
    },
    setPlayerTotal () {
      const data = this.getAllPlayerData(this.fixData(this.seasonData))
      // console.log('got data in setPlayerTotal function, listed below')
      // console.log(data)
      var playerTotals = {
      }
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          var nid = data[i][j].player_nid
          if (!playerTotals[nid] && nid !== undefined) {
            var playerData = this.getPlayerData(data, nid)
            // console.log('data for player with nid ' + nid)
            // console.log(playerData)
            playerTotals[nid] = {
              name: playerData[0].name,
              number: parseInt(playerData[0].uni),
              pos: this.getPos(playerData),
              avgPct: this.getPct(this.getSumStat(playerData, 'hitting', 'h'), this.getSumStat(playerData, 'hitting', 'ab')),
              gpgs: [this.getGP(playerData), this.getGS(playerData)],
              ab: this.getSumStat(playerData, 'hitting', 'ab'),
              r: this.getSumStat(playerData, 'hitting', 'r'),
              h: this.getSumStat(playerData, 'hitting', 'h'),
              twob: this.getSumStat(playerData, 'hitting', 'double'),
              threeb: this.getSumStat(playerData, 'hitting', 'triple'),
              hr: this.getSumStat(playerData, 'hitting', 'hr'),
              rbi: this.getSumStat(playerData, 'hitting', 'rbi'),
              tb: this.getTB(playerData),
              slgPct: this.getPct(this.getTB(playerData), this.getSumStat(playerData, 'hitting', 'ab')),
              bb: this.getSumStat(playerData, 'hitting', 'bb'),
              hp: this.getSumStat(playerData, 'hitting', 'hbp'),
              so: this.getSumStat(playerData, 'hitting', 'so'),
              gdp: this.getSumStat(playerData, 'hitting', 'gdp'),
              obPct: this.getPct((this.getSumStat(playerData, 'hitting', 'h') + this.getSumStat(playerData, 'hitting', 'bb') + this.getSumStat(playerData, 'hitting', 'hbp')), (this.getSumStat(playerData, 'hitting', 'ab') + this.getSumStat(playerData, 'hitting', 'bb') + this.getSumStat(playerData, 'hitting', 'hbp') + this.getSumStat(playerData, 'hitting', 'sf'))),
              sf: this.getSumStat(playerData, 'hitting', 'sf'),
              sh: this.getSumStat(playerData, 'hitting', 'sh'),
              sb: this.getSumStat(playerData, 'hitting', 'sb'), // instead of sb-att
              // start of fielding stats
              c: this.getC(playerData),
              po: this.getSumStat(playerData, 'fielding', 'po'),
              a: this.getSumStat(playerData, 'fielding', 'a'),
              e: this.getSumStat(playerData, 'fielding', 'e'),
              fldPct: this.getPct((this.getSumStat(playerData, 'fielding', 'po') + this.getSumStat(playerData, 'fielding', 'a')), (this.getSumStat(playerData, 'fielding', 'po') + this.getSumStat(playerData, 'fielding', 'a') + this.getSumStat(playerData, 'fielding', 'e'))),
              dp: this.getSumStat(playerData, 'fielding', 'indp'),
              sba: this.getSumStat(playerData, 'fielding', 'sba'),
              csb: this.getSumStat(playerData, 'fielding', 'csb'),
              sbaPct: null,
              pb: this.getSumStat(playerData, 'fielding', 'pb'),
              ci: this.getSumStat(playerData, 'fielding', 'ci'),
              // start of pitching stats
              era: this.getPct((this.innings * this.getSumStat(playerData, 'pitching', 'er')), this.getIP(playerData)[1]),
              wl: this.getWL(playerData),
              app: this.getAppear(playerData),
              gs: this.getSumStat(playerData, 'pitching', 'gs'),
              cg: this.getSumStat(playerData, 'pitching', 'cg'),
              sho: this.getSumStat(playerData, 'pitching', 'sho'),
              sv: this.getSv(playerData),
              ip: this.getIP(playerData)[0],
              hPitch: this.getSumStat(playerData, 'pitching', 'h'),
              rPitch: this.getSumStat(playerData, 'pitching', 'r'),
              er: this.getSumStat(playerData, 'pitching', 'er'),
              bbPitch: this.getSumStat(playerData, 'pitching', 'bb'),
              soPitch: this.getSumStat(playerData, 'pitching', 'so'),
              twobPitch: this.getSumStat(playerData, 'pitching', 'double'),
              threebPitch: this.getSumStat(playerData, 'pitching', 'triple'),
              hrPitch: this.getSumStat(playerData, 'pitching', 'hr'),
              bavgPct: this.getPct(this.getSumStat(playerData, 'pitching', 'h'), this.getSumStat(playerData, 'pitching', 'ab')),
              wp: this.getSumStat(playerData, 'pitching', 'wp'),
              hpPitch: this.getSumStat(playerData, 'pitching', 'hbp'),
              bk: this.getSumStat(playerData, 'pitching', 'bk'),
              sfa: this.getSumStat(playerData, 'pitching', 'sfa'),
              sha: this.getSumStat(playerData, 'pitching', 'sha')
            }
          }
        }
      }
      // console.log(playerTotals)
      return playerTotals
    },
    setGameData () {
      const data = this.fixData(this.seasonData)
      var games = []
      for (let i = 0; i < data.length; i++) {
        var gameStats = this.getBYUGameData(data[i])
        gameStats.hitting.avgPct = this.getPct(gameStats.hitting.h, gameStats.hitting.ab)
        gameStats.hitting.tb = this.getTBGame(gameStats)
        gameStats.hitting.slgPct = (gameStats.hitting.tb / parseInt(gameStats.hitting.ab)).toFixed(3)
        gameStats.hitting.obPct = this.getOBPctGame(gameStats)
        gameStats.fielding.c = parseInt(gameStats.fielding.po) + parseInt(gameStats.fielding.a)
        gameStats.fielding.fldPct = this.getPct(gameStats.fielding.c, gameStats.fielding.c + parseInt(gameStats.fielding.e))
        gameStats.pitching.bavgPct = this.getPct(gameStats.pitching.h, gameStats.pitching.ab)
        games.push({
          nid: data[i].nid,
          match: this.getOpponent(data[i]),
          date: data[i].bsgame.venue.date,
          dateFull: new Date(data[i].event_date.split('T')[0]),
          score: this.getScore(data[i]),
          stats: gameStats
        }
        )
      }
      // sorts games by date
      const sortedGames = games.sort((a, b) => a.dateFull - b.dateFull)
      // console.log('games after sort')
      // console.log(sortedGames)
      return sortedGames
    },
    filterBatters (data) {
      var batterTotals = {

      }
      for (const player in data) {
        if (data[player].ab !== 0) {
          batterTotals[player] = (data[player])
        }
      }
      return batterTotals
    },
    filterPitchers (data) {
      var pitcherTotals = {

      }
      for (const player in data) {
        for (let i = 0; i < data[player].pos.length; i++) {
          if (data[player].pos[i] === 'p' || data[player].pos[i].indexOf('p/') !== -1 || data[player].pos[i].indexOf('/p') !== -1) {
            pitcherTotals[player] = (data[player])
          }
        }
      }
      // console.log('pitcherTotals')
      // console.log(pitcherTotals)
      return pitcherTotals
    },
    getBYUData (data) {
      var dataBYU = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].bsgame.team[0].id === 'BYU' || data[i].bsgame.team[0].id === 'BY' || data[i].bsgame.team[0].name === 'Brigham Young University') {
          // console.log('the first team was byu')
          dataBYU[i] = (data[i].bsgame.team[0].totals)
        } else {
          // console.log('the second team was byu')
          dataBYU[i] = (data[i].bsgame.team[1].totals)
        }
      }
      // console.log(dataBYU)
      return dataBYU
    },
    getOppData (data) {
      var dataOpp = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].bsgame.team[0].id !== 'BYU' && data[i].bsgame.team[0].id !== 'BY' && data[i].bsgame.team[0].name !== 'Brigham Young University') {
          // console.log('the first team was the opponent')
          dataOpp[i] = (data[i].bsgame.team[0].totals)
        } else {
          // console.log('the second team was the opponent')
          dataOpp[i] = (data[i].bsgame.team[1].totals)
        }
      }
      // console.log(dataOpp)
      return dataOpp
    },
    getSumStat (data, categoryKey, statsKey) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i][categoryKey] && data[i][categoryKey][statsKey]) {
          total += parseInt(data[i][categoryKey][statsKey])
        } else {
          // console.log(statsKey + ' not found in ' + categoryKey)
        }
      }
      return total
    },
    // this isn't getting the correct IP for each pitcher for some reason, so it skews their overall ERA
    getIP (data) {
      var total = 0.0
      var decimal = 0.0
      var totalCalc = 0.0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].pitching && data[i].pitching.ip) {
          if (data[i].pitching.ip.indexOf('.') !== -1) {
            total += parseFloat(data[i].pitching.ip.split('.')[0])
            var addDecimal = data[i].pitching.ip.split('.')[1]
            if (addDecimal === '1') {
              decimal += (1 / 3)
            } else if (addDecimal === '2') {
              decimal += (2 / 3)
            } else if (addDecimal === '0') {
              decimal += (0 / 3) // i know this does nothing but im desperate
            }
          } else {
            total += parseFloat(data[i].pitching.ip)
          }
        }
      }
      totalCalc = total + decimal
      // console.log('total decimal remainder: ' + decimal)
      if (decimal !== 0.0) {
        decimal = (decimal.toString()).split('.')
        if ((decimal[1]) && (decimal[1][0] === '3')) {
          total += (parseFloat(decimal[0]) + 0.1)
        } else if ((decimal[1]) && (decimal[1][0] === '6')) {
          total += (parseFloat(decimal[0]) + 0.2)
        } else if ((decimal[1]) && (decimal[1][0] === '9')) {
          total += (parseFloat(decimal[0]) + 1)
        } else {
          total += parseFloat(decimal[0])
        }
      }
      return [total, totalCalc]
    },
    getAppear (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].pitching && data[i].pitching.appear) {
          total++
        }
      }
      return total
    },
    getSv (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].pitching && data[i].pitching.save) {
          total++
        }
      }
      return total
    },
    getWL (data) {
      let wins = 0
      // let losses = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].pitching && data[i].pitching.win && data[i].pitching.appear) {
          wins++
        }
        // else if (data[i] && data[i].pitching && data[i].pitching && data[i].pitching.appear) {
        //   losses++
        // }
      }
      return wins
    },
    getGS (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gs) {
          total += parseInt(data[i].gs)
        }
      }
      return total
    },
    getGP (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gp) {
          total += parseInt(data[i].gp)
        }
      }
      return total
    },
    getTB (data) {
      const total = ((this.getSumStat(data, 'hitting', 'h') - (this.getSumStat(data, 'hitting', 'double') + this.getSumStat(data, 'hitting', 'triple') + this.getSumStat(data, 'hitting', 'hr'))) * 1 + this.getSumStat(data, 'hitting', 'double') * 2 + this.getSumStat(data, 'hitting', 'triple') * 3 + this.getSumStat(data, 'hitting', 'hr') * 4)
      return total
    },
    //     getWinGame (games) {
    //       let counter = 0
    //       for (let i = 0; i < games.length; i++) {
    //         if (parseInt(games[i].byu_score) > parseInt(games[i].opp_score)) counter += 1
    //       }
    //       return counter
    //     },
    //     getLoseGame (games) {
    //       let counter = 0
    //       for (let i = 0; i < games.length; i++) {
    //         if (parseInt(games[i].byu_score) < parseInt(games[i].opp_score)) counter += 1
    //       }
    //       return counter
    //     },
    //     getTotalsByYear (year) {
    //       return this.totalsByYear.filter(total => total.schedule_year === year)
    //     },
    getPct (num, denom) {
      num = parseInt(num)
      denom = parseInt(denom)
      if (denom === 0) return 0
      const total = (num / denom).toFixed(3)
      return total
    },
    getC (data) {
      const total = (this.getSumStat(data, 'fielding', 'po') + this.getSumStat(data, 'fielding', 'a'))
      return total
    },
    getOpponent (data) {
      var oppName = ''
      if ((data.bsgame.venue.homeid !== 'BYU' && data.bsgame.venue.homeid !== 'BY' && data.bsgame.venue.homename !== 'Brigham Young University') && (data.bsgame.venue.neutralgame !== 'Y' || !data.bsgame.venue.neutralgame)) {
        oppName = oppName + '@ '
      }
      oppName = oppName + data.title
      return oppName
    },
    getScore (data) {
      var win = false
      var score = new Array(2)
      if (data.bsgame.team[0].id === 'BYU' || data.bsgame.team[0].id === 'BY' || data.bsgame.team[0].name === 'Brigham Young University') {
        score[0] = parseInt(data.bsgame.team[0].linescore.runs)
        score[1] = parseInt(data.bsgame.team[1].linescore.runs)
      } else {
        score[0] = parseInt(data.bsgame.team[1].linescore.runs)
        score[1] = parseInt(data.bsgame.team[0].linescore.runs)
      }
      if (score[0] > score[1]) {
        win = true
      }
      score = score[0] + '-' + score[1]
      return [score, win]
    },
    getBYUGameData (data) {
      var statsBYU = null
      if (data.bsgame.team[0].id === 'BYU' || data.bsgame.team[0].id === 'BY' || data.bsgame.team[0].name === 'Brigham Young University') {
        statsBYU = data.bsgame.team[0].totals
      } else {
        statsBYU = data.bsgame.team[1].totals
      }
      return statsBYU
    },
    getRecord (data) {
      var record = {
        overall: {
          wins: 0,
          losses: 0
        },
        home: {
          wins: 0,
          losses: 0
        },
        away: {
          wins: 0,
          losses: 0
        },
        neutral: {
          wins: 0,
          losses: 0
        },
        wcc: {
          wins: 0,
          losses: 0
        }
      }
      var byuScore = 0
      var oppScore = 0
      for (let i = 0; i < data.length; i++) {
        if ((data[i].bsgame.team[0].id === 'BYU') || (data[i].bsgame.team[0].id === 'BY') || (data[i].bsgame.team[0].name === 'Brigham Young University')) {
          byuScore = parseInt(data[i].bsgame.team[0].linescore.runs)
          oppScore = parseInt(data[i].bsgame.team[1].linescore.runs)
        } else {
          byuScore = parseInt(data[i].bsgame.team[1].linescore.runs)
          oppScore = parseInt(data[i].bsgame.team[0].linescore.runs)
        }
        // checks overall winner
        if (byuScore > oppScore) {
          record.overall.wins++
          // checks location
          if ((data[i].bsgame.venue.homeid === 'BYU' || data[i].bsgame.venue.homeid === 'BY' || data[i].bsgame.venue.homename === 'Brigham Young University') && (!data[i].bsgame.venue.neutralgame || data[i].bsgame.venue.neutralgame === 'N')) {
            record.home.wins++
          } else if ((data[i].bsgame.venue.visid === 'BYU' || data[i].bsgame.venue.visid === 'BY' || data[i].bsgame.venue.visname === 'Brigham Young University') && (!data[i].bsgame.venue.neutralgame || data[i].bsgame.venue.neutralgame === 'N')) {
            record.away.wins++
          } else if (data[i].bsgame.venue.neutralgame === 'Y') {
            record.neutral.wins++
          }
          if (data[i].bsgame.venue.leaguegame === 'Y') {
            record.wcc.wins++
          }
        } else if (oppScore > byuScore) {
          record.overall.losses++
          // checks location
          if ((data[i].bsgame.venue.homeid === 'BYU' || data[i].bsgame.venue.homeid === 'BY' || data[i].bsgame.venue.homename === 'Brigham Young University') && (!data[i].bsgame.venue.neutralgame || data[i].bsgame.venue.neutralgame === 'N')) {
            record.home.losses++
          } else if ((data[i].bsgame.venue.visid === 'BYU' || data[i].bsgame.venue.visid === 'BY' || data[i].bsgame.venue.visname === 'Brigham Young University') && (!data[i].bsgame.venue.neutralgame || data[i].bsgame.venue.neutralgame === 'N')) {
            record.away.losses++
          } else if (data[i].bsgame.venue.neutralgame === 'Y') {
            record.neutral.losses++
          }
          if (data[i].bsgame.venue.leaguegame === 'Y') {
            record.wcc.losses++
          }
        }
      }
      return record
    },
    getPos (data) {
      var positions = []
      for (let i = 0; i < data.length; i++) {
        if (positions.indexOf(data[i].pos) === -1 && data[i].pos !== '') {
          positions.push(data[i].pos)
        }
      }
      return positions
    },
    getTBGame (data) {
      var single = 0
      var double = 0
      var triple = 0
      var hr = 0
      if (data.hitting.double) {
        double = parseInt(data.hitting.double)
      }
      if (data.hitting.triple) {
        triple = parseInt(data.hitting.triple)
      }
      if (data.hitting.hr) {
        hr = parseInt(data.hitting.hr)
      }
      if (data.hitting.h) {
        single = parseInt(data.hitting.h) - (double + triple + hr)
      }
      const tb = (single * 1) + (double * 2) + (triple * 3) + (hr * 4)
      return tb
    },
    getOBPctGame (data) {
      var h = 0
      var bb = 0
      var hbp = 0
      var ab = 0
      var sf = 0
      if (data.hitting.h) {
        h = parseInt(data.hitting.h)
      }
      if (data.hitting.bb) {
        bb = parseInt(data.hitting.bb)
      }
      if (data.hitting.hbp) {
        hbp = parseInt(data.hitting.hbp)
      }
      if (data.hitting.ab) {
        ab = parseInt(data.hitting.ab)
      }
      if (data.hitting.sf) {
        sf = parseInt(data.hitting.sf)
      }
      const OBPct = ((h + bb + hbp) / (ab + bb + hbp + sf)).toFixed(3)
      return OBPct
    },
    setInnings (sport) {
      var innings = 9
      if (sport === '1712') {
        innings = 7
      }
      return innings
    }
  }
}
</script>
