<template>
    <div>
        <div class="table-responsive mt-3" v-if="showData">
            <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
                <thead>
                    <tr>
                        <th colspan="100%">Season Record</th>
                    </tr>
                    <tr>
                        <th>Record</th>
                        <th>Overall</th>
                        <th>Home</th>
                        <th>Away</th>
                        <th>Neutral</th>
                        <th>Conference</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="col-emphasize">ALL GAMES</td>
                    <td>{{ this.record.overall.wins }} - {{ this.record.overall.losses }}</td>
                    <td>{{ this.record.home.wins }} - {{ this.record.home.losses }}</td>
                    <td>{{ this.record.away.wins }} - {{ this.record.away.losses }}</td>
                    <td>{{ this.record.neutral.wins }} - {{ this.record.neutral.losses }}</td>
                    <td>{{ this.record.wcc.wins }} - {{ this.record.wcc.losses }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive mt-3" v-if="showData">
            <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
                <thead>
                    <tr>
                        <th colspan="100%">Team Box Score</th>
                    </tr>
                    <tr class="header-label">
                        <th colspan="2"></th>
                        <th colspan="3">Playing Time</th>
                        <th colspan="2">Shooting</th>
                        <th colspan="2">3PT Shooting</th>
                        <th colspan="2">FT Shooting</th>
                        <th colspan="4">Rebounding</th>
                        <th colspan="100%"></th>
                    </tr>
                    <tr>
                        <th>No.</th>
                        <th>Player</th>
                        <th>GP-GS</th>
                        <th>MIN</th>
                        <th>AVG</th>
                        <th>FG-FGA</th>
                        <th>FG%</th>
                        <th>3FG-FGA</th>
                        <th>3FG%</th>
                        <th>FT-FTA</th>
                        <th>FT%</th>
                        <th>ORB</th>
                        <th>DRB</th>
                        <th>RB</th>
                        <th>AVG</th>
                        <th>PF</th>
                        <th>DQ</th>
                        <th>AST</th>
                        <th>TO</th>
                        <th>BLK</th>
                        <th>STL</th>
                        <th>PTS</th>
                        <th>AVG</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(totals,index) in orderedPlayers" :key="index">
                        <td v-if="totals && totals.number" class="col-emphasize">{{ totals.number }}</td><td v-else>-</td>
                        <td v-if="totals && totals.name" class="col-emphasize">{{ totals.name }}</td><td v-else>-</td>
                        <td v-if="totals && totals.gpgs">{{ totals.gpgs[0] }} - {{ totals.gpgs[1] }}</td><td v-else>-</td>
                        <td v-if="totals && totals.min">{{ totals.min }}</td><td v-else>-</td>
                        <td v-if="totals && totals.minAvg">{{ totals.minAvg }}</td><td v-else>-</td>
                        <td v-if="totals && totals.fga">{{ totals.fg }} - {{ totals.fga }}</td><td v-else>-</td>
                        <td v-if="totals && totals.fgPct">{{ totals.fgPct }}</td><td v-else>-</td>
                        <td v-if="totals && totals.threepa">{{ totals.threepm }} - {{ totals.threepa }}</td><td v-else>-</td>
                        <td v-if="totals && totals.threePct">{{ totals.threePct }}</td><td v-else>-</td>
                        <td v-if="totals && totals.fta">{{ totals.ft }} - {{ totals.fta }}</td><td v-else>-</td>
                        <td v-if="totals && totals.ftPct">{{ totals.ftPct }}</td><td v-else>-</td>
                        <td v-if="totals && totals.orb">{{ totals.orb }}</td><td v-else>-</td>
                        <td v-if="totals && totals.drb">{{ totals.drb }}</td><td v-else>-</td>
                        <td v-if="totals && totals.rb">{{ totals.rb }}</td><td v-else>-</td>
                        <td v-if="totals && totals.rbAvg">{{ totals.rbAvg }}</td><td v-else>-</td>
                        <td v-if="totals && totals.pf">{{ totals.pf }}</td><td v-else>-</td>
                        <td v-if="totals && totals.dq">{{ totals.dq }}</td><td v-else>-</td>
                        <td v-if="totals && totals.ast">{{ totals.ast }}</td><td v-else>-</td>
                        <td v-if="totals && totals.to">{{ totals.to }}</td><td v-else>-</td>
                        <td v-if="totals && totals.blk">{{ totals.blk }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stl">{{ totals.stl }}</td><td v-else>-</td>
                        <td v-if="totals && totals.pts">{{ totals.pts }}</td><td v-else>-</td>
                        <td v-if="totals && totals.ptsAvg">{{ totals.ptsAvg }}</td><td v-else>-</td>
                    </tr>
                    <tr class="row-emphasize">
                        <td colspan="2">Totals</td>
                        <td v-if="this.totalBYU && this.totalBYU.gpgs">{{ this.totalBYU.gpgs }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.min">{{ this.totalBYU.min }}</td><td v-else>-</td>
                        <td></td>
                        <td v-if="this.totalBYU && this.totalBYU.fg && this.totalBYU.fga">{{ this.totalBYU.fg }} - {{ this.totalBYU.fga }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.fgPct">{{ this.totalBYU.fgPct }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.threepm && this.totalBYU.threepa ">{{ this.totalBYU.threepm }} - {{ this.totalBYU.threepa }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.threePct">{{ this.totalBYU.threePct }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.ft && this.totalBYU.fta">{{ this.totalBYU.ft }} - {{ this.totalBYU.fta }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.ftPct">{{ this.totalBYU.ftPct }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.orb">{{ this.totalBYU.orb }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.drb">{{ this.totalBYU.drb }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.rb">{{ this.totalBYU.rb }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.rbAvg">{{ this.totalBYU.rbAvg }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.pf">{{ this.totalBYU.pf }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.dq">{{ this.totalBYU.dq }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.ast">{{ this.totalBYU.ast }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.to">{{ this.totalBYU.to }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.blk">{{ this.totalBYU.blk }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.stl">{{ this.totalBYU.stl }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.pts">{{ this.totalBYU.pts }}</td><td v-else>-</td>
                        <td v-if="this.totalBYU && this.totalBYU.ptsAvg">{{ this.totalBYU.ptsAvg }}</td><td v-else>-</td>
                    </tr>
                    <tr class="row-emphasize">
                        <td colspan="2">Opponents</td>
                        <td v-if="this.totalOpp && this.totalOpp.gpgs">{{ this.totalOpp.gpgs }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.min">{{ this.totalOpp.min }}</td><td v-else>-</td>
                        <td></td>
                        <td v-if="this.totalOpp && this.totalOpp.fg && this.totalOpp.fga">{{ this.totalOpp.fg }} - {{ this.totalOpp.fga }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.fgPct">{{ this.totalOpp.fgPct }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.threepm && this.totalOpp.threepa">{{ this.totalOpp.threepm }} - {{ this.totalOpp.threepa }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.threePct">{{ this.totalOpp.threePct }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.ft && this.totalOpp.fta">{{ this.totalOpp.ft }} - {{ this.totalOpp.fta }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.ftPct">{{ this.totalOpp.ftPct }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.orb">{{ this.totalOpp.orb }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.drb">{{ this.totalOpp.drb }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.rb">{{ this.totalOpp.rb }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.rbAvg">{{ this.totalOpp.rbAvg }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.pf">{{ this.totalOpp.pf }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.dq">{{ this.totalOpp.dq }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.ast">{{ this.totalOpp.ast }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.to">{{ this.totalOpp.to }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.blk">{{ this.totalOpp.blk }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.stl">{{ this.totalOpp.stl }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.pts">{{ this.totalOpp.pts }}</td><td v-else>-</td>
                        <td v-if="this.totalOpp && this.totalOpp.ptsAvg">{{ this.totalOpp.ptsAvg }}</td><td v-else>-</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-responsive mt-3" v-if="showData">
            <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
                <thead>
                    <tr>
                        <th colspan="100%">Game Statistics</th>
                    </tr>
                    <tr class="header-label">
                        <th colspan="4"></th>
                        <th colspan="2">Shooting</th>
                        <th colspan="2">3PT Shooting</th>
                        <th colspan="2">FT Shooting</th>
                        <th colspan="3">Rebounding</th>
                        <th colspan="100%"></th>
                    </tr>
                    <tr>
                        <th>Opponent</th>
                        <th>Date</th>
                        <th>Result</th>
                        <th>Score</th>
                        <th>FG-FGA</th>
                        <th>FG%</th>
                        <th>3FG-FGA</th>
                        <th>3FG%</th>
                        <th>FT-FTA</th>
                        <th>FT%</th>
                        <th>ORB</th>
                        <th>DRB</th>
                        <th>RB</th>
                        <th>PF</th>
                        <th>DQ</th>
                        <th>AST</th>
                        <th>TO</th>
                        <th>BLK</th>
                        <th>STL</th>
                        <th>PTS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(totals,index) in totalGames" :key="index">
                        <td v-if="totals && totals.match" class="col-emphasize">{{ totals.match }}</td><td v-else>-</td>
                        <td v-if="totals && totals.date">{{ totals.date }}</td><td v-else>-</td>
                        <td v-if="totals && totals.score[1]" style="color: green">W</td>
                        <td v-if="totals && !totals.score[1]" style="color: red">L</td>
                        <td v-if="totals && totals.score[0]">{{ totals.score[0] }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.fgm && totals.stats.fga">{{ totals.stats.fgm }}-{{ totals.stats.fga }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.fgm && totals.stats.fga">{{ this.getPct(totals.stats.fgm, totals.stats.fga) }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.fgm3 && totals.stats.fga3">{{ totals.stats.fgm3 }}-{{ totals.stats.fga3 }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.fgm3 && totals.stats.fga3">{{ this.getPct(totals.stats.fgm3, totals.stats.fga3) }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.ftm && totals.stats.fta">{{ totals.stats.ftm }}-{{ totals.stats.fta }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.ftm && totals.stats.fta">{{ this.getPct(totals.stats.ftm, totals.stats.fta) }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.oreb">{{ totals.stats.oreb }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.dreb">{{ totals.stats.dreb }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.treb">{{ totals.stats.treb }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.pf">{{ totals.stats.pf }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.dq">{{ totals.stats.dq }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.oreb">{{ totals.stats.oreb }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.ast">{{ totals.stats.ast }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.blk">{{ totals.stats.blk }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.stl">{{ totals.stats.stl }}</td><td v-else>-</td>
                        <td v-if="totals && totals.stats && totals.stats.tp">{{ totals.stats.tp }}</td><td v-else>-</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
export default {
  props: ['selected', 'seasonData'],
  data () {
    return {
      totalBYU: null,
      totalOpp: null,
      totalPlayers: null,
      totalGames: null,
      record: null,
      showData: false // change to false later
    }
  },
  mounted () {
    // console.log(this.seasonData)
    this.totalBYU = this.setTeamTotal(this.getBYUData, this.getOppData)
    this.totalOpp = this.setTeamTotal(this.getOppData, this.getBYUData)
    this.totalPlayers = this.setPlayerTotal()
    // console.log(this.totalPlayers)
    this.totalGames = this.setGameData()
    this.record = this.getRecord(this.fixData(this.seasonData))
    // console.log(this.record)
    this.showData = true
  },
  computed: {
    orderedPlayers: function () {
      return _.orderBy(this.totalPlayers, 'number')
    }
  },
  methods: {
    setTeamTotal (getHomeData, getOppData) {
      const data = getHomeData(this.fixData(this.seasonData))
      //   const oppData = getOppData(this.fixData(this.seasonData))
      //   console.log('data and oppdata below')
      //   console.log(data)
      //   console.log(oppData)
      const totals = {
        gpgs: data.length,
        min: this.getSumStat(data, 'stats', 'min'),
        fg: this.getSumStat(data, 'stats', 'fgm'),
        fga: this.getSumStat(data, 'stats', 'fga'),
        fgPct: this.getPct(this.getSumStat(data, 'stats', 'fgm'), this.getSumStat(data, 'stats', 'fga')),
        threepm: this.getSumStat(data, 'stats', 'fgm3'),
        threepa: this.getSumStat(data, 'stats', 'fga3'),
        threePct: this.getPct(this.getSumStat(data, 'stats', 'fgm3'), this.getSumStat(data, 'stats', 'fga3')),
        ft: this.getSumStat(data, 'stats', 'ftm'),
        fta: this.getSumStat(data, 'stats', 'fta'),
        ftPct: this.getPct(this.getSumStat(data, 'stats', 'ftm'), this.getSumStat(data, 'stats', 'fta')),
        orb: this.getSumStat(data, 'stats', 'oreb'),
        drb: this.getSumStat(data, 'stats', 'dreb'),
        rb: this.getSumStat(data, 'stats', 'treb'),
        rbAvg: ((this.getSumStat(data, 'stats', 'treb')) / data.length).toFixed(1),
        pf: this.getSumStat(data, 'stats', 'pf'),
        dq: this.getSumStat(data, 'stats', 'dq'),
        ast: this.getSumStat(data, 'stats', 'ast'),
        to: this.getSumStat(data, 'stats', 'to'),
        blk: this.getSumStat(data, 'stats', 'blk'),
        stl: this.getSumStat(data, 'stats', 'stl'),
        pts: (3 * this.getSumStat(data, 'stats', 'fgm3')) + (1 * this.getSumStat(data, 'stats', 'ftm')) + (2 * (this.getSumStat(data, 'stats', 'fgm') - this.getSumStat(data, 'stats', 'fgm3'))),
        ptsAvg: (((3 * this.getSumStat(data, 'stats', 'fgm3')) + (1 * this.getSumStat(data, 'stats', 'ftm')) + (2 * (this.getSumStat(data, 'stats', 'fgm') - this.getSumStat(data, 'stats', 'fgm3')))) / data.length).toFixed(1)
      }
      return totals
    },
    setPlayerTotal () {
      const data = this.getAllPlayerData(this.fixData(this.seasonData))
      // console.log('got data in setPlayerTotal function, listed below')
      // console.log(data)
      var playerTotals = {
      }
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          var nid = data[i][j].player_nid
          if (!playerTotals[nid] && nid !== undefined) {
            var playerData = this.getPlayerData(data, nid)
            // console.log('data for player with nid ' + nid)
            // console.log(playerData)
            playerTotals[nid] = {
              name: playerData[0].name,
              number: parseInt(playerData[0].uni),
              gpgs: [this.getGP(playerData), this.getGS(playerData)],
              min: this.getSumStat(playerData, 'stats', 'min'),
              minAvg: this.getAvg(this.getSumStat(playerData, 'stats', 'min'), this.getGP(playerData)),
              fg: this.getSumStat(playerData, 'stats', 'fgm'),
              fga: this.getSumStat(playerData, 'stats', 'fga'),
              fgPct: this.getPct(this.getSumStat(playerData, 'stats', 'fgm'), this.getSumStat(playerData, 'stats', 'fga')),
              threepm: this.getSumStat(playerData, 'stats', 'fgm3'),
              threepa: this.getSumStat(playerData, 'stats', 'fga3'),
              threePct: this.getPct(this.getSumStat(playerData, 'stats', 'fgm3'), this.getSumStat(playerData, 'stats', 'fga3')),
              ft: this.getSumStat(playerData, 'stats', 'ftm'),
              fta: this.getSumStat(playerData, 'stats', 'fta'),
              ftPct: this.getPct(this.getSumStat(playerData, 'stats', 'ftm'), this.getSumStat(playerData, 'stats', 'fta')),
              orb: this.getSumStat(playerData, 'stats', 'oreb'),
              drb: this.getSumStat(playerData, 'stats', 'dreb'),
              rb: this.getSumStat(playerData, 'stats', 'treb'),
              rbAvg: this.getAvg(this.getSumStat(playerData, 'stats', 'treb'), this.getGP(playerData)),
              pf: this.getSumStat(playerData, 'stats', 'pf'),
              dq: this.getSumStat(playerData, 'stats', 'dq'),
              ast: this.getSumStat(playerData, 'stats', 'ast'),
              to: this.getSumStat(playerData, 'stats', 'to'),
              blk: this.getSumStat(playerData, 'stats', 'blk'),
              stl: this.getSumStat(playerData, 'stats', 'stl'),
              pts: (3 * this.getSumStat(playerData, 'stats', 'fgm3')) + (1 * this.getSumStat(playerData, 'stats', 'ftm')) + (2 * (this.getSumStat(playerData, 'stats', 'fgm') - this.getSumStat(playerData, 'stats', 'fgm3'))),
              ptsAvg: this.getAvg((3 * this.getSumStat(playerData, 'stats', 'fgm3')) + (1 * this.getSumStat(playerData, 'stats', 'ftm')) + (2 * (this.getSumStat(playerData, 'stats', 'fgm') - this.getSumStat(playerData, 'stats', 'fgm3'))), this.getGP(playerData))
            }
          }
        }
      }
      // console.log(playerTotals)
      return playerTotals
    },
    setGameData () {
      const data = this.fixData(this.seasonData)
      var games = []
      for (let i = 0; i < data.length; i++) {
        games.push({
          nid: data[i].nid,
          match: this.getOpponent(data[i]),
          date: data[i].bbgame.venue.date,
          dateFull: new Date(data[i].event_date.split('T')[0]),
          score: this.getScore(data[i]),
          stats: this.getBYUGameData(data[i])
        }
        )
      }
      // sorts games by date
      const sortedGames = games.sort((a, b) => a.dateFull - b.dateFull)
      // console.log('games after sort')
      // console.log(sortedGames)
      return sortedGames
    },
    getBYUData (data) {
      var dataBYU = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].bbgame.team[0].id === 'BYU' || data[i].bbgame.team[0].id === 'BY' || data[i].bbgame.team[0].name === 'Brigham Young University') {
          // console.log('the first team was byu')
          dataBYU[i] = (data[i].bbgame.team[0].totals)
        } else {
          // console.log('the second team was byu')
          dataBYU[i] = (data[i].bbgame.team[1].totals)
        }
      }
      // console.log(dataBYU)
      return dataBYU
    },
    getOppData (data) {
      var dataOpp = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].bbgame.team[0].id !== 'BYU' && data[i].bbgame.team[0].id !== 'BY' && data[i].bbgame.team[0].name !== 'Brigham Young University') {
          // console.log('the first team was the opponent')
          dataOpp[i] = (data[i].bbgame.team[0].totals)
        } else {
          // console.log('the second team was the opponent')
          dataOpp[i] = (data[i].bbgame.team[1].totals)
        }
      }
      // console.log(dataOpp)
      return dataOpp
    },
    getAllPlayerData (data) {
      var AllPlayerData = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].bbgame.team[0].id === 'BYU' || data[i].bbgame.team[0].id === 'BY' || data[i].bbgame.team[0].name === 'Brigham Young University') {
          AllPlayerData[i] = (data[i].bbgame.team[0].player)
        } else {
          AllPlayerData[i] = (data[i].bbgame.team[1].player)
        }
      }
      // console.log('AllPlayerData: ')
      // console.log(AllPlayerData)
      return AllPlayerData
    },
    getPlayerData (data, nid) {
      var playerData = []
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          if (data[i][j].player_nid === nid) {
            playerData.push(data[i][j])
          }
        }
      }
      //   console.log(playerData)
      return playerData
    },
    getBYUGameData (data) {
      var statsBYU = null
      if (data.bbgame.team[0].id === 'BYU' || data.bbgame.team[0].id === 'BY' || data.bbgame.team[0].name === 'Brigham Young University') {
        statsBYU = data.bbgame.team[0].totals.stats
      } else if (data.bbgame.team[1].id === 'BYU' || data.bbgame.team[1].id === 'BY' || data.bbgame.team[1].name === 'Brigham Young University') {
        statsBYU = data.bbgame.team[1].totals.stats
      }
      return statsBYU
    },
    fixData (data) {
      var fixedData = []
      for (let i = 0; i < data.length; i++) {
        // hopefully handles the issue where game arrays have an extra empty array before the actual info
        if (data[i][0]) {
          data[i] = data[i][0]
        }
        // removes all games that don't include bbgame objects
        if (data[i].bbgame) {
          fixedData.push(data[i])
        } else {
          // console.log('found an object without bbgame')
        }
      }
      return fixedData
    },
    getSumStat (data, categoryKey, statsKey) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i][categoryKey] && data[i][categoryKey][statsKey]) {
          total += parseInt(data[i][categoryKey][statsKey])
        } else {
          // console.log(statsKey + ' not found in ' + categoryKey)
        }
      }
      return total
    },
    getPct (num, denom) {
      if (denom === 0) return null
      const total = (num / denom).toFixed(3)
      return total
    },
    getAvg (num, denom) {
      if (denom === 0) return null
      const total = (num / denom).toFixed(1)
      return total
    },
    getGS (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gs) {
          total += parseInt(data[i].gs)
        }
      }
      return total
    },
    getGP (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gp) {
          total += parseInt(data[i].gp)
        }
      }
      return total
    },
    getOpponent (data) {
      var oppName = ''
      if ((data.bbgame.venue.homeid !== 'BYU' && data.bbgame.venue.homeid !== 'BY' && data.bbgame.venue.homename !== 'Brigham Young University') && data.bbgame.venue.neutralgame !== 'Y') {
        // console.log(data.bbgame.venue.homeid)
        // console.log(data.bbgame.venue.neutralgame)
        oppName = oppName + '@ '
      }
      oppName = oppName + data.title
      return oppName
    },
    getScore (data) {
      var win = false
      var score = new Array(2)
      if ((data.bbgame.team[0].id === 'BYU') || (data.bbgame.team[0].id === 'BY') || (data.bbgame.team[0].name === 'Brigham Young University')) {
        score[0] = parseInt(data.bbgame.team[0].linescore.score)
        score[1] = parseInt(data.bbgame.team[1].linescore.score)
      } else if ((data.bbgame.team[1].id === 'BYU') || (data.bbgame.team[1].id === 'BY') || (data.bbgame.team[1].name === 'Brigham Young University')) {
        score[0] = parseInt(data.bbgame.team[1].linescore.score)
        score[1] = parseInt(data.bbgame.team[0].linescore.score)
      } else {
        // console.log('error while getting score in game below')
        // console.log(data)
        return null
      }
      if (score[0] > score[1]) {
        win = true
      }
      score = score[0] + '-' + score[1]
      return [score, win]
    },
    getRecord (data) {
      var record = {
        overall: {
          wins: 0,
          losses: 0
        },
        home: {
          wins: 0,
          losses: 0
        },
        away: {
          wins: 0,
          losses: 0
        },
        neutral: {
          wins: 0,
          losses: 0
        },
        wcc: {
          wins: 0,
          losses: 0
        }
      }
      var byuScore = 0
      var oppScore = 0
      for (let i = 0; i < data.length; i++) {
        if ((data[i].bbgame.team[0].id === 'BYU') || (data[i].bbgame.team[0].id === 'BY') || (data[i].bbgame.team[0].name === 'Brigham Young University')) {
          byuScore = parseInt(data[i].bbgame.team[0].linescore.score)
          oppScore = parseInt(data[i].bbgame.team[1].linescore.score)
        } else {
          byuScore = parseInt(data[i].bbgame.team[1].linescore.score)
          oppScore = parseInt(data[i].bbgame.team[0].linescore.score)
        }
        // checks overall winner
        if (byuScore > oppScore) {
          record.overall.wins++
          // checks location
          if ((data[i].bbgame.venue.homeid === 'BYU' || data[i].bbgame.venue.homeid === 'BY' || data[i].bbgame.venue.homename === 'Brigham Young University') && (!data[i].bbgame.venue.neutralgame || data[i].bbgame.venue.neutralgame === 'N')) {
            record.home.wins++
          } else if ((data[i].bbgame.venue.visid === 'BYU' || data[i].bbgame.venue.visid === 'BY' || data[i].bbgame.venue.visname === 'Brigham Young University') && (!data[i].bbgame.venue.neutralgame || data[i].bbgame.venue.neutralgame === 'N')) {
            record.away.wins++
          } else if (data[i].bbgame.venue.neutralgame === 'Y') {
            record.neutral.wins++
          }
          if (data[i].bbgame.venue.leaguegame === 'Y') {
            record.wcc.wins++
          }
        } else if (oppScore > byuScore) {
          record.overall.losses++
          // checks location
          if ((data[i].bbgame.venue.homeid === 'BYU' || data[i].bbgame.venue.homeid === 'BY' || data[i].bbgame.venue.homename === 'Brigham Young University') && (!data[i].bbgame.venue.neutralgame || data[i].bbgame.venue.neutralgame === 'N')) {
            record.home.losses++
          } else if ((data[i].bbgame.venue.visid === 'BYU' || data[i].bbgame.venue.visid === 'BY' || data[i].bbgame.venue.visname === 'Brigham Young University') && (!data[i].bbgame.venue.neutralgame || data[i].bbgame.venue.neutralgame === 'N')) {
            record.away.losses++
          } else if (data[i].bbgame.venue.neutralgame === 'Y') {
            record.neutral.losses++
          }
          if (data[i].bbgame.venue.leaguegame === 'Y') {
            record.wcc.losses++
          }
        }
      }
      return record
    }
  }
}
</script>
