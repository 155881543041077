<template>
  <div class="game-description" nowrap>
    <!-- 2017 Concordia Irvine -->
    <h6 style="text-align:left;"><strong v-if="stats.vbgame.venue.location">Location:</strong> {{ stats.vbgame.venue.location }} </h6>
    <h6 style="text-align:left;"><strong v-if="stats.vbgame.venue.time">Time:</strong> {{ stats.vbgame.venue.time }} <strong>Date:</strong> {{ stats.vbgame.venue.date }} </h6>
    <h6 style="text-align:left;"><strong v-if="stats.vbgame.venue.attend">Attendance:</strong> {{ stats.vbgame.venue.attend }} </h6>
  </div>

  <div>
    <div class="table-responsive">
      <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
        <thead>
          <tr>
            <th colspan="100%">
              Box Score
            </th>
          </tr>
          <tr>
            <th nowrap>Set Scores</th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th v-if="stats.vbgame.breakdowns[0].breakdown[3]">4</th>
            <th v-if="stats.vbgame.breakdowns[0].breakdown[4]">5</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i, teamIndex) in 2" :key="teamIndex">
            <td class="col-emphasize">{{stats.vbgame.team[teamIndex].id}} </td>
            <td >{{stats.vbgame.team[teamIndex].linescore.linegame[0].points}}</td>
            <td >{{stats.vbgame.team[teamIndex].linescore.linegame[1].points}}</td>
            <td >{{stats.vbgame.team[teamIndex].linescore.linegame[2].points}}</td>
            <td v-if="stats.vbgame.team[teamIndex].linescore.linegame[3]">{{stats.vbgame.team[teamIndex].linescore.linegame[3].points}}</td>
            <td v-if="stats.vbgame.team[teamIndex].linescore.linegame[4]">{{stats.vbgame.team[teamIndex].linescore.linegame[4].points}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-3" v-for="(i, teamIndex) in 2" :key="teamIndex">
      <h6 style="text-align:left;"><strong>{{stats.vbgame.team[teamIndex].name}}</strong> <small>{{stats.vbgame.team[teamIndex].record}}</small></h6>
      <div class="table-responsive">
        <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
          <thead>
            <tr>
              <th >NO.</th>
              <th style="text-align:left;">Athlete</th>
              <th title="Sets">S</th>
              <th title="Kills">K</th>
              <th title="Errors">E</th>
              <th title="Total Attemps">TA</th>
              <th title="Hitting Pecentage">Pct</th>
              <th title="Assists">Ast</th>
              <th title="Service Aces">SA</th>
              <th title="Service Errors">SE</th>
              <th title="Reception Errors">RE</th>
              <th title="Digs">Digs</th>
              <th title="Block Solos">BS</th>
              <th title="Block Assisted">BA</th>
              <th title="Block Errors">BE</th>
              <th title="Ball Handling Errors">BHE</th>
              <th title="Points">Pts</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="player in playersInGameFilter(teamIndex)" :key="player.uni">
              <td v-if="player.uni" class="col-emphasize">{{player.uni}}</td><td v-else>-</td>
              <td nowrap style="text-align:left;" class="col-emphasize">{{player.name}}</td>
              <td v-if="player.gp">{{player.gp}}</td><td v-else>-</td>
              <td v-if="player.attack && player.attack.k">{{player.attack.k}}</td><td v-else>-</td>
              <td v-if="player.attack && player.attack.e">{{player.attack.e}}</td><td v-else>-</td>
              <td v-if="player.attack && player.attack.ta">{{player.attack.ta}}</td><td v-else>-</td>
              <td v-if="player.attack && player.attack.pct">{{player.attack.pct}}</td><td v-else>-</td>
              <td v-if="player.set && player.set.a">{{player.set.a}}</td><td v-else>-</td>
              <td v-if="player.serve && player.serve.sa">{{player.serve.sa}}</td><td v-else>-</td>
              <td v-if="player.serve && player.serve.se">{{player.serve.se}}</td><td v-else>-</td>
              <td v-if="player.defense && player.defense.re">{{player.defense.re}}</td><td v-else>-</td>
              <td v-if="player.defense && player.defense.dig">{{player.defense.dig}}</td><td v-else>-</td>
              <td v-if="player.block && player.block.bs">{{player.block.bs}}</td><td v-else>-</td>
              <td v-if="player.block && player.block.ba">{{player.block.ba}}</td><td v-else>-</td>
              <td v-if="player.block && player.block.be">{{player.block.be}}</td><td v-else>-</td>
              <td v-if="player.misc && player.misc.bhe">{{player.misc.bhe}}</td><td v-else>-</td>
              <td v-if="player.misc && player.misc.pts">{{player.misc.pts}}</td><td v-else>-</td>
            </tr>
            <tr class="row-emphasize">
              <td></td>
              <td style="text-align:left">Totals</td>
              <td></td>
              <td>{{stats.vbgame.team[teamIndex].totals.attack.k}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.attack.e}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.attack.ta}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.attack.pct}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.set.a}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.serve.sa}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.serve.se}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.defense.re}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.defense.dig}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.block.bs}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.block.ba}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.block.be}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.misc.bhe}}</td>
              <td>{{stats.vbgame.team[teamIndex].totals.misc.pts}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <h4>Team Attack Stats By Set</h4>
    <div class="mt-3" v-for="(i, teamIndex) in 2" :key="teamIndex">
      <h6 style="text-align:left;"><strong>{{stats.vbgame.team[teamIndex].name}}</strong> <small>{{stats.vbgame.team[teamIndex].linescore.score}} sets {{stats.vbgame.breakdowns[teamIndex].block}} blocks</small> </h6>
      <div class="table-responsive">
        <table class=" table-striped table-sm table-condensed table table-hover table-bordered total_up">
          <thead>
            <tr>
              <th style="text-align:left">Set</th>
              <th>K</th>
              <th>E</th>
              <th>TA</th>
              <th>Pct</th>
              <th>Pts</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(set ,index) in stats.vbgame.team[teamIndex].attackbygame.attackgame" :key="index">
              <td style="text-align:left" class="col-emphasize">{{set.game}}</td>
              <td>{{set.k}}</td>
              <td>{{set.e}}</td>
              <td>{{set.ta}}</td>
              <td>{{set.pct}}</td>
              <td v-if="set.game == '1'">{{stats.vbgame.team[teamIndex].linescore.linegame[0].points}}</td>
              <td v-if="set.game == '2'">{{stats.vbgame.team[teamIndex].linescore.linegame[1].points}}</td>
              <td v-if="set.game == '3'">{{stats.vbgame.team[teamIndex].linescore.linegame[2].points}}</td>
              <td v-if="set.game == '4'">{{stats.vbgame.team[teamIndex].linescore.linegame[3].points}}</td>
              <td v-if="set.game == '5'">{{stats.vbgame.team[teamIndex].linescore.linegame[4].points}}</td>
              <td v-if="set.game == '6'">{{stats.vbgame.team[teamIndex].linescore.linegame[5].points}}</td>
            </tr>
            <tr class="row-emphasize">
              <th style="text-align:left">Totals</th>
              <th>{{stats.vbgame.team[teamIndex].totals.attack.k}}</th>
              <th>{{stats.vbgame.team[teamIndex].totals.attack.e}}</th>
              <th>{{stats.vbgame.team[teamIndex].totals.attack.ta}}</th>
              <th>{{stats.vbgame.team[teamIndex].totals.attack.pct}}</th>
              <th>{{stats.vbgame.breakdowns[teamIndex].pts}}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
    <div v-if="stats.vbgame.plays.game">
    <h4>Play By Play</h4>
    <div v-for="(i, index) in stats.vbgame.plays.game" :key="index" class="table-responsive">
      <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
        <thead>
          <tr>
            <th colspan="100%">{{stats.vbgame.plays.game[index].number}}</th>
          </tr>
          <tr>
            <th>Team</th>
            <th>Play</th>
            <th>{{stats.vbgame.venue.visid}}</th>
            <th>{{stats.vbgame.venue.homeid}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(i,play) in stats.vbgame.plays.game[index].play" :key="play">
            <td v-if="stats.vbgame.plays.game[index].play[play].serveteam">{{stats.vbgame.plays.game[index].play[play].serveteam}}</td><td v-else>-</td>
            <td v-if="stats.vbgame.plays.game[index].play[play].text">{{stats.vbgame.plays.game[index].play[play].text}}</td>
            <td v-if="stats.vbgame.plays.game[index].play[play].vscore">{{stats.vbgame.plays.game[index].play[play].vscore}}</td><td v-else></td>
            <td v-if="stats.vbgame.plays.game[index].play[play].hscore">{{stats.vbgame.plays.game[index].play[play].hscore}}</td><td v-else></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VolleyballScoreBox',
  props: ['selected', 'stats'],
  mounted () {
    console.log(this.stats)
  },
  methods: {
    playersInGameFilter (teamIndex) {
      return this.stats.vbgame.team[teamIndex].player.filter(player => (player.gp !== '0' && player.uni !== 'TM'))
    }
  }
}
</script>

<style scoped>
thead {
  color: white;
  background: #003FA2;
}
</style>
