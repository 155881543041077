<template>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan="100%">Season Record</th>
        </tr>
        <tr>
          <th>Record</th>
          <th>Overall</th>
          <th>Home</th>
          <th>Away</th>
          <th>Neutral</th>
          <th>Conference</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="col-emphasize">ALL GAMES</td>
          <td>{{ this.record.overall.wins }} - {{ this.record.overall.losses }}</td>
          <td>{{ this.record.home.wins }} - {{ this.record.home.losses }}</td>
          <td>{{ this.record.away.wins }} - {{ this.record.away.losses }}</td>
          <td>{{ this.record.neutral.wins }} - {{ this.record.neutral.losses }}</td>
          <td>{{ this.record.wcc.wins }} - {{ this.record.wcc.losses }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan="100%">Game Results</th>
        </tr>
        <tr class="header-label">
          <th colspan="4"></th>
          <th colspan="2">Record</th>
          <th colspan="2"></th>
        </tr>
        <tr>
          <th>Opponent</th>
          <th>Date</th>
          <th>Result</th>
          <th>Score</th>
          <th>Overall</th>
          <th>Conf.</th>
          <th>Time</th>
          <th>Attend</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(totals,index) in totalGames" :key="index">
          <td v-if="totals && totals.match" class="col-emphasize">{{ totals.match }}</td><td v-else>-</td>
          <td v-if="totals && totals.date">{{ totals.date }}</td><td v-else>-</td>
          <td v-if="totals && totals.score[1]" style="color: green">W</td>
          <td v-if="totals && !totals.score[1]" style="color: red">L</td>
          <td v-if="totals && totals.score[0]">{{ totals.score[0] }}</td><td v-else>-</td>
          <td v-if="totals && totals.record">{{ totals.record }}</td><td v-else>-</td>
          <td v-if="totals && totals.confRecord">{{ totals.confRecord }}</td><td v-else>-</td>
          <td v-if="totals && totals.time">{{ totals.time }}</td><td v-else>-</td>
          <td v-if="totals && totals.attendance">{{ totals.attendance }}</td><td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan="100%">Team Statistics</th>
        </tr>
        <tr>
          <th colspan="1"></th>
          <th colspan="1">BYU</th>
          <th colspan="1">Opp</th>
        </tr>
      </thead>
      <tbody>
        <tr class="row-emphasize row-label">
          <td>Rushing Yardage</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.yds">{{ this.totalBYU.rush.yds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.yds">{{ this.totalOpp.rush.yds }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Attempts</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.att">{{ this.totalBYU.rush.att }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.att">{{ this.totalOpp.rush.att }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Avg Per Attempt</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.att && this.totalBYU.rush.yds">{{ this.getAvg(this.totalBYU.rush.yds, this.totalBYU.rush.att) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.att && this.totalOpp.rush.yds">{{ this.getAvg(this.totalOpp.rush.yds, this.totalOpp.rush.att) }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Avg Per Game</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.yds">{{ this.getAvg(this.totalBYU.rush.yds, this.totalBYU.gpgs) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.yds">{{ this.getAvg(this.totalOpp.rush.yds, this.totalOpp.gpgs) }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>TDs</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.td">{{ this.totalBYU.rush.td }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.td">{{ this.totalOpp.rush.td }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Yardage Gained</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.gain">{{ this.totalBYU.rush.gain }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.gain">{{ this.totalOpp.rush.gain }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Yardage Lost</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.loss">{{ this.totalBYU.rush.loss }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.loss">{{ this.totalOpp.rush.loss }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize row-label">
          <td>Passing Yardage</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.yds">{{ this.totalBYU.pass.yds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.yds">{{ this.totalOpp.pass.yds }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Attempts</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.att">{{ this.totalBYU.pass.att }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.att">{{ this.totalOpp.pass.att }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Completion Percentage</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.att && this.totalBYU.pass.comp">{{ ((this.totalBYU.pass.comp / this.totalBYU.pass.att) * 100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.att && this.totalOpp.pass.comp">{{ ((this.totalOpp.pass.comp / this.totalOpp.pass.att) * 100).toFixed(1) }}%</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Avg Per Pass</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.yds && this.totalBYU.pass.att">{{ this.getAvg(this.totalBYU.pass.yds, this.totalBYU.pass.att) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.yds && this.totalOpp.pass.att">{{ this.getAvg(this.totalOpp.pass.yds, this.totalOpp.pass.att) }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Avg Per Game</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.yds && this.totalBYU.gpgs">{{ this.getAvg(this.totalBYU.pass.yds, this.totalBYU.gpgs) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.yds && this.totalOpp.gpgs">{{ this.getAvg(this.totalOpp.pass.yds, this.totalOpp.gpgs) }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Interceptions</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.int">{{ this.totalBYU.pass.int }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.int">{{ this.totalOpp.pass.int }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>TDs</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.td">{{ this.totalBYU.pass.td }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.td">{{ this.totalOpp.pass.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize row-label">
          <td>First Downs</td>
          <td v-if="this.totalBYU && this.totalBYU.firstdowns && this.totalBYU.firstdowns.no">{{ this.totalBYU.firstdowns.no }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.firstdowns && this.totalOpp.firstdowns.no">{{ this.totalOpp.firstdowns.no }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Rushing</td>
          <td v-if="this.totalBYU && this.totalBYU.firstdowns && this.totalBYU.firstdowns.rush">{{ this.totalBYU.firstdowns.rush }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.firstdowns && this.totalOpp.firstdowns.rush">{{ this.totalOpp.firstdowns.rush }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Passing</td>
          <td v-if="this.totalBYU && this.totalBYU.firstdowns && this.totalBYU.firstdowns.pass">{{ this.totalBYU.firstdowns.pass }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.firstdowns && this.totalOpp.firstdowns.pass">{{ this.totalOpp.firstdowns.pass }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Penalty</td>
          <td v-if="this.totalBYU && this.totalBYU.firstdowns && this.totalBYU.firstdowns.penalty">{{ this.totalBYU.firstdowns.penalty }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.firstdowns && this.totalOpp.firstdowns.penalty">{{ this.totalOpp.firstdowns.penalty }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize row-label">
          <td>Third Down Conversions</td>
          <td v-if="this.totalBYU && this.totalBYU.conversions && this.totalBYU.conversions.thirdatt && this.totalBYU.conversions.thirdconv">{{ this.totalBYU.conversions.thirdconv }} / {{ this.totalBYU.conversions.thirdatt }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.conversions && this.totalOpp.conversions.thirdatt && this.totalOpp.conversions.thirdconv">{{ this.totalOpp.conversions.thirdconv }} / {{ this.totalOpp.conversions.thirdatt }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize row-label">
          <td>Fourth Down Conversions</td>
          <td v-if="this.totalBYU && this.totalBYU.conversions && this.totalBYU.conversions.fourthatt && this.totalBYU.conversions.fourthconv">{{ this.totalBYU.conversions.fourthconv }} / {{ this.totalBYU.conversions.fourthatt }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.conversions && this.totalOpp.conversions.fourthatt && this.totalOpp.conversions.fourthconv">{{ this.totalOpp.conversions.fourthconv }} / {{ this.totalOpp.conversions.fourthatt }}</td><td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan="100%">Game Statistics</th>
        </tr>
        <tr class="header-label">
          <th colspan="3"></th>
          <th colspan="5">Scoring</th>
          <th colspan="3">Rushing</th>
          <th colspan="5">Passing</th>
          <th colspan="7">Defense</th>
        </tr>
        <tr>
          <th>Opponent</th>
          <th>Date</th>
          <th>Result</th>
          <th>Score</th>
          <th>TD</th>
          <th>PAT</th>
          <th>TPC</th>
          <th>FG</th>
          <th>Yds</th>
          <th>Att</th>
          <th>TD</th>
          <th>Yds</th>
          <th>Att</th>
          <th>Compl. %</th>
          <th>Int</th>
          <th>TD</th>
          <th>Ast Tack</th>
          <th>UnA Tack</th>
          <th>Tot. Tack</th>
          <th>Sack</th>
          <th>Int</th>
          <th>Fumble Forced</th>
          <th>Fumble Recov.</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(totals,index) in totalGames" :key="index">
          <td v-if="totals && totals.match" class="col-emphasize">{{ totals.match }}</td><td v-else>-</td>
          <td v-if="totals && totals.date">{{ totals.date }}</td><td v-else>-</td>
          <td v-if="totals && totals.score[1]" style="color: green">W</td>
          <td v-if="totals && !totals.score[1]" style="color: red">L</td>
          <td v-if="totals && totals.score[0]">{{ totals.score[0] }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.scoring && totals.stats.scoring.td">{{ totals.stats.scoring.td }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.scoring && totals.stats.scoring.patkick">{{ totals.stats.scoring.patkick }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.scoring && totals.stats.scoring.patrcv">{{ totals.stats.scoring.patrcv }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.scoring && totals.stats.scoring.fg">{{ totals.stats.scoring.fg }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.rush && totals.stats.rush.yds">{{ totals.stats.rush.yds }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.rush && totals.stats.rush.att">{{ totals.stats.rush.att }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.rush && totals.stats.rush.td">{{ totals.stats.rush.td }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.pass && totals.stats.pass.yds">{{ totals.stats.pass.yds }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.pass && totals.stats.pass.att">{{ totals.stats.pass.att }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.pass && totals.stats.pass.comp && totals.stats.pass.att">{{ ((totals.stats.pass.comp / totals.stats.pass.att) * 100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="totals && totals.stats.pass && totals.stats.pass.int">{{ totals.stats.pass.int }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.pass && totals.stats.pass.td">{{ totals.stats.pass.td }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.defense && totals.stats.defense.tacka">{{ totals.stats.defense.tacka }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.defense && totals.stats.defense.tackua">{{ totals.stats.defense.tackua }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.defense && totals.stats.defense.tot_tack">{{ totals.stats.defense.tot_tack }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.defense && totals.stats.defense.sacks">{{ totals.stats.defense.sacks }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.defense && totals.stats.defense.int">{{ totals.stats.defense.int }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.defense && totals.stats.defense.ff">{{ totals.stats.defense.ff }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats.defense && totals.stats.defense.fr">{{ totals.stats.defense.fr }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="4">Total</td>
          <td v-if="this.totalBYU && this.totalBYU.scoring && this.totalBYU.scoring.td">{{ this.totalBYU.scoring.td }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.scoring && this.totalBYU.scoring.patkick">{{ this.totalBYU.scoring.patkick }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.scoring && this.totalBYU.scoring.patrcv">{{ this.totalBYU.scoring.patrcv }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.scoring && this.totalBYU.scoring.fg">{{ this.totalBYU.scoring.fg }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.yds">{{ this.totalBYU.rush.yds }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.att">{{ this.totalBYU.rush.att }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.td">{{ this.totalBYU.rush.td }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.yds">{{ this.totalBYU.pass.yds }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.att">{{ this.totalBYU.pass.att }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.comp && this.totalBYU.pass.att">{{ ((this.totalBYU.pass.comp / this.totalBYU.pass.att) * 100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.int">{{ this.totalBYU.pass.int }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.td">{{ this.totalBYU.pass.td }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.tacka">{{ this.totalBYU.defense.tacka }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.tackua">{{ this.totalBYU.defense.tackua }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.tot_tack">{{ this.totalBYU.defense.tot_tack }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.sacks">{{ this.totalBYU.defense.sacks }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.int">{{ this.totalBYU.defense.int }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.ff">{{ this.totalBYU.defense.ff }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.fr">{{ this.totalBYU.defense.fr }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="4">Opponents</td>
          <td v-if="this.totalOpp && this.totalOpp.scoring && this.totalOpp.scoring.td">{{ this.totalOpp.scoring.td }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.scoring && this.totalOpp.scoring.patkick">{{ this.totalOpp.scoring.patkick }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.scoring && this.totalOpp.scoring.patrcv">{{ this.totalOpp.scoring.patrcv }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.scoring && this.totalOpp.scoring.fg">{{ this.totalOpp.scoring.fg }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.yds">{{ this.totalOpp.rush.yds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.att">{{ this.totalOpp.rush.att }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.td">{{ this.totalOpp.rush.td }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.yds">{{ this.totalOpp.pass.yds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.att">{{ this.totalOpp.pass.att }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.comp && this.totalOpp.pass.att">{{ ((this.totalOpp.pass.comp / this.totalOpp.pass.att) * 100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.int">{{ this.totalOpp.pass.int }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.td">{{ this.totalOpp.pass.td }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.tacka">{{ this.totalOpp.defense.tacka }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.tackua">{{ this.totalOpp.defense.tackua }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.tot_tack">{{ this.totalOpp.defense.tot_tack }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.sacks">{{ this.totalOpp.defense.sacks }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.int">{{ this.totalOpp.defense.int }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.ff">{{ this.totalOpp.defense.ff }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.fr">{{ this.totalOpp.defense.fr }}</td><td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan=100%>Rushing</th>
        </tr>
        <tr class="header-label">
          <th colspan="4"></th>
          <th colspan="5">Yards</th>
          <th colspan="1"></th>
        </tr>
        <tr>
          <th>#</th>
          <th>Player</th>
          <th>GP</th>
          <th>Att</th>
          <th>Gain</th>
          <th>Loss</th>
          <th>Total</th>
          <th>Avg</th>
          <th>Game Avg</th>
          <th>TD</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(player,index) in orderedRush" :key="index">
          <td v-if="player && player.number" class="col-emphasize">{{ player.number }}</td><td v-else>-</td>
          <td v-if="player && player.name" class="col-emphasize">{{ player.name }}</td><td v-else>-</td>
          <td v-if="player && player.gpgs[0]">{{ player.gpgs[0] }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rush && player.stats.rush.att">{{ player.stats.rush.att }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rush && player.stats.rush.gain">{{ player.stats.rush.gain }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rush && player.stats.rush.loss">{{ player.stats.rush.loss }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rush && player.stats.rush.yds">{{ player.stats.rush.yds }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rush && player.stats.rush.yds && player.stats.rush.att">{{ this.getAvg(player.stats.rush.yds, player.stats.rush.att) }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rush && player.stats.rush.yds && player.gpgs[0]">{{ this.getAvg(player.stats.rush.yds, player.gpgs[0]) }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rush && player.stats.rush.td">{{ player.stats.rush.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Total</td>
          <td v-if="this.totalBYU && this.totalBYU.gpgs">{{ this.totalBYU.gpgs }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.att">{{ this.totalBYU.rush.att }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.gain">{{ this.totalBYU.rush.gain }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.loss">{{ this.totalBYU.rush.loss }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.yds">{{ this.totalBYU.rush.yds }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.yds && this.totalBYU.rush.att">{{ this.getAvg(this.totalBYU.rush.yds, this.totalBYU.rush.att) }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.yds && this.totalBYU.gpgs">{{ this.getAvg(this.totalBYU.rush.yds, this.totalBYU.gpgs) }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rush && this.totalBYU.rush.td">{{ this.totalBYU.rush.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Opponents</td>
          <td v-if="this.totalOpp && this.totalOpp.gpgs">{{ this.totalOpp.gpgs }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.att">{{ this.totalOpp.rush.att }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.gain">{{ this.totalOpp.rush.gain }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.loss">{{ this.totalOpp.rush.loss }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.yds">{{ this.totalOpp.rush.yds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.yds && this.totalOpp.rush.att">{{ this.getAvg(this.totalOpp.rush.yds, this.totalOpp.rush.att) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.yds && this.totalOpp.gpgs">{{ this.getAvg(this.totalOpp.rush.yds, this.totalOpp.gpgs) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rush && this.totalOpp.rush.td">{{ this.totalOpp.rush.td }}</td><td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan=100%>Passing</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Player</th>
          <th>GP</th>
          <th>Att</th>
          <th>Comp</th>
          <th>Int</th>
          <th>Compl. %</th>
          <th>Yds</th>
          <th>Game Avg</th>
          <th>TD</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(player,index) in orderedPass" :key="index">
          <td v-if="player && player.number" class="col-emphasize">{{ player.number }}</td><td v-else>-</td>
          <td v-if="player && player.name" class="col-emphasize">{{ player.name }}</td><td v-else>-</td>
          <td v-if="player && player.gpgs[0]">{{ player.gpgs[0] }}</td><td v-else>-</td>
          <td v-if="player && player.stats.pass && player.stats.pass.att">{{ player.stats.pass.att }}</td><td v-else>-</td>
          <td v-if="player && player.stats.pass && player.stats.pass.comp">{{ player.stats.pass.comp }}</td><td v-else>-</td>
          <td v-if="player && player.stats.pass && player.stats.pass.int">{{ player.stats.pass.int }}</td><td v-else>-</td>
          <td v-if="player && player.stats.pass && player.stats.pass.comp && player.stats.pass.att">{{ ((player.stats.pass.comp / player.stats.pass.att) * 100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="player && player.stats.pass && player.stats.pass.yds">{{ player.stats.pass.yds }}</td><td v-else>-</td>
          <td v-if="player && player.stats.pass && player.stats.pass.yds && player.gpgs[0]">{{ this.getAvg(player.stats.pass.yds, player.gpgs[0]) }}</td><td v-else>-</td>
          <td v-if="player && player.stats.pass && player.stats.pass.td">{{ player.stats.pass.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Total</td>
          <td v-if="this.totalBYU && this.totalBYU.gpgs">{{ this.totalBYU.gpgs }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.att">{{ this.totalBYU.pass.att }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.comp">{{ this.totalBYU.pass.comp }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.int">{{ this.totalBYU.pass.int }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.comp && this.totalBYU.pass.att">{{ ((this.totalBYU.pass.comp / this.totalBYU.pass.att) * 100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.yds">{{ this.totalBYU.pass.yds }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.yds && this.totalBYU.gpgs">{{ this.getAvg(this.totalBYU.pass.yds, this.totalBYU.gpgs) }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pass && this.totalBYU.pass.td">{{ this.totalBYU.pass.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Opponents</td>
          <td v-if="this.totalOpp && this.totalOpp.gpgs">{{ this.totalOpp.gpgs }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.att">{{ this.totalOpp.pass.att }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.comp">{{ this.totalOpp.pass.comp }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.int">{{ this.totalOpp.pass.int }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.comp && this.totalOpp.pass.att">{{ ((this.totalOpp.pass.comp / this.totalOpp.pass.att) * 100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.yds">{{ this.totalOpp.pass.yds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.yds && this.totalOpp.gpgs">{{ this.getAvg(this.totalOpp.pass.yds, this.totalOpp.gpgs) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pass && this.totalOpp.pass.td">{{ this.totalOpp.pass.td }}</td><td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan=100%>Receiving</th>
        </tr>
        <tr class="header-label">
          <th colspan="4"></th>
          <th colspan="3">Yards</th>
          <th colspan="1"></th>
        </tr>
        <tr>
          <th>#</th>
          <th>Player</th>
          <th>GP</th>
          <th>No.</th>
          <th>Total</th>
          <th>Avg</th>
          <th>Game Avg</th>
          <th>TD</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(player,index) in orderedRcv" :key="index">
          <td v-if="player && player.number" class="col-emphasize">{{ player.number }}</td><td v-else>-</td>
          <td v-if="player && player.name" class="col-emphasize">{{ player.name }}</td><td v-else>-</td>
          <td v-if="player && player.gpgs[0]">{{ player.gpgs[0] }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rcv && player.stats.rcv.no">{{ player.stats.rcv.no }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rcv && player.stats.rcv.yds">{{ player.stats.rcv.yds }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rcv && player.stats.rcv.yds && player.stats.rcv.no">{{ this.getAvg(player.stats.rcv.yds, player.stats.rcv.no) }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rcv && player.stats.rcv.yds && player.gpgs[0]">{{ this.getAvg(player.stats.rcv.yds, player.gpgs[0]) }}</td><td v-else>-</td>
          <td v-if="player && player.stats.rcv && player.stats.rcv.td">{{ player.stats.rcv.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Total</td>
          <td v-if="this.totalBYU && this.totalBYU.gpgs">{{ this.totalBYU.gpgs }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rcv && this.totalBYU.rcv.no">{{ this.totalBYU.rcv.no }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rcv && this.totalBYU.rcv.yds">{{ this.totalBYU.rcv.yds }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rcv && this.totalBYU.rcv.yds && this.totalBYU.rcv.no">{{ this.getAvg(this.totalBYU.rcv.yds, this.totalBYU.rcv.no) }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rcv && this.totalBYU.rcv.yds && this.totalBYU.gpgs">{{ this.getAvg(this.totalBYU.rcv.yds, this.totalBYU.gpgs) }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.rcv && this.totalBYU.rcv.td">{{ this.totalBYU.rcv.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Opponents</td>
          <td v-if="this.totalOpp && this.totalOpp.gpgs">{{ this.totalOpp.gpgs }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rcv && this.totalOpp.rcv.no">{{ this.totalOpp.rcv.no }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rcv && this.totalOpp.rcv.yds">{{ this.totalOpp.rcv.yds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rcv && this.totalOpp.rcv.yds && this.totalOpp.rcv.no">{{ this.getAvg(this.totalOpp.rcv.yds, this.totalOpp.rcv.no) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rcv && this.totalOpp.rcv.yds && this.totalOpp.gpgs">{{ this.getAvg(this.totalOpp.rcv.yds, this.totalOpp.gpgs) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.rcv && this.totalOpp.rcv.td">{{ this.totalOpp.rcv.td }}</td><td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan=100%>Interceptions</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Player</th>
          <th>No.</th>
          <th>Yds</th>
          <th>TD</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(player,index) in orderedIR" :key="index">
          <td v-if="player && player.number" class="col-emphasize">{{ player.number }}</td><td v-else>-</td>
          <td v-if="player && player.name" class="col-emphasize">{{ player.name }}</td><td v-else>-</td>
          <td v-if="player && player.stats.ir && player.stats.ir.no">{{ player.stats.ir.no }}</td><td v-else>-</td>
          <td v-if="player && player.stats.ir && player.stats.ir.yds">{{ player.stats.ir.yds }}</td><td v-else>-</td>
          <td v-if="player && player.stats.ir && player.stats.ir.td">{{ player.stats.ir.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Total</td>
          <td v-if="this.totalBYU && this.totalBYU.ir && this.totalBYU.ir.no">{{ this.totalBYU.ir.no }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.ir && this.totalBYU.ir.yds">{{ this.totalBYU.ir.yds }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.ir && this.totalBYU.ir.td">{{ this.totalBYU.ir.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Opponents</td>
          <td v-if="this.totalOpp && this.totalOpp.ir && this.totalOpp.ir.no">{{ this.totalOpp.ir.no }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.ir && this.totalOpp.ir.yds">{{ this.totalOpp.ir.yds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.ir && this.totalOpp.ir.td">{{ this.totalOpp.ir.td }}</td><td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan=100%>Punt Returns</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Player</th>
          <th>No.</th>
          <th>Yds</th>
          <th>Avg</th>
          <th>TD</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(player,index) in orderedPR" :key="index">
          <td v-if="player && player.number" class="col-emphasize">{{ player.number }}</td><td v-else>-</td>
          <td v-if="player && player.name" class="col-emphasize">{{ player.name }}</td><td v-else>-</td>
          <td v-if="player && player.stats.pr && player.stats.pr.no">{{ player.stats.pr.no }}</td><td v-else>-</td>
          <td v-if="player && player.stats.pr && player.stats.pr.yds">{{ player.stats.pr.yds }}</td><td v-else>-</td>
          <td v-if="player && player.stats.pr && player.stats.pr.yds && player.stats.pr.no">{{ this.getAvg(player.stats.pr.yds, player.stats.pr.no) }}</td><td v-else>-</td>
          <td v-if="player && player.stats.pr && player.stats.pr.td">{{ player.stats.pr.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Total</td>
          <td v-if="this.totalBYU && this.totalBYU.pr && this.totalBYU.pr.no">{{ this.totalBYU.pr.no }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pr && this.totalBYU.pr.yds">{{ this.totalBYU.pr.yds }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pr && this.totalBYU.pr.yds && this.totalBYU.pr.no">{{ this.getAvg(this.totalBYU.pr.yds, this.totalBYU.pr.no) }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.pr && this.totalBYU.pr.td">{{ this.totalBYU.pr.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Opponents</td>
          <td v-if="this.totalOpp && this.totalOpp.pr && this.totalOpp.pr.no">{{ this.totalOpp.pr.no }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pr && this.totalOpp.pr.yds">{{ this.totalOpp.pr.yds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pr && this.totalOpp.pr.yds && this.totalOpp.pr.no">{{ this.getAvg(this.totalOpp.pr.yds, this.totalOpp.pr.no) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.pr && this.totalOpp.pr.td">{{ this.totalOpp.pr.td }}</td><td v-else>-</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colspan=100%>Kick Returns</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Player</th>
          <th>No.</th>
          <th>Yds</th>
          <th>Avg</th>
          <th>TD</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(player,index) in orderedKR" :key="index">
          <td v-if="player && player.number" class="col-emphasize">{{ player.number }}</td><td v-else>-</td>
          <td v-if="player && player.name" class="col-emphasize">{{ player.name }}</td><td v-else>-</td>
          <td v-if="player && player.stats.kr && player.stats.kr.no">{{ player.stats.kr.no }}</td><td v-else>-</td>
          <td v-if="player && player.stats.kr && player.stats.kr.yds">{{ player.stats.kr.yds }}</td><td v-else>-</td>
          <td v-if="player && player.stats.kr && player.stats.kr.yds && player.stats.kr.no">{{ this.getAvg(player.stats.kr.yds, player.stats.kr.no) }}</td><td v-else>-</td>
          <td v-if="player && player.stats.kr && player.stats.kr.td">{{ player.stats.kr.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Total</td>
          <td v-if="this.totalBYU && this.totalBYU.kr && this.totalBYU.kr.no">{{ this.totalBYU.kr.no }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.kr && this.totalBYU.kr.yds">{{ this.totalBYU.kr.yds }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.kr && this.totalBYU.kr.yds && this.totalBYU.kr.no">{{ this.getAvg(this.totalBYU.kr.yds, this.totalBYU.kr.no) }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.kr && this.totalBYU.kr.td">{{ this.totalBYU.kr.td }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="2">Opponents</td>
          <td v-if="this.totalOpp && this.totalOpp.kr && this.totalOpp.kr.no">{{ this.totalOpp.kr.no }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.kr && this.totalOpp.kr.yds">{{ this.totalOpp.kr.yds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.kr && this.totalOpp.kr.yds && this.totalOpp.kr.no">{{ this.getAvg(this.totalOpp.kr.yds, this.totalOpp.kr.no) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.kr && this.totalOpp.kr.td">{{ this.totalOpp.kr.td }}</td><td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan=100%>Defense</th>
        </tr>
        <tr class="header-label">
          <th colspan="3"></th>
          <th colspan="4">Tackles</th>
          <th colspan="1">Sacks</th>
          <th colspan="2">Fumbles</th>
          <th colspan="3">Pass Defense</th>
        </tr>
        <tr>
          <th>#</th>
          <th>Player</th>
          <th>GP</th>
          <th>UA</th>
          <th>A</th>
          <th>Total</th>
          <th>TFL/Yds</th>
          <th>No./Yds</th>
          <th>Forced</th>
          <th>Recovered</th>
          <th>Int/Yds</th>
          <th>BrUp</th>
          <th>QBH</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(player,index) in orderedDefense" :key="index">
          <td v-if="player && player.number" class="col-emphasize">{{ player.number }}</td><td v-else>-</td>
          <td v-if="player && player.name" class="col-emphasize">{{ player.name }}</td><td v-else>-</td>
          <td v-if="player && player.gpgs[0]">{{ player.gpgs[0] }}</td><td v-else>-</td>
          <td v-if="player && player.stats.defense && player.stats.defense.tackua">{{ player.stats.defense.tackua }}</td><td v-else>-</td>
          <td v-if="player && player.stats.defense && player.stats.defense.tacka">{{ player.stats.defense.tacka }}</td><td v-else>-</td>
          <td v-if="player && player.stats.defense && player.stats.defense.tot_tack">{{ player.stats.defense.tot_tack }}</td><td v-else>-</td>
          <td v-if="player && player.stats.defense && (player.stats.defense.tfla || player.stats.defense.tflua)">{{ player.stats.defense.tfla + player.stats.defense.tflua }} / {{ player.stats.defense.tflyds }}</td><td v-else>-</td>
          <td v-if="player && player.stats.defense && player.stats.defense.sacks">{{ player.stats.defense.sacks }} / {{ player.stats.defense.sackyds }}</td><td v-else>-</td>
          <td v-if="player && player.stats.defense && player.stats.defense.ff">{{ player.stats.defense.ff }}</td><td v-else>-</td>
          <td v-if="player && player.stats.defense && player.stats.defense.fr">{{ player.stats.defense.fr }}</td><td v-else>-</td>
          <td v-if="player && player.stats.defense && player.stats.defense.int">{{ player.stats.defense.int }} / {{ player.stats.defense.intyds }}</td><td v-else>-</td>
          <td v-if="player && player.stats.defense && player.stats.defense.brup">{{ player.stats.defense.brup }}</td><td v-else>-</td>
          <td v-if="player && player.stats.defense && player.stats.defense.qbh">{{ player.stats.defense.qbh }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="3">Total</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.tackua">{{ this.totalBYU.defense.tackua }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.tacka">{{ this.totalBYU.defense.tacka }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.tot_tack">{{ this.totalBYU.defense.tot_tack }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && (this.totalBYU.defense.tfla || this.totalBYU.defense.tflua)">{{ this.totalBYU.defense.tfla + this.totalBYU.defense.tflua }} / {{ this.totalBYU.defense.tflyds }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.sacks">{{ this.totalBYU.defense.sacks }} / {{ this.totalBYU.defense.sackyds }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.ff">{{ this.totalBYU.defense.ff }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.fr">{{ this.totalBYU.defense.fr }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.int">{{ this.totalBYU.defense.int }} / {{ this.totalBYU.defense.intyds }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.brup">{{ this.totalBYU.defense.brup }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.defense && this.totalBYU.defense.qbh">{{ this.totalBYU.defense.qbh }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="3">Opponents</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.tackua">{{ this.totalOpp.defense.tackua }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.tacka">{{ this.totalOpp.defense.tacka }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.tot_tack">{{ this.totalOpp.defense.tot_tack }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && (this.totalOpp.defense.tfla || this.totalOpp.defense.tflua)">{{ this.totalOpp.defense.tfla + this.totalOpp.defense.tflua }} / {{ this.totalOpp.defense.tflyds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.sacks">{{ this.totalOpp.defense.sacks }} / {{ this.totalOpp.defense.sackyds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.ff">{{ this.totalOpp.defense.ff }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.fr">{{ this.totalOpp.defense.fr }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.int">{{ this.totalOpp.defense.int }} / {{ this.totalOpp.defense.intyds }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.brup">{{ this.totalOpp.defense.brup }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.defense && this.totalOpp.defense.qbh">{{ this.totalOpp.defense.qbh }}</td><td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  props: ['selected', 'seasonData'],
  data () {
    return {
      totalBYU: null,
      totalOpp: null,
      totalPlayers: null,
      totalGames: null,
      record: null,
      playerPos: null,
      showData: false
    }
  },
  mounted () {
    // console.log(this.seasonData)
    this.record = this.getRecord(this.fixData(this.seasonData))
    this.totalBYU = this.setTeamTotal(this.getBYUData, this.getOppData)
    this.totalOpp = this.setTeamTotal(this.getOppData, this.getBYUData)
    // console.log(this.totalBYU)
    // console.log(this.totalOpp)
    this.totalGames = this.setGameData()
    this.totalPlayers = this.setPlayerTotal()
    // console.log(this.totalPlayers)
    this.playerPos = this.filterPlayers(this.totalPlayers, ['pass', 'rcv', 'rush', 'ir', 'pr', 'kr', 'defense'])
    // console.log(this.playerPos)
    this.showData = true
  },
  computed: {
    orderedDefense: function () {
      return _.orderBy(this.playerPos.defense, 'number')
    },
    orderedKR: function () {
      return _.orderBy(this.playerPos.kr, 'number')
    },
    orderedPR: function () {
      return _.orderBy(this.playerPos.pr, 'number')
    },
    orderedIR: function () {
      return _.orderBy(this.playerPos.ir, 'number')
    },
    orderedRush: function () {
      return _.orderBy(this.playerPos.rush, 'number')
    },
    orderedRcv: function () {
      return _.orderBy(this.playerPos.rcv, 'number')
    },
    orderedPass: function () {
      return _.orderBy(this.playerPos.pass, 'number')
    }
  },
  methods: {
    setTeamTotal (getHomeData, getOppData) {
      const data = getHomeData(this.fixData(this.seasonData))
      //   const oppData = getOppData(this.fixData(this.seasonData))
      //   console.log('data and oppdata below')
      //   console.log(data)
      //   console.log(oppData)
      const totals = {
        gpgs: data.length,
        conversions: {},
        defense: {},
        fg: {},
        firstdowns: {},
        ir: {},
        ko: {},
        pass: {},
        pat: {},
        penalties: {},
        pr: {},
        punt: {},
        rcv: {},
        redzone: {},
        rush: {},
        scoring: {},
        kr: {},
        fumbles: {},
        fr: {}
      }
      for (let x = 0; x < data.length; x++) {
        for (var item in data[x]) { // iterates through each key in each player's game data
          if (typeof data[x][item] === 'object' && totals[item]) { // if the key is an object (indicating that its a stat category), it processes that stat category object
            for (var key in data[x][item]) {
              if (!totals[item][key]) { // checks if each stat key exists before running sum function on it
                totals[item][key] = this.getSumStat(data, item, key)
              }
            }
          } else if (typeof data[x][item] === 'object') {
            // console.log('item missing: ' + item)
          }
        }
      }
      return totals
    },
    fixData (data) {
      var fixedData = []
      for (let i = 0; i < data.length; i++) {
        // hopefully handles the issue where game arrays have an extra empty array before the actual info
        if (data[i][0]) {
          data[i] = data[i][0]
        }
        // removes all games that don't include fbgame objects
        if (data[i].fbgame) {
          fixedData.push(data[i])
        } else {
          // console.log('found an object without fbgame')
        }
      }
      return fixedData
    },
    setGameData () {
      const data = this.fixData(this.seasonData)
      var games = []
      for (let i = 0; i < data.length; i++) {
        var BYUGameData = this.getBYUGameData(data[i])
        games.push({
          nid: data[i].nid,
          match: this.getOpponent(data[i]),
          date: data[i].fbgame.venue.date,
          dateFull: new Date(data[i].event_date.split('T')[0]),
          score: this.getScore(data[i]),
          record: BYUGameData.record,
          confRecord: BYUGameData['conf-record'],
          stats: BYUGameData.totals,
          time: data[i].fbgame.venue.duration,
          attendance: data[i].fbgame.venue.attend
        }
        )
      }
      // sorts games by date
      const sortedGames = games.sort((a, b) => a.dateFull - b.dateFull)
      // console.log('games after sort')
      // console.log(sortedGames)
      return sortedGames
    },
    getBYUGameData (data) {
      var statsBYU = null
      if (data.fbgame.team[0].id === 'BYU' || data.fbgame.team[0].id === 'BY' || data.fbgame.team[0].name === 'Brigham Young University') {
        statsBYU = data.fbgame.team[0]
      } else if (data.fbgame.team[1].id === 'BYU' || data.fbgame.team[1].id === 'BY' || data.fbgame.team[1].name === 'Brigham Young University') {
        statsBYU = data.fbgame.team[1]
      }
      return statsBYU
    },
    getOpponent (data) {
      var oppName = ''
      if ((data.fbgame.venue.homeid !== 'BYU' && data.fbgame.venue.homeid !== 'BY' && data.fbgame.venue.homename !== 'Brigham Young University') && data.fbgame.venue.neutralgame !== 'Y') {
        // console.log(data.fbgame.venue.homeid)
        // console.log(data.fbgame.venue.neutralgame)
        oppName = oppName + '@ '
      }
      oppName = oppName + data.title
      return oppName
    },
    getScore (data) {
      var win = false
      var score = new Array(2)
      if ((data.fbgame.team[0].id === 'BYU') || (data.fbgame.team[0].id === 'BY') || (data.fbgame.team[0].name === 'Brigham Young University')) {
        score[0] = parseInt(data.fbgame.team[0].linescore.score)
        score[1] = parseInt(data.fbgame.team[1].linescore.score)
      } else if ((data.fbgame.team[1].id === 'BYU') || (data.fbgame.team[1].id === 'BY') || (data.fbgame.team[1].name === 'Brigham Young University')) {
        score[0] = parseInt(data.fbgame.team[1].linescore.score)
        score[1] = parseInt(data.fbgame.team[0].linescore.score)
      } else {
        // console.log('error while getting score in game below')
        // console.log(data)
        return null
      }
      if (score[0] > score[1]) {
        win = true
      }
      score = score[0] + '-' + score[1]
      return [score, win]
    },
    getAllPlayerData (data) {
      var AllPlayerData = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].fbgame.team[0].id === 'BYU' || data[i].fbgame.team[0].id === 'BY' || data[i].fbgame.team[0].name === 'Brigham Young University') {
          AllPlayerData[i] = (data[i].fbgame.team[0].player)
        } else {
          AllPlayerData[i] = (data[i].fbgame.team[1].player)
        }
      }
      // console.log('AllPlayerData: ')
      // console.log(AllPlayerData)
      return AllPlayerData
    },
    setPlayerTotal () {
      const data = this.getAllPlayerData(this.fixData(this.seasonData))
      // console.log('got data in setPlayerTotal function, listed below')
      // console.log(data)
      var playerTotals = {
      }
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          var nid = data[i][j].player_nid
          if (!playerTotals[nid] && nid !== undefined) {
            var playerData = this.getPlayerData(data, nid)
            // console.log('data for player with nid ' + nid)
            // console.log(playerData)
            playerTotals[nid] = {
              name: playerData[0].name,
              number: parseInt(playerData[0].uni),
              positions: this.getPos(playerData),
              gpgs: [this.getGP(playerData), this.getGS(playerData)],
              stats: {
                // empty objects to be populated in below for loop. If no stats are found for player, object will be deleted later
                defense: {},
                pass: {},
                rush: {},
                fumbles: {},
                rcv: {},
                scoring: {},
                punt: {},
                pat: {},
                fg: {},
                ko: {},
                kr: {},
                ir: {},
                pr: {},
                fr: {}
              }
            }
            for (let x = 0; x < playerData.length; x++) {
              for (var item in playerData[x]) { // iterates through each key in each player's game data
                if (typeof playerData[x][item] === 'object' && playerTotals[nid].stats[item]) { // if the key is an object (indicating that its a stat category), it processes that stat category object
                  for (var key in playerData[x][item]) {
                    if (!playerTotals[nid].stats[item][key]) { // checks if each stat key exists before running sum function on it
                      playerTotals[nid].stats[item][key] = this.getSumStat(playerData, item, key)
                    }
                  }
                } else if (typeof playerData[x][item] === 'object') {
                  // console.log('item missing: ' + item)
                }
              }
            }
          }
        }
      }
      // clears empty stat objects out of each player's stats
      for (var player in playerTotals) {
        for (var statType in playerTotals[player].stats) {
          if (Object.keys(playerTotals[player].stats[statType]).length === 0) {
            delete playerTotals[player].stats[statType]
          } else {
            let statTotal = 0
            for (var stat in playerTotals[player].stats[statType]) {
              statTotal += parseInt(playerTotals[player].stats[statType][stat])
            }
            if (statTotal === 0) {
              delete playerTotals[player].stats[statType]
            }
          }
        }
      }
      // console.log(playerTotals)
      return playerTotals
    },
    getPlayerData (data, nid) {
      var playerData = []
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          if (data[i][j].player_nid === nid) {
            playerData.push(data[i][j])
          }
        }
      }
      // console.log(playerData)
      return playerData
    },
    filterPlayers (data, categories) {
      var players = {}
      for (let i = 0; i < categories.length; i++) {
        players[categories[i]] = {}
        for (var player in data) {
          if (data[player].stats && data[player].stats[categories[i]]) {
            players[categories[i]][player] = data[player]
          }
        }
      }
      return players
    },
    getBYUData (data) {
      var dataBYU = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].fbgame.team[0].id === 'BYU' || data[i].fbgame.team[0].id === 'BY' || data[i].fbgame.team[0].name === 'Brigham Young University') {
          // console.log('the first team was byu')
          dataBYU[i] = (data[i].fbgame.team[0].totals)
        } else {
          // console.log('the second team was byu')
          dataBYU[i] = (data[i].fbgame.team[1].totals)
        }
      }
      // console.log(dataBYU)
      return dataBYU
    },
    getOppData (data) {
      var dataOpp = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].fbgame.team[0].id !== 'BYU' && data[i].fbgame.team[0].id !== 'BY' && data[i].fbgame.team[0].name !== 'Brigham Young University') {
          // console.log('the first team was the opponent')
          dataOpp[i] = (data[i].fbgame.team[0].totals)
        } else {
          // console.log('the second team was the opponent')
          dataOpp[i] = (data[i].fbgame.team[1].totals)
        }
      }
      // console.log(dataOpp)
      return dataOpp
    },
    getSumStat (data, categoryKey, statsKey) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i][categoryKey] && data[i][categoryKey][statsKey]) {
          total += parseInt(data[i][categoryKey][statsKey])
        } else {
          // console.log(statsKey + ' not found in ' + categoryKey)
        }
      }
      return total
    },
    getPct (num, denom) {
      if (denom === 0) return null
      const total = (num / denom).toFixed(3)
      return total
    },
    getAvg (num, denom) {
      if (denom === 0) return null
      const total = (num / denom).toFixed(1)
      return total
    },
    getGS (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gs) {
          total += parseInt(data[i].gs)
        }
      }
      return total
    },
    getGP (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gp) {
          total += parseInt(data[i].gp)
        }
      }
      return total
    },
    getPos (data) {
      // add methodology to check stats for positions that are unidentified (kicker/punter/kr/pr/oline etc)
      var oPositions = []
      var dPositions = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].opos && oPositions.indexOf(data[i].opos) === -1 && data[i].opos !== '') {
          oPositions.push(data[i].opos)
        }
        if (data[i].dpos && dPositions.indexOf(data[i].dpos) === -1 && data[i].dpos !== '') {
          dPositions.push(data[i].dpos)
        }
      }
      return { offense: oPositions, defense: dPositions }
    },
    getRecord (data) {
      // wins, losses, home wins, home losses, away wins, away losses, neutral wins, neutral losses, wcc wins, wcc losses
      var record = {
        overall: {
          wins: 0,
          losses: 0
        },
        home: {
          wins: 0,
          losses: 0
        },
        away: {
          wins: 0,
          losses: 0
        },
        neutral: {
          wins: 0,
          losses: 0
        },
        wcc: {
          wins: 0,
          losses: 0
        }
      }
      var byuScore = 0
      var oppScore = 0
      for (let i = 0; i < data.length; i++) {
        if ((data[i].fbgame.team[0].id === 'BYU') || (data[i].fbgame.team[0].id === 'BY') || (data[i].fbgame.team[0].name === 'Brigham Young University')) {
          byuScore = parseInt(data[i].fbgame.team[0].linescore.score)
          oppScore = parseInt(data[i].fbgame.team[1].linescore.score)
        } else {
          byuScore = parseInt(data[i].fbgame.team[1].linescore.score)
          oppScore = parseInt(data[i].fbgame.team[0].linescore.score)
        }
        // checks overall winner
        if (byuScore > oppScore) {
          record.overall.wins++
          // checks location
          if ((data[i].fbgame.venue.homeid === 'BYU' || data[i].fbgame.venue.homeid === 'BY' || data[i].fbgame.venue.homename === 'Brigham Young University') && (!data[i].fbgame.venue.neutralgame || data[i].fbgame.venue.neutralgame === 'N')) {
            record.home.wins++
          } else if ((data[i].fbgame.venue.visid === 'BYU' || data[i].fbgame.venue.visid === 'BY' || data[i].fbgame.venue.visname === 'Brigham Young University') && (!data[i].fbgame.venue.neutralgame || data[i].fbgame.venue.neutralgame === 'N')) {
            record.away.wins++
          } else if (data[i].fbgame.venue.neutralgame === 'Y') {
            record.neutral.wins++
          }
          if (data[i].fbgame.venue.leaguegame === 'Y') {
            record.wcc.wins++
          }
        } else if (oppScore > byuScore) {
          record.overall.losses++
          // checks location
          if ((data[i].fbgame.venue.homeid === 'BYU' || data[i].fbgame.venue.homeid === 'BY' || data[i].fbgame.venue.homename === 'Brigham Young University') && (!data[i].fbgame.venue.neutralgame || data[i].fbgame.venue.neutralgame === 'N')) {
            record.home.losses++
          } else if ((data[i].fbgame.venue.visid === 'BYU' || data[i].fbgame.venue.visid === 'BY' || data[i].fbgame.venue.visname === 'Brigham Young University') && (!data[i].fbgame.venue.neutralgame || data[i].fbgame.venue.neutralgame === 'N')) {
            record.away.losses++
          } else if (data[i].fbgame.venue.neutralgame === 'Y') {
            record.neutral.losses++
          }
          if (data[i].fbgame.venue.leaguegame === 'Y') {
            record.wcc.losses++
          }
        }
      }
      return record
    }
  }
}
</script>
