<template>
<div class="top-links-vue">
    <ul role="navigation" class="nav justify-content-end ">
      <li class="nav-item-vue">
        <a class="nav-link prmy-color" href="https://byucougars.com/">BYU COUGARS</a>
      </li>
      <li class="nav-item-vue">
        <a class="nav-link prmy-color" href="https://tickets.byu.edu/">TICKETS</a>
      </li>
      <li class="nav-item-vue">
        <a class="nav-link prmy-color" href="https://cougarclub.com">CLUB</a>
      </li>
      <li class="nav-item-vue" >
        <a class="nav-link prmy-color" href="https://records.byucougars.com/">FOOTBALL RECORDS</a>
      </li>
    </ul>
  </div>
<header>
  <div id="nav-container-vue">
    <div id="nav-vue">
      <img class="y-logo" alt="BYU logo" src="https://byucougars.com/themes/Cougar/byu.png">
      <div style="color: white;" class="stat-text">STATS</div>
    </div>
  </div>
</header>
<div class="router-view">
  <Statistics/>
</div>
</template>

<script>
import Statistics from '@/views/Statistics.vue'
export default {
  components: {
    Statistics
  }
}
</script>
<style>

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* #003DA5 */
/* #0b5ed7 */
/* #0d6efd */

#nav-vue {
  display: flex;
  align-items: center !important;
  justify-content: center;
  background-color: #002E5D;
  width: 100%;
  z-index: 11;
  font-family: 'Bebas Neue', cursive;
  font-size: 2em;
  color: #fff;
  padding: 10px 20px 5px 20px;
  text-align: center;
  border-bottom: 3px solid transparent;
  display: flex;
  background-color: #002E5D;
  border-bottom-color: white;
}

#nav-vue a{
  color: inherit;
  text-decoration: none;
}

.router-view {
  margin-top: 60px;
}

.prmy-color {
  color: #002E5D !important;
}

.y-logo {
  width: 105px;
  height: auto;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.nav-item-vue {
  font-family: 'Bebas Neue', cursive;
  font-size: 1.2em;
}

#nav-container-vue {
  max-width: 100vw;
}

.stat-text {
  border-left: 2px solid white;
  height: 50%;
  padding-left: 1rem;
  font-size: 1em;
}

@media only screen and (max-width: 800px) {
  .top-links-vue {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 500px) {
  .nav-item-vue {
    font-size: 1.1em;
  }

  .y-logo {
    width: 80px;
  }

  .stat-text {
    font-size: 0.8em;
  }
}

</style>
