<template>
  <div>
      <div class="table-responsive mt-3" v-if="showData">
          <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
              <thead>
                  <tr>
                      <th colspan="100%">Season Record</th>
                  </tr>
                  <tr>
                      <th>Record</th>
                      <th>Overall</th>
                      <th>Home</th>
                      <th>Away</th>
                      <th>Neutral</th>
                      <th>Conference</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td class="col-emphasize">ALL GAMES</td>
                  <td>{{ this.record.overall.wins }} - {{ this.record.overall.losses }}</td>
                  <td>{{ this.record.home.wins }} - {{ this.record.home.losses }}</td>
                  <td>{{ this.record.away.wins }} - {{ this.record.away.losses }}</td>
                  <td>{{ this.record.neutral.wins }} - {{ this.record.neutral.losses }}</td>
                  <td>{{ this.record.wcc.wins }} - {{ this.record.wcc.losses }}</td>
                  </tr>
              </tbody>
          </table>
      </div>
  <div class="table-responsive mt-3" v-if="showData">
      <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
          <thead>
              <tr>
                  <th colspan="100%">Team Box Score</th>
              </tr>
              <tr class="header-label">
                <th colspan="4"></th>
                <th colspan="5">Attack</th>
                <th colspan="4">Set</th>
                <th colspan="5">Serve</th>
                <th colspan="3">Receptions</th>
                <th colspan="2">Dig</th>
                <th colspan="5">Blocking</th>
                <th colspan="1"></th>
                <th colspan="2">Points</th>
              </tr>

              <tr>
                  <th>No.</th>
                  <th>Player</th>
                  <th>SP</th>
                  <th>MP/MS</th>
                  <th>K</th>
                  <th>K/S</th>
                  <th>E</th>
                  <th>TA</th>
                  <th>PCT</th>
                  <th>A</th>
                  <th>A/S</th>
                  <th>TA</th>
                  <th>PTC</th>
                  <th>SA</th>
                  <th>SA/S</th>
                  <th>SE</th>
                  <th>TA</th>
                  <th>PTC</th>
                  <th>RE</th>
                  <th>TA</th>
                  <th>PTC</th>
                  <th>DIG</th>
                  <th>DIG/S</th>
                  <th>BS</th>
                  <th>BA</th>
                  <th>TOTAL</th>
                  <th>BLK/S</th>
                  <th>BE</th>
                  <th>BHE</th>
                  <th>PTS</th>
                  <th>PTS/S</th>
              </tr>
          </thead>
          <tbody>
            <tr v-for="(totals,index) in orderedPlayers" :key="index">
                  <td v-if="totals && totals.number" class="col-emphasize">{{ totals.number }}</td><td v-else>-</td>
                  <td v-if="totals && totals.name" class="col-emphasize">{{ totals.name }}</td><td v-else>-</td>
                  <td v-if="totals && totals.gp">{{ totals.gp[0] }}</td><td v-else>-</td>
                  <td v-if="totals && totals.mpms">{{ totals.mpms[0]}} - {{ totals.mpms[1]}}</td><td v-else>-</td>
                  <td v-if="totals && totals.k">{{ totals.k }}</td><td v-else>-</td>
                  <td v-if="totals && totals.ks">{{ totals.ks }}</td><td v-else>-</td>
                  <td v-if="totals && totals.ke">{{ totals.ke }}</td><td v-else>-</td>
                  <td v-if="totals && totals.kta">{{ totals.kta }}</td><td v-else>-</td>
                  <td v-if="totals && totals.kPct">{{ totals.kPct }}</td><td v-else>-</td>
                  <td v-if="totals && totals.a">{{ totals.a }}</td><td v-else>-</td>
                  <td v-if="totals && totals.as">{{ totals.as }}</td><td v-else>-</td>
                  <td v-if="totals && totals.ata">{{ totals.ata }}</td><td v-else>-</td>
                  <td v-if="totals && totals.aPct">{{ totals.aPct }}</td><td v-else>-</td>
                  <td v-if="totals && totals.sa">{{ totals.sa }}</td><td v-else>-</td>
                  <td v-if="totals && totals.ss">{{ totals.ss }}</td><td v-else>-</td>
                  <td v-if="totals && totals.se">{{ totals.se }}</td><td v-else>-</td>
                  <td v-if="totals && totals.sta">{{ totals.sta }}</td><td v-else>-</td>
                  <td v-if="totals && totals.sPct">{{ totals.sPct }}</td><td v-else>-</td>
                  <td v-if="totals && totals.re">{{ totals.re }}</td><td v-else>-</td>
                  <td v-if="totals && totals.rta">{{ totals.rta }}</td><td v-else>-</td>
                  <td v-if="totals && totals.rPct">{{ totals.rPct }}</td><td v-else>-</td>
                  <td v-if="totals && totals.dig">{{ totals.dig }}</td><td v-else>-</td>
                  <td v-if="totals && totals.digs">{{ totals.digs }}</td><td v-else>-</td>
                  <td v-if="totals && totals.bs">{{ totals.bs }}</td><td v-else>-</td>
                  <td v-if="totals && totals.ba">{{ totals.ba }}</td><td v-else>-</td>
                  <td v-if="totals && totals.total">{{ totals.total }}</td><td v-else>-</td>
                  <td v-if="totals && totals.blks">{{ totals.blks }}</td><td v-else>-</td>
                  <td v-if="totals && totals.be">{{ totals.be }}</td><td v-else>-</td>
                  <td v-if="totals && totals.bhe">{{ totals.bhe }}</td><td v-else>-</td>
                  <td v-if="totals && totals.pts">{{ totals.pts }}</td><td v-else>-</td>
                  <td v-if="totals && totals.ptsAvg">{{ totals.ptsAvg }}</td><td v-else>-</td>
              </tr>
              <tr class="row-emphasize">
                  <td colspan="2">Totals</td>
                  <td v-if="this.totalBYU && this.totalBYU.gp">{{ this.totalBYU.gp }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.mpms">{{ this.totalBYU.mpms }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.k">{{ this.totalBYU.k }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.ks">{{ this.totalBYU.ks }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.ke">{{ this.totalBYU.ke }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.kta">{{ this.totalBYU.kta }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.kPct">{{ this.totalBYU.kPct }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.a">{{ this.totalBYU.a }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.as">{{ this.totalBYU.as }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.ata">{{ this.totalBYU.ata }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.aPct">{{ this.totalBYU.aPct }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.sa">{{ this.totalBYU.sa }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.ss">{{ this.totalBYU.ss }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.se">{{ this.totalBYU.se }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.sta">{{ this.totalBYU.sta }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.sPct">{{ this.totalBYU.sPct }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.re">{{ this.totalBYU.re }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.rta">{{ this.totalBYU.rta}}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.rPct">{{ this.totalBYU.rPct }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.dig">{{ this.totalBYU.dig }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.digs">{{ this.totalBYU.digs }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.bs">{{ this.totalBYU.bs }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.ba">{{ this.totalBYU.ba }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.total">{{ this.totalBYU.total }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.blks">{{ this.totalBYU.blks }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.be">{{ this.totalBYU.be }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.bhe">{{ this.totalBYU.bhe }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.pts">{{ this.totalBYU.pts }}</td><td v-else>-</td>
                  <td v-if="this.totalBYU && this.totalBYU.ptsAvg">{{ this.totalBYU.ptsAvg }}</td><td v-else>-</td>
              </tr>
              <tr class="row-emphasize">
                  <td colspan="2">Opponents</td>
                  <td v-if="this.totalOpp && this.totalOpp.gp">{{ this.totalOpp.gp }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.mpms">{{ this.totalOpp.mpms }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.k">{{ this.totalOpp.k }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.ks">{{ this.totalOpp.ks }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.ke">{{ this.totalOpp.ke }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.kta">{{ this.totalOpp.kta }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.kPct">{{ this.totalOpp.kPct }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.a">{{ this.totalOpp.a }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.as">{{ this.totalOpp.as }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.ata">{{ this.totalOpp.ata }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.aPct">{{ this.totalOpp.aPct }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.sa">{{ this.totalOpp.sa }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.ss">{{ this.totalOpp.ss }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.se">{{ this.totalOpp.se }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.sta">{{ this.totalOpp.sta }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.sPct">{{ this.totalOpp.sPct }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.re">{{ this.totalOpp.re }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.rta">{{ this.totalOpp.rta}}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.rPct">{{ this.totalOpp.rPct }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.dig">{{ this.totalOpp.dig }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.digs">{{ this.totalOpp.digs }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.bs">{{ this.totalOpp.bs }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.ba">{{ this.totalOpp.ba }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.total">{{ this.totalOpp.total }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.blks">{{ this.totalOpp.blks }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.be">{{ this.totalOpp.be }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.bhe">{{ this.totalOpp.bhe }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.pts">{{ this.totalOpp.pts }}</td><td v-else>-</td>
                  <td v-if="this.totalOpp && this.totalOpp.ptsAvg">{{ this.totalOpp.ptsAvg }}</td><td v-else>-</td>
              </tr>
          </tbody>
      </table>
  </div>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
      <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
          <thead>
              <tr class="table-label">
                  <th colspan="100%">Game Statistics</th>
              </tr>
              <tr class="header-label">
                  <th colspan="4"></th>
                  <th colspan="4">Attack</th>
                  <th colspan="3">Set</th>
                  <th colspan="4">Serve</th>
                  <th colspan="3">Recept</th>
                  <th colspan="1"></th>
                  <th colspan="4">Blocking</th>
                  <th colspan="100%"></th>
              </tr>
              <tr>
                  <th>Opponent</th>
                  <th>Date</th>
                  <th>Result</th>
                  <th>SP</th>
                  <th>K</th>
                  <th>E</th>
                  <th>TA</th>
                  <th>PCT</th>
                  <th>A</th>
                  <th>TA</th>
                  <th>PCT</th>
                  <th>SA</th>
                  <th>SE</th>
                  <th>TA</th>
                  <th>PCT</th>
                  <th>RE</th>
                  <th>TA</th>
                  <th>PCT</th>
                  <th>DIG</th>
                  <th>BS</th>
                  <th>BA</th>
                  <th>BE</th>
                  <th>TOTAL</th>
                  <th>BHE</th>
                  <th>POINTS</th>
                  <th>SCORE</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="(totals,index) in totalGames" :key="index">
                  <td v-if="totals && totals.match" class="col-emphasize">{{ totals.match }}</td><td v-else>-</td>
                  <td v-if="totals && totals.date">{{ totals.date }}</td><td v-else>-</td>
                  <td v-if="totals && totals.score && totals.score[1]" style="color: green">W</td>
                  <td v-if="totals && totals.score && !totals.score[1]" style="color: red">L</td>
                  <td v-if="totals && totals.score && totals.score[0]">{{ totals.score[0] }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.attack">{{ totals.stats.attack.k }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.attack">{{ totals.stats.attack.e }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.attack">{{ totals.stats.attack.ta }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.attack">{{ totals.stats.attack.pct }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.set.a }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.set.ta }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ this.getPct(totals.stats.set.a, totals.stats.set.ta) }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.serve.sa }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.serve.se }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.serve.ta }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ this.getPct((totals.stats.serve.ta - totals.stats.serve.se), totals.stats.serve.ta) }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.defense.re }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.defense.ta }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ this.getPct((totals.stats.defense.ta - totals.stats.defense.re), totals.stats.defense.ta) }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.defense.dig }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.block.bs }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.block.ba }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.block.be }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.score && totals.stats.set">{{ this.getTotal(totals.stats.block.ba, totals.score.length, totals.stats.block.bs) }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.misc.bhe }}</td><td v-else>-</td>
                  <td v-if="totals && totals.stats && totals.stats.set">{{ totals.stats.misc.pts }}</td><td v-else>-</td>
                  <td v-if="totals && totals.score && totals.score[0]">{{ totals.score[0] }}</td><td v-else>-</td>
              </tr>
          </tbody>
      </table>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  props: ['selected', 'gameYears', 'seasonData'],
  data () {
    return {
      totalBYU: null,
      totalOpp: null,
      totalPlayers: null,
      totalGames: null,
      record: null,
      showData: false
    }
  },
  mounted () {
    console.log('seasondata: ', this.seasonData)
    this.totalBYU = this.setTeamTotal(this.getBYUData, this.getOppData)
    this.totalOpp = this.setTeamTotal(this.getOppData, this.getBYUData)
    // console.log(this.totalBYU)
    // console.log(this.totalOpp)
    this.totalPlayers = this.setPlayerTotal()
    this.totalGames = this.setGameData()
    this.record = this.getRecord(this.seasonData)
    // console.log(this.record)
    // console.log(this.totalPlayers)
    // this.byuTotals = this.setBYUData(this.getBYUData(this.seasonData))
    console.log('setting this.showData to true')
    this.showData = true
  },
  computed: {
    orderedPlayers: function () {
      return _.orderBy(this.totalPlayers, 'number')
    }
  },
  methods: {
    setTeamTotal (getHomeData, getOppData) {
      const data = getHomeData(this.seasonData)
      //   const oppData = getOppData(this.seasonData)
      // console.log('data and oppdata below')
      // console.log(data)
      //   console.log(oppData)
      const totals = {
        gp: this.getGamesPlayed(this.seasonData), // needs to access the season data, then loop through  vbgame > status > parseInt Game key to gamesPlayed total
        mpms: (data.length, data.length),
        k: this.getSumStat(data, 'attack', 'k'),
        ke: this.getSumStat(data, 'attack', 'e'),
        kta: this.getSumStat(data, 'attack', 'ta'),
        ks: this.getAvg(this.getSumStat(data, 'attack', 'k'), this.getGamesPlayed(this.seasonData)),
        kPct: this.getPct(((this.getSumStat(data, 'attack', 'k')) - (this.getSumStat(data, 'attack', 'e'))), this.getSumStat(data, 'attack', 'ta')),
        a: this.getSumStat(data, 'set', 'a'),
        ae: this.getSumStat(data, 'set', 'e'),
        ata: this.getSumStat(data, 'set', 'ta'),
        as: this.getAvg(this.getSumStat(data, 'set', 'a'), this.getGamesPlayed(this.seasonData)),
        aPct: this.getPct(this.getSumStat(data, 'set', 'a'), this.getSumStat(data, 'set', 'ta')),
        sa: this.getSumStat(data, 'serve', 'sa'),
        ss: this.getAvg(this.getSumStat(data, 'serve', 'sa'), this.getGamesPlayed(this.seasonData)),
        se: this.getSumStat(data, 'serve', 'se'),
        sta: this.getSumStat(data, 'serve', 'ta'),
        sPct: this.getPct(((this.getSumStat(data, 'serve', 'ta')) - (this.getSumStat(data, 'serve', 'se'))), this.getSumStat(data, 'serve', 'ta')),
        re: this.getSumStat(data, 'defense', 're'),
        rta: this.getSumStat(data, 'defense', 'ta'),
        rPct: this.getPct(((this.getSumStat(data, 'defense', 'ta')) - (this.getSumStat(data, 'defense', 're'))), this.getSumStat(data, 'defense', 'ta')),
        dig: this.getSumStat(data, 'defense', 'dig'),
        digs: this.getAvg(this.getSumStat(data, 'defense', 'dig'), this.getGamesPlayed(this.seasonData)),
        ba: this.getSumStat(data, 'block', 'ba'),
        bs: this.getSumStat(data, 'block', 'bs'),
        total: ((this.getSumStat(data, 'block', 'bs')) + (this.getSumStat(data, 'block', 'ba'))),
        blks: this.getAvg(((this.getSumStat(data, 'block', 'bs')) + (this.getSumStat(data, 'block', 'ba'))), this.getGamesPlayed(this.seasonData)),
        be: this.getSumStat(data, 'block', 'be'),
        bhe: this.getSumStat(data, 'misc', 'bhe'),
        pts: this.getSumStat(data, 'misc', 'pts').toFixed(1),
        ptsAvg: this.getAvg(this.getSumStat(data, 'misc', 'pts'), this.getGamesPlayed(this.seasonData))
      }
      return totals
    },
    getBYUData (data) {
      var BYUGames = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].vbgame.team[0].id === 'BYU') {
          BYUGames.push(data[i].vbgame.team[0].totals)
        } else if (data[i].vbgame.team[1].id === 'BYU') {
          BYUGames.push(data[i].vbgame.team[1].totals)
        }
      }
      return BYUGames
    },
    getGamesPlayed (seasonData) {
      var gamesPlayed = 0
      for (let i = 0; i < seasonData.length; i++) {
        gamesPlayed += parseInt(seasonData[i].vbgame.status.game)
      }
      return gamesPlayed
    },
    setBYUData (data) {
      const totals = {
        gp: data.length,
        k: this.getSumStat(data, 'attack', 'k'),
        e: this.getSumStat(data, 'attack', 'e'),
        ta: this.getSumStat(data, 'attack', 'ta'),
        bs: this.getSumStat(data, 'block', 'bs'),
        ba: this.getSumStat(data, 'block', 'ba'),
        be: this.getSumStat(data, 'block', 'be'),
        tb: this.getSumStat(data, 'block', 'tb'),
        dig: this.getSumStat(data, 'defense', 'dig'),
        re: this.getSumStat(data, 'defense', 're'),
        bhe: this.getSumStat(data, 'misc', 'bhe'),
        pts: this.getSumStat(data, 'misc', 'pts'),
        sa: this.getSumStat(data, 'serve', 'sa'),
        se: this.getSumStat(data, 'serve', 'se'),
        a: this.getSumStat(data, 'set', 'a'),
        pct: (((this.getSumStat(data, 'attack', 'k')) - (this.getSumStat(data, 'attack', 'e'))) / (this.getSumStat(data, 'attack', 'ta')))
      }
      return totals
    },
    setPlayerTotal () {
      const data = this.getAllPlayerData(this.seasonData)
      // console.log('got data in setPlayerTotal function, listed below')
      // console.log(data)
      var playerTotals = {
      }
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          var nid = data[i][j].player_nid
          if (!playerTotals[nid] && nid !== undefined) {
            var playerData = this.getPlayerData(data, nid)
            // console.log('data for player with nid ' + nid)
            // console.log(playerData)
            playerTotals[nid] = {
              name: playerData[0].name,
              number: parseInt(playerData[0].uni),
              gp: [this.getGP(playerData)],
              mpms: [this.getMP(playerData), this.getMS(playerData)], // need matches played not games played
              k: this.getSumStat(playerData, 'attack', 'k'),
              ke: this.getSumStat(playerData, 'attack', 'e'),
              kta: this.getSumStat(playerData, 'attack', 'ta'),
              ks: this.getAvg(this.getSumStat(playerData, 'attack', 'k'), this.getGP(playerData)),
              kPct: this.getPct(((this.getSumStat(playerData, 'attack', 'k')) - (this.getSumStat(playerData, 'attack', 'e'))), this.getSumStat(playerData, 'attack', 'ta')),
              a: this.getSumStat(playerData, 'set', 'a'),
              ae: this.getSumStat(playerData, 'set', 'e'),
              ata: this.getSumStat(playerData, 'set', 'ta'),
              as: this.getAvg(this.getSumStat(playerData, 'set', 'a'), this.getGP(playerData)),
              aPct: this.getPct(this.getSumStat(playerData, 'set', 'a'), this.getSumStat(playerData, 'set', 'ta')),
              sa: this.getSumStat(playerData, 'serve', 'sa'),
              ss: this.getAvg(this.getSumStat(playerData, 'serve', 'sa'), this.getGP(playerData)),
              se: this.getSumStat(playerData, 'serve', 'se'),
              sta: this.getSumStat(playerData, 'serve', 'ta'),
              sPct: this.getPct(((this.getSumStat(playerData, 'serve', 'ta')) - (this.getSumStat(playerData, 'serve', 'se'))), this.getSumStat(playerData, 'serve', 'ta')),
              re: this.getSumStat(playerData, 'defense', 're'),
              rta: this.getSumStat(playerData, 'defense', 'ta'),
              rPct: this.getPct(((this.getSumStat(playerData, 'defense', 'ta')) - (this.getSumStat(playerData, 'defense', 're'))), this.getSumStat(playerData, 'defense', 'ta')),
              dig: this.getSumStat(playerData, 'defense', 'dig'),
              digs: this.getAvg(this.getSumStat(playerData, 'defense', 'dig'), this.getGP(playerData)),
              ba: this.getSumStat(playerData, 'block', 'ba'),
              bs: this.getSumStat(playerData, 'block', 'bs'),
              total: ((this.getSumStat(playerData, 'block', 'bs')) + (this.getSumStat(playerData, 'block', 'ba'))),
              blks: this.getAvg(((this.getSumStat(playerData, 'block', 'bs')) + (this.getSumStat(playerData, 'block', 'ba'))), this.getGP(playerData)),
              be: this.getSumStat(playerData, 'block', 'be'),
              bhe: this.getSumStat(playerData, 'misc', 'bhe'),
              pts: this.getSumStat(playerData, 'misc', 'pts').toFixed(1),
              ptsAvg: this.getAvg(this.getSumStat(playerData, 'misc', 'pts'), this.getGP(playerData))
            }
          }
        }
      }
      // console.log(playerTotals)
      return playerTotals
    },
    setGameData () {
      const data = this.seasonData
      var games = []
      for (let i = 0; i < data.length; i++) {
        games.push({
          nid: data[i].nid,
          match: this.getOpponent(data[i]),
          date: data[i].vbgame.venue.date,
          dateFull: new Date(data[i].event_date.split('T')[0]),
          score: this.getScore(data[i]),
          stats: this.getBYUGameData(data[i])
        }
        )
      }
      // sorts games by date
      const sortedGames = games.sort((a, b) => a.dateFull - b.dateFull)
      // console.log('games after sort')
      // console.log(sortedGames)
      return sortedGames
    },
    getOppData (data) {
      var dataOpp = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].vbgame.team[0].id !== 'BYU' && data[i].vbgame.team[0].id !== 'BY' && data[i].vbgame.team[0].name !== 'Brigham Young University') {
          // console.log('the first team was the opponent')
          dataOpp[i] = (data[i].vbgame.team[0].totals)
        } else {
          // console.log('the second team was the opponent')
          dataOpp[i] = (data[i].vbgame.team[1].totals)
        }
      }
      // console.log(dataOpp)
      return dataOpp
    },
    getPlayerData (data, nid) {
      var playerData = []
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          if (data[i][j].player_nid === nid) {
            playerData.push(data[i][j])
          }
        }
      }
      //   console.log(playerData)
      return playerData
    },
    getBYUGameData (data) {
      var statsBYU = null
      if (data.vbgame.team[0].id === 'BYU' || data.vbgame.team[0].id === 'BY' || data.vbgame.team[0].name === 'Brigham Young University') {
        statsBYU = data.vbgame.team[0].totals
      } else if (data.vbgame.team[1].id === 'BYU' || data.vbgame.team[1].id === 'BY' || data.vbgame.team[1].name === 'Brigham Young University') {
        statsBYU = data.vbgame.team[1].totals
      }
      return statsBYU
    },
    getSumStat (data, categoryKey, statsKey) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i][categoryKey] && data[i][categoryKey][statsKey]) {
          total += parseFloat(data[i][categoryKey][statsKey])
        } else {
          // console.log(statsKey + ' not found in ' + categoryKey)
        }
      }
      return total
    },
    getPct (num, denom) {
      if (denom === 0) return null
      const total = (num / denom).toFixed(3)
      return total
    },
    getAvg (num, denom) {
      if (denom === 0) return null
      const total = (num / denom).toFixed(2)
      return total
    },
    getTotal (num, denom, num2) {
      if (denom === 0) return null
      const total = ((num / denom) + parseInt(num2)).toFixed(1)
      return total
    },
    getMS (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].ms) {
          total += parseInt(data[i].ms)
        }
      }
      return total
    },
    getMP (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gp && parseInt(data[i].gp) > 0) {
          total++
        }
      }
      return total
    },
    getGP (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gp) {
          total += parseInt(data[i].gp)
        }
      }
      return total
    },
    getOpponent (data) {
      var oppName = ''
      if ((data.vbgame.venue.homeid !== 'BYU' && data.vbgame.venue.homeid !== 'BY' && data.vbgame.venue.homename !== 'Brigham Young University') && data.vbgame.venue.neutralgame !== 'Y') {
        // console.log(data.vbgame.venue.homeid)
        // console.log(data.vbgame.venue.neutralgame)
        oppName = oppName + '@ '
      }
      oppName = oppName + data.title
      return oppName
    },
    getScore (data) {
      var win = false
      var score = new Array(2)
      if ((data.vbgame.team[0].id === 'BYU') || (data.vbgame.team[0].id === 'BY') || (data.vbgame.team[0].name === 'Brigham Young University')) {
        score[0] = parseInt(data.vbgame.team[0].linescore.score)
        score[1] = parseInt(data.vbgame.team[1].linescore.score)
      } else if ((data.vbgame.team[1].id === 'BYU') || (data.vbgame.team[1].id === 'BY') || (data.vbgame.team[1].name === 'Brigham Young University')) {
        score[0] = parseInt(data.vbgame.team[1].linescore.score)
        score[1] = parseInt(data.vbgame.team[0].linescore.score)
      } else {
        // console.log('error while getting score in game below')
        // console.log(data)
        return null
      }
      if (score[0] > score[1]) {
        win = true
      }
      score = score[0] + '-' + score[1]
      return [score, win]
    },
    getAllPlayerData (data) {
      var AllPlayerData = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].vbgame.team[0].id === 'BYU' || data[i].vbgame.team[0].id === 'BY' || data[i].vbgame.team[0].name === 'Brigham Young University') {
          AllPlayerData[i] = (data[i].vbgame.team[0].player)
        } else {
          AllPlayerData[i] = (data[i].vbgame.team[1].player)
        }
      }
      // console.log('AllPlayerData: ')
      // console.log(AllPlayerData)
      return AllPlayerData
    },
    getRecord (data) {
      var record = {
        overall: {
          wins: 0,
          losses: 0
        },
        home: {
          wins: 0,
          losses: 0
        },
        away: {
          wins: 0,
          losses: 0
        },
        neutral: {
          wins: 0,
          losses: 0
        },
        wcc: {
          wins: 0,
          losses: 0
        }
      }
      var byuScore = 0
      var oppScore = 0
      for (let i = 0; i < data.length; i++) {
        if ((data[i].vbgame.team[0].id === 'BYU') || (data[i].vbgame.team[0].id === 'BY') || (data[i].vbgame.team[0].name === 'Brigham Young University')) {
          byuScore = parseInt(data[i].vbgame.team[0].linescore.score)
          oppScore = parseInt(data[i].vbgame.team[1].linescore.score)
        } else {
          byuScore = parseInt(data[i].vbgame.team[1].linescore.score)
          oppScore = parseInt(data[i].vbgame.team[0].linescore.score)
        }
        // checks overall winner
        if (byuScore > oppScore) {
          record.overall.wins++
          // checks location
          if ((data[i].vbgame.venue.homeid === 'BYU' || data[i].vbgame.venue.homeid === 'BY' || data[i].vbgame.venue.homename === 'Brigham Young University') && (!data[i].vbgame.venue.neutralgame || data[i].vbgame.venue.neutralgame === 'N')) {
            record.home.wins++
          } else if ((data[i].vbgame.venue.visid === 'BYU' || data[i].vbgame.venue.visid === 'BY' || data[i].vbgame.venue.visname === 'Brigham Young University') && (!data[i].vbgame.venue.neutralgame || data[i].vbgame.venue.neutralgame === 'N')) {
            record.away.wins++
          } else if (data[i].vbgame.venue.neutralgame === 'Y') {
            record.neutral.wins++
          }
          if (data[i].vbgame.venue.leaguegame === 'Y') {
            record.wcc.wins++
          }
        } else if (oppScore > byuScore) {
          record.overall.losses++
          // checks location
          if ((data[i].vbgame.venue.homeid === 'BYU' || data[i].vbgame.venue.homeid === 'BY' || data[i].vbgame.venue.homename === 'Brigham Young University') && (!data[i].vbgame.venue.neutralgame || data[i].vbgame.venue.neutralgame === 'N')) {
            record.home.losses++
          } else if ((data[i].vbgame.venue.visid === 'BYU' || data[i].vbgame.venue.visid === 'BY' || data[i].vbgame.venue.visname === 'Brigham Young University') && (!data[i].vbgame.venue.neutralgame || data[i].vbgame.venue.neutralgame === 'N')) {
            record.away.losses++
          } else if (data[i].vbgame.venue.neutralgame === 'Y') {
            record.neutral.losses++
          }
          if (data[i].vbgame.venue.leaguegame === 'Y') {
            record.wcc.losses++
          }
        }
      }
      return record
    }
  }
}
</script>
