<template>
 <h5>Pitcher</h5>
  <div class="career-and-season">
    <div class="table-responsive">
      <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
        <thead>
          <tr>
            <th colspan="22">Career & Season Stats</th>
          </tr>
          <tr>
            <th>Year</th>
            <th>GP</th>
            <th>GS</th>
            <th>W</th>
            <th>L</th>
            <th>CG</th>
            <th>SHO</th>
            <th>SV</th>
            <th>IP</th>
            <th>H</th>
            <th>R</th>
            <th>ER</th>
            <th>BB</th>
            <th>SO</th>
            <th>2B</th>
            <th>3B</th>
            <th>HR</th>
            <th>AB</th>
            <th>HBP</th>
            <th>AVG</th>
            <th>ERA</th>
            <th>WHIP</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(totals,index) in totalsByYear" :key="index">
            <td class="col-emphasize">{{ totals.schedule_year }}</td>
            <td v-if="totals.gp">{{ totals.gp }}</td><td v-else>-</td>
            <td v-if="totals.gs">{{ totals.gs }}</td><td v-else>-</td>
            <td v-if="totals.result && totals.result.win">{{ totals.result.win }}</td><td v-else>-</td>
            <td v-if="totals.result && totals.result.loss">{{ totals.result.loss }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.cg">{{ totals.pitching.cg }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.sho">{{ totals.pitching.sho }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.save">{{ totals.pitching.save }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.ip">{{ totals.pitching.ip }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.h">{{ totals.pitching.h }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.r">{{ totals.pitching.r }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.er">{{ totals.pitching.er }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.bb">{{ totals.pitching.bb }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.so">{{ totals.pitching.so }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.double">{{ totals.pitching.double }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.triple">{{ totals.pitching.triple }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.hr">{{ totals.pitching.hr }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.ab">{{ totals.pitching.ab }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.hbp">{{ totals.pitching.hbp }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.h && totals.pitching.ab">{{ totals.pitching.avg }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.er && totals.pitching.ip">{{ totals.pitching.era }}</td><td v-else>-</td>
            <td v-if="totals.pitching && totals.pitching.h && totals.pitching.bb && totals.pitching.ip">{{ totals.pitching.whip }} </td><td v-else>-</td>
          </tr>
          <tr v-if="careerTotals" class="row-emphasize">
            <td>TOTALS:</td>
            <td>{{ careerTotals.gp }}</td>
            <td>{{ careerTotals.gs }}</td>
            <td v-if="careerTotals.result && careerTotals.result.win">{{ careerTotals.result.win }}</td><td v-else>-</td>
            <td v-if="careerTotals.result && careerTotals.result.loss">{{ careerTotals.result.loss }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.cg">{{ careerTotals.pitching.cg }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.sho">{{ careerTotals.pitching.sho }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.save">{{ careerTotals.pitching.save }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.ip">{{ careerTotals.pitching.ip }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.h">{{ careerTotals.pitching.h }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.r">{{ careerTotals.pitching.r }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.er">{{ careerTotals.pitching.er }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.bb">{{ careerTotals.pitching.bb }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.so">{{ careerTotals.pitching.so }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.double">{{ careerTotals.pitching.double }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.triple">{{ careerTotals.pitching.triple }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.hr">{{ careerTotals.pitching.hr }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.ab">{{ careerTotals.pitching.ab }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.hbp">{{ careerTotals.pitching.hbp }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.h && careerTotals.pitching.ab">{{ careerTotals.pitching.avg }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.er && careerTotals.pitching.ip">{{ careerTotals.pitching.era }}</td><td v-else>-</td>
            <td v-if="careerTotals.pitching && careerTotals.pitching.h && careerTotals.pitching.bb && careerTotals.pitching.ip">{{ careerTotals.pitching.whip }}</td><td v-else>-</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-for="(year, index) in gameYears" :key="index">
      <div class="table-responsive mt-3">
        <table class=" table-striped table-sm table-condensed table table-hover table-bordered total_up">
          <thead>
            <tr>
              <th>{{ year }} Game - Opponent</th>
              <th>Date</th>
              <th>Result</th>
              <th>IP</th>
              <th>H</th>
              <th>R</th>
              <th>ER</th>
              <th>BB</th>
              <th>SO</th>
              <th>2B</th>
              <th>3B</th>
              <th>HR</th>
              <th>AB</th>
              <th>HBP</th>
              <th>AVG</th>
              <th>ERA</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(game, _id) in gamesFilterEventsByYear(year)" :key="_id">
              <td nowrap style="text-align: left" class="col-emphasize">
                <span v-if="game.venue.stadium  != 'LaVell Edwards' && game.venue.neutralgame != 'Y' ">@</span>{{game.title}}
              </td>
              <td nowrap>{{ formDateStr(game.event_date) }}</td>
              <td nowrap v-if="(game.byu_score - 0) > (game.opp_score - 0)"><b style="color: green">W </b> {{ game.byu_score }}-{{ game.opp_score }}</td>
              <td nowrap v-if="(game.byu_score - 0) == (game.opp_score - 0)"><b style="color: blue">T </b> {{ game.byu_score }}-{{ game.opp_score }}</td>
              <td nowrap v-if="(game.byu_score - 0) < (game.opp_score - 0)"><b style="color: red">L </b> {{ game.byu_score }}-{{ game.opp_score }}</td>
              <td v-if="game.pitching && game.pitching.ip" nowrap>{{ game.pitching.ip }}</td><td v-else>-</td>
              <td v-if="game.pitching && game.pitching.h" nowrap>{{ game.pitching.h }}</td><td v-else>-</td>
              <td v-if="game.pitching && game.pitching.r" nowrap>{{ game.pitching.r }}</td><td v-else>-</td>
              <td v-if="game.pitching && game.pitching.er" nowrap>{{ game.pitching.er }}</td><td v-else>-</td>
              <td v-if="game.pitching && game.pitching.bb" nowrap>{{ game.pitching.bb }}</td><td v-else>-</td>
              <td v-if="game.pitching && game.pitching.so" nowrap>{{ game.pitching.so }}</td><td v-else>-</td>
              <td v-if="game.pitching && game.pitching.double" nowrap>{{ game.pitching.double }}</td><td v-else>-</td>
              <td v-if="game.pitching && game.pitching.triple" nowrap>{{ game.pitching.triple }}</td><td v-else>-</td>
              <td v-if="game.pitching && game.pitching.hr" nowrap>{{ game.pitching.hr }}</td><td v-else>-</td>
              <td v-if="game.pitching && game.pitching.ab" nowrap>{{ game.pitching.ab }}</td><td v-else>-</td>
              <td v-if="game.pitching && game.pitching.hbp" nowrap>{{ game.pitching.hbp }}</td><td v-else>-</td>
              <!-- AVG -->
              <td v-if="game.pitching && game.pitching.h && game.pitching.ab && game.pitching.ab !=='0'" nowrap>{{ (game.pitching.h / game.pitching.ab).toFixed(2) }}</td>
              <td v-else-if="game.pitching && game.pitching.h && game.pitching.ab">0.00</td>
              <td v-else>-</td>
              <!-- ERA -->
              <td v-if="game.pitching && game.pitching.er" nowrap>{{ getERA((game.pitching.er - 0), (game.pitching.innings_pitched - 0)) }}</td><td v-else>-</td>

            </tr>
            <tr v-for="(totals,index) in getTotalsByYear(year)" :key="index" class="row-emphasize">
              <td>TOTALS:</td>
              <td>&nbsp;</td>
              <td></td>
              <td>{{ totals.pitching.ip }}</td>
              <td>{{ totals.pitching.h }}</td>
              <td>{{ totals.pitching.r }}</td>
              <td>{{ totals.pitching.er }}</td>
              <td>{{ totals.pitching.bb }}</td>
              <td>{{ totals.pitching.so }}</td>
              <td>{{ totals.pitching.double }}</td>
              <td>{{ totals.pitching.triple }}</td>
              <td>{{ totals.pitching.hr }}</td>
              <td>{{ totals.pitching.ab }}</td>
              <td>{{ totals.pitching.hbp }}</td>
              <td v-if="totals.pitching && totals.pitching.avg">{{ totals.pitching.avg }}</td><td v-else>-</td>
              <td v-if="totals.pitching && totals.pitching.era">{{ totals.pitching.era }}</td><td v-else>-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Infielder',
  props: ['selected', 'gamesRecordPlayerInCleared', 'gameYears'],
  data () {
    return {
      // For UI to format dates
      months: [
        'Jan.', // "January",
        'Feb.', // "February",
        'Mar.', // "March",
        'Apr.', // "April",
        'May.', // "May",
        'Jun.', // "June",
        'Jul.', // "July",
        'Aug.', // "August",
        'Sep.', // "September",
        'Oct.', // "October",
        'Nov.', // "November",
        'Dec.' // "December",
      ],
      totalsByYear: [],
      careerTotals: [],
      innings: null
    }
  },
  computed: {
    // do something
  },
  mounted () {
    this.innings = this.setInnings(this.selected.sport)
    this.calTotalsByYear(this.gameYears)
    // console.log(this.careerTotals.pitching.ip)
  },
  methods: {
    formDateStr (str) {
      var date = new Date(str)
      // need to double check if area different then show different data
      date.setHours(date.getHours() - 6)
      var dateStr = this.months[date.getMonth()] + ' ' + date.getDate()
      return dateStr
    },
    gamesFilterEventsByYear (year) {
      const games = this.gamesRecordPlayerInCleared.filter(game => game.schedule_year === year).sort(this.dateSort)
      for (var i = 0; i < games.length; i++) {
        if (!games[i].pitching) games[i].pitching = {}
        if (!games[i].pitching.ip) {
          games[i].pitching.innings_pitched = this.getInnings_pitched(0)
        } else {
          games[i].pitching.innings_pitched = this.getInnings_pitched(games[i].pitching.ip)
        }
      }
      return games
    },
    getInnings_pitched (ip) {
      ip = ip.toString()
      if (ip.indexOf('.1') !== -1) {
        return ip.split('.')[0] + '.3334'
      } else if (ip.indexOf('.2') !== -1) {
        return ip.split('.')[0] + '.6667'
      } else {
        return ip
      }
    },
    dateSort (a, b) {
      if (a.event_date < b.event_date) {
        return -1
      }
      if (a.event_date > b.event_date) {
        return 1
      }
      return 0
    },
    getTotal (games) {
      var ip = this.getIP(games)
      var winloss = this.getWinLoss(games)
      const gamesTotal = {
        gs: this.getGS(games),
        gp: this.getGP(games),
        result: {
          win: winloss[0],
          loss: winloss[1]
        },
        pitching: {
          ip: ip[0],
          h: this.getSumStats(games, 'pitching', 'h'),
          r: this.getSumStats(games, 'pitching', 'r'),
          er: this.getSumStats(games, 'pitching', 'er'),
          bb: this.getSumStats(games, 'pitching', 'bb'),
          so: this.getSumStats(games, 'pitching', 'so'),
          double: this.getSumStats(games, 'pitching', 'double'),
          triple: this.getSumStats(games, 'pitching', 'triple'),
          hr: this.getSumStats(games, 'pitching', 'hr'),
          ab: this.getSumStats(games, 'pitching', 'ab'),
          hbp: this.getSumStats(games, 'pitching', 'hbp'),
          innings_pitched: this.getInnings_pitched(this.getSumStats(games, 'pitching', 'ip')),
          save: this.getSave(games),
          sho: this.getSumStats(games, 'pitching', 'sho'),
          cg: this.getSumStats(games, 'pitching', 'cg'),
          avg: this.getPct(this.getSumStats(games, 'pitching', 'h'), this.getSumStats(games, 'pitching', 'ab')),
          // <>{{ (totals.pitching.er / totals.pitching.innings_pitched * 9).toFixed(2) }}</th>
          era: this.getPct((this.innings * this.getSumStats(games, 'pitching', 'er')), ip[1]),
          whip: this.getPct((this.getSumStats(games, 'pitching', 'bb') + this.getSumStats(games, 'pitching', 'h')), ip[1])
        }
      }

      return gamesTotal
    },
    calTotalsByYear (years) {
      for (let i = 0; i < years.length; i++) {
        const gamesByYear = this.gamesFilterEventsByYear(years[i])
        // console.log('Games for year ' + years[i])
        // console.log(gamesByYear)
        const gamesTotalByYear = this.getTotal(gamesByYear)
        gamesTotalByYear.schedule_year = years[i]
        this.totalsByYear.push(gamesTotalByYear)
      }
      this.calCareerTotals(this.totalsByYear)
    },
    calCareerTotals (games) {
      if (games) {
        this.careerTotals = this.getTotal(games)
        this.careerTotals.pitching.save = this.getSumStats(games, 'pitching', 'save')
        this.careerTotals.result.win = this.getSumStats(games, 'result', 'win')
        this.careerTotals.result.loss = this.getSumStats(games, 'result', 'loss')
      }
    },
    getGS (games) {
      let total = 0
      let counter = 0
      for (let i = 0; i < games.length; i++) {
        if (games[i] && games[i].gs) {
          total += games[i].gs - 0
        } else {
          counter++
        }
      }
      if (counter !== games.length) {
        return total
      } else {
        return 0
      }
    },
    getGP (games) {
      let total = 0
      let counter = 0
      for (let i = 0; i < games.length; i++) {
        if (games[i] && games[i].gp) {
          total += games[i].gp - 0
        } else {
          counter++
        }
      }
      if (counter !== games.length) {
        return total
      } else {
        return null
      }
    },
    getWinGame (games) {
      let counter = 0
      for (let i = 0; i < games.length; i++) {
        if ((parseInt(games[i].byu_score) > parseInt(games[i].opp_score)) && (parseInt(games[i].gp) > 0)) counter += 1
      }
      return counter
    },
    getLoseGame (games) {
      let counter = 0
      for (let i = 0; i < games.length; i++) {
        if ((parseInt(games[i].byu_score) < parseInt(games[i].opp_score)) && (parseInt(games[i].gp) > 0)) counter += 1
      }
      return counter
    },
    getSave (games) {
      for (let i = games.length - 1; i >= 0; i--) {
        if (games[i] && games[i].pitching && games[i].pitching.save) {
          return games[i].pitching.save
        }
      }
    },
    getWinLoss (games) {
      var wl
      for (let i = games.length - 1; i >= 0; i--) {
        if (games[i] && games[i].pchseason && games[i].pchseason.win && games[i].pchseason.loss) {
          wl = [games[i].pchseason.win, games[i].pchseason.loss]
          // console.log(wl)
          return wl
        } else if (games[i] && games[i].pitching && games[i].pitching.win) {
          if ((games[i].pitching.win).indexOf('-') !== -1) {
            wl = games[i].pitching.win.split('-')
          } else {
            wl = [games[i].pitching.win, 0]
          }
          // console.log(wl)
          return wl
        }
      }
      return new Array(2)
    },
    getSumStats (data, categoryKey, statsKey) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i][categoryKey] && data[i][categoryKey][statsKey]) {
          total += parseInt(data[i][categoryKey][statsKey])
        } else {
          // console.log(statsKey + ' not found in ' + categoryKey)
        }
      }
      return total
    },
    getTotalsByYear (year) {
      return this.totalsByYear.filter(total => total.schedule_year === year)
    },
    getIP (data) {
      var total = 0.0
      var decimal = 0.0
      var totalCalc = 0.0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].pitching && data[i].pitching.ip) {
          var ip = data[i].pitching.ip.toString()
          if (ip.indexOf('.') !== -1) {
            total += parseFloat(ip.split('.')[0])
            var addDecimal = ip.split('.')[1]
            if (addDecimal === '1') {
              decimal += (1 / 3)
            } else if (addDecimal === '2') {
              decimal += (2 / 3)
            } else if (addDecimal === '0') {
              decimal += (0 / 3) // i know this does nothing but im desperate
            }
          } else {
            total += parseFloat(ip)
          }
        }
      }
      totalCalc = total + decimal
      // console.log('total decimal remainder: ' + decimal)
      if (decimal !== 0.0) {
        decimal = (decimal.toString()).split('.')
        if ((decimal[1]) && (decimal[1][0] === '3')) {
          total += (parseFloat(decimal[0]) + 0.1)
        } else if ((decimal[1]) && (decimal[1][0] === '6')) {
          total += (parseFloat(decimal[0]) + 0.2)
        } else if ((decimal[1]) && (decimal[1][0] === '9')) {
          total += (parseFloat(decimal[0]) + 1)
        } else {
          total += parseFloat(decimal[0])
        }
      }
      return [total, totalCalc]
    },
    getAVG (numer, denom) {
      if (denom === 0) return null
      const avg = (numer / denom).toFixed(2)
      return avg
    },
    getERA (er, inningsPitched) {
      const ip = parseFloat(inningsPitched)
      if (ip === 0) return 0
      const era = (er / ip * 9).toFixed(2)
      return era
    },
    getPct (num, denom) {
      num = parseFloat(num)
      denom = parseFloat(denom)
      if (denom === 0) return 0
      const total = (num / denom)
      return total.toFixed(3)
    },
    setInnings (sport) {
      var innings = 9
      if (sport === '1712') {
        innings = 7
      }
      return innings
    }
  }
}
</script>

<style scoped>

thead {
  color: white;
  background: #003FA2;
}

</style>
