<template>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan="100%">Season Record</th>
        </tr>
        <tr>
          <th>Record</th>
          <th>Overall</th>
          <th>Home</th>
          <th>Away</th>
          <th>Neutral</th>
          <th>Conference</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="col-emphasize">ALL GAMES</td>
          <td>{{ this.record.overall.wins }} - {{ this.record.overall.losses }} - {{ this.record.overall.ties }}</td>
          <td>{{ this.record.home.wins }} - {{ this.record.home.losses }} - {{ this.record.home.ties }}</td>
          <td>{{ this.record.away.wins }} - {{ this.record.away.losses }} - {{ this.record.away.ties }}</td>
          <td>{{ this.record.neutral.wins }} - {{ this.record.neutral.losses }} - {{ this.record.neutral.ties }}</td>
          <td>{{ this.record.wcc.wins }} - {{ this.record.wcc.losses }} - {{ this.record.wcc.ties }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan="100%">Team Statistics</th>
        </tr>
        <tr>
          <th colspan="1"></th>
          <th colspan="1">BYU</th>
          <th colspan="1">Opp</th>
        </tr>
      </thead>
      <tbody>
        <tr class="row-emphasize row-label">
          <th colspan="1">Shooting</th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <td>Goals / Shot Attempts</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.g && this.totalBYU.shots.sh">{{ this.totalBYU.shots.g }} / {{ this.totalBYU.shots.sh }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.g && this.totalOpp.shots.sh">{{ this.totalOpp.shots.g }} / {{ this.totalOpp.shots.sh }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Goals Per Game</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.g && this.totalBYU.gpgs">{{ this.getAvg(this.totalBYU.shots.g, this.totalBYU.gpgs) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.g && this.totalOpp.gpgs">{{ this.getAvg(this.totalOpp.shots.g, this.totalOpp.gpgs) }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Shot Percentage</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.g && this.totalBYU.shots.sh">{{ ((this.totalBYU.shots.g / this.totalBYU.shots.sh) * 100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.g && this.totalOpp.shots.sh">{{ ((this.totalOpp.shots.g / this.totalOpp.shots.sh) * 100).toFixed(1) }}%</td><td v-else>-</td>
        </tr>
        <tr>
          <td>SOG / Attempts </td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.sog && this.totalBYU.shots.sh">{{ this.totalBYU.shots.sog }} / {{ this.totalBYU.shots.sh }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.sog && this.totalOpp.shots.sh">{{ this.totalOpp.shots.sog }} / {{ this.totalOpp.shots.sh }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>SOG Percentage</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.sog && this.totalBYU.shots.sh">{{ ((this.totalBYU.shots.sog / this.totalBYU.shots.sh) * 100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.sog && this.totalOpp.shots.sh">{{ ((this.totalOpp.shots.sog / this.totalOpp.shots.sh) * 100).toFixed(1) }}%</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Shots Per Game</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.sh && this.totalBYU.gpgs">{{ this.getAvg(this.totalBYU.shots.sh, this.totalBYU.gpgs) }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.sh && this.totalOpp.gpgs">{{ this.getAvg(this.totalOpp.shots.sh, this.totalOpp.gpgs) }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Assists</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.a">{{ this.totalBYU.shots.a }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.a">{{ this.totalOpp.shots.a }}</td><td v-else>-</td>
        </tr>
      </tbody>
      <tbody>
        <tr class="row-emphasize row-label">
          <th>Goals</th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <td>Total</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.g">{{ this.totalBYU.shots.g }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.g">{{ this.totalOpp.shots.g }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Penalty</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.ps">{{ this.totalBYU.shots.ps }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.ps">{{ this.totalOpp.shots.ps }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Unassisted</td>
          <td v-if="this.totalBYU && this.totalBYU.goaltype && this.totalBYU.goaltype.ua">{{ this.totalBYU.goaltype.ua }}</td>
          <td v-if="this.totalOpp && this.totalOpp.goaltype && this.totalOpp.goaltype.ua">{{ this.totalOpp.goaltype.ua }}</td>
        </tr>
        <tr>
          <td>Overtime</td>
          <td v-if="this.totalBYU && this.totalBYU.goaltype && this.totalBYU.goaltype.ot">{{ this.totalBYU.goaltype.ot }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.goaltype && this.totalOpp.goaltype.ot">{{ this.totalOpp.goaltype.ot }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize row-label">
          <td>Corner Kicks</td>
          <td>{{ this.overallTeamStatistics.cornersbyPeriods[0].total }}</td>
          <td>{{ this.overallTeamStatistics.cornersbyPeriods[1].total }}</td>
        </tr>
        <tr>
          <td>Goals off corners</td>
          <td>?</td>
          <td>?</td>
        </tr>
      </tbody>
      <tbody>
        <tr class="row-emphasize row-label">
          <td>Penalty Kicks / Attempts</td>
          <td v-if="this.overallTeamStatistics && this.overallTeamStatistics.teamstatistics && this.overallTeamStatistics.teamstatistics.goalbreakdown[0]">{{ this.overallTeamStatistics.teamstatistics.goalbreakdown[0].penalty }} / {{ this.overallTeamStatistics.teamstatistics.goalbreakdown[0].penaltyAttempts }}</td><td v-else>-</td>
          <td v-if="this.overallTeamStatistics && this.overallTeamStatistics.teamstatistics && this.overallTeamStatistics.teamstatistics.goalbreakdown[1]">{{ this.overallTeamStatistics.teamstatistics.goalbreakdown[1].penalty }}-{{ this.overallTeamStatistics.teamstatistics.goalbreakdown[1].penaltyAttempts }}</td><td v-else>-</td>
        </tr>
      </tbody>
      <tbody>
        <tr class="row-emphasize row-label">
          <td>Offsides</td>
          <td v-if="this.overallTeamStatistics && this.overallTeamStatistics.offsidesbyPeriods[0]">{{ this.overallTeamStatistics.offsidesbyPeriods[0].total }}</td><td v-else>-</td>
          <td v-if="this.overallTeamStatistics && this.overallTeamStatistics.offsidesbyPeriods[1]">{{ this.overallTeamStatistics.offsidesbyPeriods[1].total }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize row-label">
          <td>Penalties</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Fouls</td>
          <td v-if="this.totalBYU && this.totalBYU.penalty && this.totalBYU.penalty.fouls">{{ this.totalBYU.penalty.fouls }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.penalty && this.totalOpp.penalty.fouls">{{ this.totalOpp.penalty.fouls }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Yellow cards</td>
          <td v-if="this.totalBYU && this.totalBYU.penalty && this.totalBYU.penalty.yellow">{{ this.totalBYU.penalty.yellow }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.penalty && this.totalOpp.penalty.yellow">{{ this.totalOpp.penalty.yellow }}</td><td v-else>-</td>
        </tr>
        <tr>
          <td>Red cards</td>
          <td v-if="this.totalBYU && this.totalBYU.penalty && this.totalBYU.penalty.red">{{ this.totalBYU.penalty.red }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.penalty && this.totalOpp.penalty.red">{{ this.totalOpp.penalty.red }}</td><td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive mt-3" v-if="showData">
    <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
      <thead>
        <tr>
          <th colspan="100%">Game Statistics</th>
        </tr>
        <tr class="header-label">
          <th colspan="4"></th>
          <th colspan="12">Shooting</th>
          <th colspan="3">Penalties</th>
        </tr>
        <tr>
          <th>Opponent</th>
          <th>Date</th>
          <th>Result</th>
          <th>Score</th>
          <th>G</th>
          <th>A</th>
          <th>Pts</th>
          <th>Sh</th>
          <th>Sh %</th>
          <th>SOG</th>
          <th>SOG %</th>
          <th>GW</th>
          <th>GT</th>
          <th>UA</th>
          <th>OT</th>
          <th>PK / Att</th>
          <th>Foul</th>
          <th>YC</th>
          <th>RC</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(totals,index) in totalGames" :key="index">
          <td v-if="totals && totals.match" class="col-emphasize">{{ totals.match }}</td><td v-else>-</td>
          <td v-if="totals && totals.date">{{ totals.date }}</td><td v-else>-</td>
          <td v-if="totals && totals.score[1]" style="color: green">W</td>
          <td v-if="totals && !totals.score[1] && !totals.score[2]" style="color: red">L</td>
          <td v-if="totals && totals.score[2]" style="color: blue">T</td>
          <td v-if="totals && totals.score[0]">{{ totals.score[0] }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.shots && totals.stats.shots.g">{{ totals.stats.shots.g }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.shots && totals.stats.shots.a">{{ totals.stats.shots.a }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.shots && totals.stats.shots.pts">{{ totals.stats.shots.pts }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.shots && totals.stats.shots.sh">{{ totals.stats.shots.sh }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.shots && totals.stats.shots.sh">{{ ((totals.stats.shots.g / totals.stats.shots.sh)*100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.shots && totals.stats.shots.sog">{{ totals.stats.shots.sog }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.shots && totals.stats.shots.sh">{{ ((totals.stats.shots.sog / totals.stats.shots.sh)*100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.goaltype && totals.stats.goaltype.gw">{{ totals.stats.goaltype.gw }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.goaltype && totals.stats.goaltype.gt">{{ totals.stats.goaltype.gt }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.goaltype && totals.stats.goaltype.ua">{{ totals.stats.goaltype.ua }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.goaltype && totals.stats.goaltype.ot">{{ totals.stats.goaltype.ot }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.shots && totals.stats.shots.ps && totals.stats.shots.psatt">{{ totals.stats.shots.ps }} / {{ totals.stats.shots.psatt }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.penalty && totals.stats.penalty.fouls">{{ totals.stats.penalty.fouls }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.penalty && totals.stats.penalty.yellow">{{ totals.stats.penalty.yellow }}</td><td v-else>-</td>
          <td v-if="totals && totals.stats && totals.stats.penalty && totals.stats.penalty.red">{{ totals.stats.penalty.red }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="4">Total</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.g">{{ this.totalBYU.shots.g }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.a">{{ this.totalBYU.shots.a }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.pts">{{ this.totalBYU.shots.pts }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.sh">{{ this.totalBYU.shots.sh }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.sh">{{ ((this.totalBYU.shots.g / this.totalBYU.shots.sh)*100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.sog">{{ this.totalBYU.shots.sog }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.sh">{{ ((this.totalBYU.shots.sog / this.totalBYU.shots.sh)*100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.goaltype && this.totalBYU.goaltype.gw">{{ this.totalBYU.goaltype.gw }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.goaltype && this.totalBYU.goaltype.gt">{{ this.totalBYU.goaltype.gt }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.goaltype && this.totalBYU.goaltype.ua">{{ this.totalBYU.goaltype.ua }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU.goaltype && this.totalBYU.goaltype.ot">{{ this.totalBYU.goaltype.ot }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.ps && this.totalBYU.shots.psatt">{{ this.totalBYU.shots.ps }} / {{ this.totalBYU.shots.psatt }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU && this.totalBYU.penalty && this.totalBYU.penalty.fouls">{{ this.totalBYU.penalty.fouls }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU && this.totalBYU.penalty && this.totalBYU.penalty.yellow">{{ this.totalBYU.penalty.yellow }}</td><td v-else>-</td>
          <td v-if="this.totalBYU && this.totalBYU && this.totalBYU.penalty && this.totalBYU.penalty.red">{{ this.totalBYU.penalty.red }}</td><td v-else>-</td>
        </tr>
        <tr class="row-emphasize">
          <td colspan="4">Opponents</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.g">{{ this.totalOpp.shots.g }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.a">{{ this.totalOpp.shots.a }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.pts">{{ this.totalOpp.shots.pts }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.sh">{{ this.totalOpp.shots.sh }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.sh">{{ ((this.totalOpp.shots.g / this.totalOpp.shots.sh)*100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.sog">{{ this.totalOpp.shots.sog }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.sh">{{ ((this.totalOpp.shots.sog / this.totalOpp.shots.sh)*100).toFixed(1) }}%</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.goaltype && this.totalOpp.goaltype.gw">{{ this.totalOpp.goaltype.gw }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.goaltype && this.totalOpp.goaltype.gt">{{ this.totalOpp.goaltype.gt }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.goaltype && this.totalOpp.goaltype.ua">{{ this.totalOpp.goaltype.ua }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp.goaltype && this.totalOpp.goaltype.ot">{{ this.totalOpp.goaltype.ot }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.ps && this.totalOpp.shots.psatt">{{ this.totalOpp.shots.ps }} / {{ this.totalOpp.shots.psatt }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp && this.totalOpp.penalty && this.totalOpp.penalty.fouls">{{ this.totalOpp.penalty.fouls }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp && this.totalOpp.penalty && this.totalOpp.penalty.yellow">{{ this.totalOpp.penalty.yellow }}</td><td v-else>-</td>
          <td v-if="this.totalOpp && this.totalOpp && this.totalOpp.penalty && this.totalOpp.penalty.red">{{ this.totalOpp.penalty.red }}</td><td v-else>-</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- 6 Periods -->
  <div v-if="showData">
    <div class="row">
      <div class="table-responsive col-sm-6">
        <table class="table-sm table-condensed table table-hover table-bordered total_up">
          <thead>
            <tr>
              <th colspan="100%">Goals by Period</th>
            </tr>
            <tr>
              <th>Team</th>
              <th>1st</th>
              <th>2nd</th>
              <th>OT1</th>
              <th>OT2</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(stats, index) in this.overallTeamStatistics.goalsbyPeriods" :key="index">
              <td v-if="stats && stats.team" class="col-emphasize"> {{ stats.team }}</td><td v-else>-</td>
              <td v-if="stats && stats.first"> {{ stats.first }}</td><td v-else>-</td>
              <td v-if="stats && stats.second"> {{ stats.second }}</td><td v-else>-</td>
              <td v-if="stats && stats.ot1"> {{ stats.ot1 }} </td><td v-else>-</td>
              <td v-if="stats && stats.ot2"> {{ stats.ot2 }} </td><td v-else>-</td>
              <td v-if="stats && stats.total" class="row-emphasize"> {{ stats.total }}</td><td v-else>-</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-responsive col-sm-6">
        <table class="table-sm table-condensed table table-hover table-bordered total_up">
          <thead>
            <tr>
              <th colspan="100%">Corners by Period</th>
            </tr>
            <tr>
              <th>Team</th>
              <th>1st</th>
              <th>2nd</th>
              <th>OT1</th>
              <th>OT2</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(stats, index) in this.overallTeamStatistics.cornersbyPeriods" :key="index">
              <td v-if="stats && stats.team" class="col-emphasize"> {{ stats.team }}</td><td v-else>-</td>
              <td v-if="stats && stats.first"> {{ stats.first }}</td><td v-else>-</td>
              <td v-if="stats && stats.second"> {{ stats.second }}</td><td v-else>-</td>
              <td v-if="stats && stats.ot1"> {{ stats.ot1 }} </td><td v-else>-</td>
              <td v-if="stats && stats.ot2"> {{ stats.ot2 }} </td><td v-else>-</td>
              <td v-if="stats && stats.total" class="row-emphasize"> {{ stats.total }}</td><td v-else>-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive col-sm-6">
        <table class="table-sm table-condensed table table-hover table-bordered total_up">
          <thead>
            <tr>
              <th colspan="100%">Shots by Period</th>
            </tr>
            <tr>
              <th>Team</th>
              <th>1st</th>
              <th>2nd</th>
              <th>OT1</th>
              <th>OT2</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(stats, index) in this.overallTeamStatistics.shotsbyPeriods" :key="index">
              <td v-if="stats && stats.team" class="col-emphasize"> {{ stats.team }}</td><td v-else>-</td>
              <td v-if="stats && stats.first"> {{ stats.first }}</td><td v-else>-</td>
              <td v-if="stats && stats.second"> {{ stats.second }}</td><td v-else>-</td>
              <td v-if="stats && stats.ot1"> {{ stats.ot1 }} </td><td v-else>-</td>
              <td v-if="stats && stats.ot2"> {{ stats.ot2 }} </td><td v-else>-</td>
              <td v-if="stats && stats.total" class="row-emphasize"> {{ stats.total }}</td><td v-else>-</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-responsive col-sm-6">
        <table class="table-sm table-condensed table table-hover table-bordered total_up">
          <thead>
            <tr>
              <th colspan="100%">Fouls by Period</th>
            </tr>
            <tr>
              <th>Team</th>
              <th>1st</th>
              <th>2nd</th>
              <th>OT1</th>
              <th>OT2</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(stats, index) in this.overallTeamStatistics.foulsbyPeriods" :key="index">
              <td v-if="stats && stats.team" class="col-emphasize"> {{ stats.team }}</td><td v-else>-</td>
              <td v-if="stats && stats.first"> {{ stats.first }}</td><td v-else>-</td>
              <td v-if="stats && stats.second"> {{ stats.second }}</td><td v-else>-</td>
              <td v-if="stats && stats.ot1"> {{ stats.ot1 }} </td><td v-else>-</td>
              <td v-if="stats && stats.ot2"> {{ stats.ot2 }} </td><td v-else>-</td>
              <td v-if="stats && stats.total" class="row-emphasize"> {{ stats.total }}</td><td v-else>-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="table-responsive col-sm-6">
        <table class="table-sm table-condensed table table-hover table-bordered total_up">
          <thead>
            <tr>
              <th colspan="100%">Saves by Period</th>
            </tr>
            <tr>
              <th>Team</th>
              <th>1st</th>
              <th>2nd</th>
              <th>OT1</th>
              <th>OT2</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(stats, index) in this.overallTeamStatistics.savesbyPeriods" :key="index">
              <td v-if="stats && stats.team" class="col-emphasize"> {{ stats.team }}</td><td v-else>-</td>
              <td v-if="stats && stats.first"> {{ stats.first }}</td><td v-else>-</td>
              <td v-if="stats && stats.second"> {{ stats.second }}</td><td v-else>-</td>
              <td v-if="stats && stats.ot1"> {{ stats.ot1 }} </td><td v-else>-</td>
              <td v-if="stats && stats.ot2"> {{ stats.ot2 }} </td><td v-else>-</td>
              <td v-if="stats && stats.total" class="row-emphasize"> {{ stats.total }}</td><td v-else>-</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-responsive col-sm-6">
        <table class="table-sm table-condensed table table-hover table-bordered total_up">
          <thead>
            <tr>
              <th colspan="100%">Offsides by Period</th>
            </tr>
            <tr>
              <th>Team</th>
              <th>1st</th>
              <th>2nd</th>
              <th>OT1</th>
              <th>OT2</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(stats, index) in this.overallTeamStatistics.offsidesbyPeriods" :key="index">
              <td v-if="stats && stats.team" class="col-emphasize"> {{ stats.team }}</td><td v-else>-</td>
              <td v-if="stats && stats.first"> {{ stats.first }}</td><td v-else>-</td>
              <td v-if="stats && stats.second"> {{ stats.second }}</td><td v-else>-</td>
              <td v-if="stats && stats.ot1"> {{ stats.ot1 }} </td><td v-else>-</td>
              <td v-if="stats && stats.ot2"> {{ stats.ot2 }} </td><td v-else>-</td>
              <td v-if="stats && stats.total" class="row-emphasize"> {{ stats.total }}</td><td v-else>-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="table-responsive mt-3" v-if="showData">
      <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
        <thead>
          <tr>
            <th colspan=100%>Offensive Player Statistics</th>
          </tr>
          <tr class="header-label">
            <th colspan="3"></th>
            <th colspan="12">Shooting</th>
            <th colspan="3">Penalty</th>
          </tr>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>GP-GS</th>
            <th>G</th>
            <th>A</th>
            <th>Pts</th>
            <th>Sh</th>
            <th>Sh %</th>
            <th>SOG</th>
            <th>SOG %</th>
            <th>GW</th>
            <th>GT</th>
            <th>UA</th>
            <th>OT</th>
            <th>PK / Att</th>
            <th>Foul</th>
            <th>YC</th>
            <th>RC</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player,index) in orderedOffense" :key="index">
            <td v-if="player && player.number" class="col-emphasize">{{ player.number }}</td><td v-else>-</td>
            <td v-if="player && player.name" class="col-emphasize">{{ player.name }}</td><td v-else>-</td>
            <td v-if="player && player.gpgs">{{ player.gpgs[0] }}-{{ player.gpgs[1] }}</td><td v-else>-</td>
            <td v-if="player && player.stats.shots && player.stats.shots.g">{{ player.stats.shots.g }}</td><td v-else>-</td>
            <td v-if="player && player.stats.shots && player.stats.shots.a">{{ player.stats.shots.a }}</td><td v-else>-</td>
            <td v-if="player && player.stats.shots && player.stats.shots.pts">{{ player.stats.shots.pts }}</td><td v-else>-</td>
            <td v-if="player && player.stats.shots && player.stats.shots.sh">{{ player.stats.shots.sh }}</td><td v-else>-</td>
            <td v-if="player && player.stats.shots && player.stats.shots.sh">{{ ((player.stats.shots.g / player.stats.shots.sh) * 100).toFixed(1) }}%</td><td v-else>-</td>
            <td v-if="player && player.stats.shots && player.stats.shots.sog">{{ player.stats.shots.sog }}</td><td v-else>-</td>
            <td v-if="player && player.stats.shots && player.stats.shots.sh">{{ ((player.stats.shots.sog / player.stats.shots.sh) * 100).toFixed(1) }}%</td><td v-else>-</td>
            <td v-if="player && player.stats.goaltype && player.stats.goaltype.gw">{{ player.stats.goaltype.gw }}</td><td v-else>-</td>
            <td v-if="player && player.stats.goaltype && player.stats.goaltype.gt">{{ player.stats.goaltype.gt }}</td><td v-else>-</td>
            <td v-if="player && player.stats.goaltype && player.stats.goaltype.ua">{{ player.stats.goaltype.ua }}</td><td v-else>-</td>
            <td v-if="player && player.stats.goaltype && player.stats.goaltype.ot">{{ player.stats.goaltype.ot }}</td><td v-else>-</td>
            <td v-if="player && player.stats.shots && player.stats.shots.psatt">{{ player.stats.shots.ps }} / {{ player.stats.shots.psatt }}</td><td v-else>-</td>
            <td v-if="player && player.stats.penalty && player.stats.penalty.fouls">{{ player.stats.penalty.fouls }}</td><td v-else>-</td>
            <td v-if="player && player.stats.penalty && player.stats.penalty.yellow">{{ player.stats.penalty.yellow }}</td><td v-else>-</td>
            <td v-if="player && player.stats.penalty && player.stats.penalty.red">{{ player.stats.penalty.red }}</td><td v-else>-</td>
          </tr>
          <tr class="row-emphasize">
            <td colspan="2">Total</td>
            <td v-if="this.totalBYU && this.totalBYU.gpgs">{{ this.totalBYU.gpgs }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.g">{{ this.totalBYU.shots.g }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.a">{{ this.totalBYU.shots.a }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.pts">{{ this.totalBYU.shots.pts }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.sh">{{ this.totalBYU.shots.sh }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.sh">{{ ((this.totalBYU.shots.g / this.totalBYU.shots.sh)*100).toFixed(1) }}%</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.sog">{{ this.totalBYU.shots.sog }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.sh">{{ ((this.totalBYU.shots.sog / this.totalBYU.shots.sh)*100).toFixed(1) }}%</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.goaltype && this.totalBYU.goaltype.gw">{{ this.totalBYU.goaltype.gw }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.goaltype && this.totalBYU.goaltype.gt">{{ this.totalBYU.goaltype.gt }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.goaltype && this.totalBYU.goaltype.ua">{{ this.totalBYU.goaltype.ua }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.goaltype && this.totalBYU.goaltype.ot">{{ this.totalBYU.goaltype.ot }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU && this.totalBYU.shots && this.totalBYU.shots.ps && this.totalBYU.shots.psatt">{{ this.totalBYU.shots.ps }} / {{ this.totalBYU.shots.psatt }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU && this.totalBYU.penalty && this.totalBYU.penalty.fouls">{{ this.totalBYU.penalty.fouls }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU && this.totalBYU.penalty && this.totalBYU.penalty.yellow">{{ this.totalBYU.penalty.yellow }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU && this.totalBYU.penalty && this.totalBYU.penalty.red">{{ this.totalBYU.penalty.red }}</td><td v-else>-</td>
          </tr>
          <tr class="row-emphasize">
            <td colspan="2">Opponents</td>
            <td v-if="this.totalOpp && this.totalOpp.gpgs">{{ this.totalOpp.gpgs }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.g">{{ this.totalOpp.shots.g }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.a">{{ this.totalOpp.shots.a }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.pts">{{ this.totalOpp.shots.pts }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.sh">{{ this.totalOpp.shots.sh }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.sh">{{ ((this.totalOpp.shots.g / this.totalOpp.shots.sh)*100).toFixed(1) }}%</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.sog">{{ this.totalOpp.shots.sog }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.sh">{{ ((this.totalOpp.shots.sog / this.totalOpp.shots.sh)*100).toFixed(1) }}%</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.goaltype && this.totalOpp.goaltype.gw">{{ this.totalOpp.goaltype.gw }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.goaltype && this.totalOpp.goaltype.gt">{{ this.totalOpp.goaltype.gt }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.goaltype && this.totalOpp.goaltype.ua">{{ this.totalOpp.goaltype.ua }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.goaltype && this.totalOpp.goaltype.ot">{{ this.totalOpp.goaltype.ot }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp && this.totalOpp.shots && this.totalOpp.shots.ps && this.totalOpp.shots.psatt">{{ this.totalOpp.shots.ps }} / {{ this.totalOpp.shots.psatt }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp && this.totalOpp.penalty && this.totalOpp.penalty.fouls">{{ this.totalOpp.penalty.fouls }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp && this.totalOpp.penalty && this.totalOpp.penalty.yellow">{{ this.totalOpp.penalty.yellow }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp && this.totalOpp.penalty && this.totalOpp.penalty.red">{{ this.totalOpp.penalty.red }}</td><td v-else>-</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive mt-3" v-if="showData">
      <table class="table-striped table-sm table-condensed table table-hover table-bordered total_up">
        <thead>
          <tr>
            <th colspan=100%>Goalie Player Statistics</th>
          </tr>
          <tr>
            <th>#</th>
            <th>Player</th>
            <th>GP-GS</th>
            <th>Minutes</th>
            <th>GA</th>
            <th>Saves</th>
            <th>Saves %</th>
            <!-- <th>W</th>
            <th>L</th>
            <th>T</th>
            <th>SHO / CBO</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(player,index) in orderedGoalie" :key="index">
            <td v-if="player && player.number" class="col-emphasize">{{ player.number }}</td><td v-else>-</td>
            <td v-if="player && player.name" class="col-emphasize">{{ player.name }}</td><td v-else>-</td>
            <td v-if="player && player.gpgs">{{ player.gpgs[0] }}-{{ player.gpgs[1] }}</td><td v-else>-</td>
            <td v-if="player && player.stats.goalie && player.stats.goalie.minutes">{{ player.stats.goalie.minutes }}</td><td v-else>-</td>
            <td v-if="player && player.stats.goalie && player.stats.goalie.ga">{{ player.stats.goalie.ga }}</td><td v-else>-</td>
            <td v-if="player && player.stats.goalie && player.stats.goalie.saves">{{ player.stats.goalie.saves }}</td><td v-else>-</td>
            <td v-if="player && player.stats.goalie && player.stats.goalie.saves">{{ ((player.stats.goalie.saves / (player.stats.goalie.ga + player.stats.goalie.saves))*100).toFixed(1) }}%</td><td v-else>-</td>
            <!-- <td v-if="player && player.stats.goalie && player.stats.goalie.win">{{ player.stats.goalie.win }}</td><td v-else>-</td>
            <td v-if="player && player.stats.goalie && player.stats.goalie.loss">{{ player.stats.goalie.loss }}</td><td v-else>-</td>
            <td v-if="player && player.stats.goalie && player.stats.goalie.tie">{{ player.stats.goalie.tie }}</td><td v-else>-</td>
            <td v-if="player && player.stats.goalie && player.stats.goalie.cbosho && player.stats.goalie.shutout">{{ player.stats.goalie.shutout }} / {{ player.stats.goalie.cbosho }}</td><td v-else-if="player && player.stats.goalie && player.stats.goalie.cbosho">0 / {{ player.stats.goalie.cbosho }}</td><td v-else-if="player && player.stats.goalie && player.stats.goalie.shutout">{{ player.stats.goalie.shutout }} / 0</td><td v-else>-</td> -->
          </tr>
          <tr class="row-emphasize">
            <td colspan="2">Total</td>
            <td v-if="this.totalBYU && this.totalBYU.gpgs">{{ this.totalBYU.gpgs }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.goalie && this.totalBYU.goalie.minutes">{{ this.totalBYU.goalie.minutes }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.goalie && this.totalBYU.goalie.ga">{{ this.totalBYU.goalie.ga }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.goalie && this.totalBYU.goalie.saves">{{ this.totalBYU.goalie.saves }}</td><td v-else>-</td>
            <td v-if="this.totalBYU && this.totalBYU.goalie && this.totalBYU.goalie.saves">{{ ((this.totalBYU.goalie.saves / (this.totalBYU.goalie.ga + this.totalBYU.goalie.saves))*100).toFixed(1) }}%</td><td v-else>-</td>
          </tr>
          <tr class="row-emphasize">
            <td colspan="2">Opponents</td>
            <td v-if="this.totalOpp && this.totalOpp.gpgs">{{ this.totalOpp.gpgs }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.goalie && this.totalOpp.goalie.minutes">{{ this.totalOpp.goalie.minutes }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.goalie && this.totalOpp.goalie.ga">{{ this.totalOpp.goalie.ga }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.goalie && this.totalOpp.goalie.saves">{{ this.totalOpp.goalie.saves }}</td><td v-else>-</td>
            <td v-if="this.totalOpp && this.totalOpp.goalie && this.totalOpp.goalie.saves">{{ ((this.totalOpp.goalie.saves / (this.totalOpp.goalie.ga + this.totalOpp.goalie.saves))*100).toFixed(1) }}%</td><td v-else>-</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  props: ['selected', 'gameYears', 'seasonData'],
  data () {
    return {
      totalBYU: null,
      totalOpp: null,
      totalPlayers: null,
      offPlayers: null,
      totalGames: null,
      overallTeamStatistics: null,
      record: null,
      showData: false
    }
  },
  mounted () {
    // console.log(this.seasonData)
    this.record = this.getRecord(this.fixData(this.seasonData))
    this.totalBYU = this.setTeamTotal(this.getBYUData, this.getOppData)
    this.totalOpp = this.setTeamTotal(this.getOppData, this.getBYUData)
    // console.log(this.totalBYU)
    // console.log(this.totalOpp)
    this.totalPlayers = this.setPlayerTotal()
    // console.log('totalplayers below')
    // console.log(this.totalPlayers)
    this.offPlayers = this.filterPlayers(this.totalPlayers, 'shots')
    // console.log(this.offPlayers)
    this.goaliePlayers = this.filterPlayers(this.totalPlayers, 'goalie')
    // console.log(this.goaliePlayers)
    this.totalGames = this.setGameData()
    this.overallTeamStatistics = this.getOverallTeamStatistics(this.seasonData)
    // console.log(this.overallTeamStatistics)
    this.showData = true
  },
  computed: {
    orderedOffense: function () {
      return _.orderBy(this.offPlayers, 'number')
    },
    orderedGoalie: function () {
      return _.orderBy(this.goaliePlayers, 'number')
    }
  },
  methods: {
    getOverallTeamStatistics (data) {
      const overallTeamStatistics = {
        goalsbyPeriods: this.getPeriods(data, 'score'),
        cornersbyPeriods: this.getPeriods(data, 'corners'),
        shotsbyPeriods: this.getPeriods(data, 'shots'),
        foulsbyPeriods: this.getPeriods(data, 'fouls'),
        savesbyPeriods: this.getPeriods(data, 'saves'),
        offsidesbyPeriods: this.getPeriods(data, 'offsides'),
        teamstatistics: this.getTeamstatistics(data)
      }

      return overallTeamStatistics
    },
    getPeriods (data, statsKey) {
      const byu = {
        team: 'BYU',
        total: 0,
        first: 0,
        second: 0,
        ot1: 0,
        ot2: 0
      }
      const oppo = {
        team: 'Opponents',
        total: 0,
        first: 0,
        second: 0,
        ot1: 0,
        ot2: 0
      }
      if (statsKey === 'shots') {
        byu.a = 0
        byu.sog = 0
        oppo.a = 0
        oppo.sog = 0
      } else if (statsKey === 'fouls') {

      }

      for (let i = 0; i < data.length; i++) { // n games
        const byuTeamIndex = data[i].sogame.team.findIndex(x => (x.id === 'BYU' || x.id === 'BY' || x.name === 'BYU' || x.name === 'Brigham Young University' || x.name === 'BY')) // find index in array
        const oppoTeamIndex = data[i].sogame.team.findIndex(x => (x.id !== 'BYU' && x.id !== 'BY' && x.name !== 'BYU' && x.name !== 'Brigham Young University' && x.name !== 'BY')) // find index in array

        byu.first += data[i].sogame.team[byuTeamIndex].linescore.lineprd[0][statsKey] - 0
        byu.second += data[i].sogame.team[byuTeamIndex].linescore.lineprd[1][statsKey] - 0
        byu.ot1 += (data[i].sogame.team[byuTeamIndex].linescore.lineprd[2]) ? data[i].sogame.team[byuTeamIndex].linescore.lineprd[2][statsKey] - 0 : 0
        byu.ot2 += (data[i].sogame.team[byuTeamIndex].linescore.lineprd[3]) ? data[i].sogame.team[byuTeamIndex].linescore.lineprd[3][statsKey] - 0 : 0

        oppo.first += data[i].sogame.team[oppoTeamIndex].linescore.lineprd[0][statsKey] - 0
        oppo.second += data[i].sogame.team[oppoTeamIndex].linescore.lineprd[1][statsKey] - 0
        oppo.ot1 += (data[i].sogame.team[oppoTeamIndex].linescore.lineprd[2]) ? data[i].sogame.team[oppoTeamIndex].linescore.lineprd[2][statsKey] - 0 : 0
        oppo.ot2 += (data[i].sogame.team[oppoTeamIndex].linescore.lineprd[3]) ? data[i].sogame.team[oppoTeamIndex].linescore.lineprd[3][statsKey] - 0 : 0

        // other totals stats
        if (statsKey === 'score') {
          byu.total += data[i].sogame.team[byuTeamIndex].linescore.score - 0
          oppo.total += data[i].sogame.team[oppoTeamIndex].linescore.score - 0
        } else if (statsKey === 'shots') {
          byu.a += data[i].sogame.team[byuTeamIndex].totals.shots.a - 0
          oppo.a += data[i].sogame.team[oppoTeamIndex].totals.shots.a - 0
          byu.sog += data[i].sogame.team[byuTeamIndex].totals.shots.sog - 0
          oppo.sog += data[i].sogame.team[oppoTeamIndex].totals.shots.sog - 0
          byu.total += data[i].sogame.team[byuTeamIndex].totals.shots.sh - 0
          oppo.total += data[i].sogame.team[oppoTeamIndex].totals.shots.sh - 0
        } else if (statsKey === 'fouls') {
          byu.total += data[i].sogame.team[byuTeamIndex].totals.penalty.fouls - 0
          oppo.total += data[i].sogame.team[oppoTeamIndex].totals.penalty.fouls - 0
        }
      }

      if (statsKey === 'corners' || statsKey === 'saves' || statsKey === 'offsides') {
        byu.total += byu.first + byu.second + byu.ot1 + byu.ot2
        oppo.total += oppo.first + oppo.second + oppo.ot1 + oppo.ot2
      }

      return [byu, oppo]
    },
    getBYUTeamIndex (game) {
      return game.sogame.team.findIndex(x => (x.id === 'BYU' || x.id === 'BY' || x.name === 'BYU' || x.name === 'Brigham Young University' || x.name === 'BY'))
    },
    getOPPOTeamIndex (game) {
      return game.sogame.team.findIndex(x => (x.id !== 'BYU' && x.id !== 'BY' && x.name !== 'BYU' && x.name !== 'Brigham Young University' && x.name !== 'BY'))
    },
    getTeamstatistics (data) {
      const teamstatistics = {}
      teamstatistics.goalbreakdown = this.getGoalbreakdown(data)
      teamstatistics.penalties = this.getPenalties(data)
      teamstatistics.attendance = this.getAttendance(data)
      return teamstatistics
    },
    getGoalbreakdown (data) {
      const byu = {
        team: 'BYU',
        penalty: 0,
        penaltyAttempts: 0,
        unassisted: 0,
        overtime: 0,
        goalsScoredAverage: 0
      }
      const oppo = {
        team: 'Opponents',
        penalty: 0,
        penaltyAttempts: 0,
        unassisted: 0,
        overtime: 0,
        goalsScoredAverage: 0
      }

      for (let i = 0; i < data.length; i++) {
        const byuTeamIndex = this.getBYUTeamIndex(data[i])
        const oppoTeamIndex = this.getOPPOTeamIndex(data[i])

        byu.penalty += data[i].sogame.team[byuTeamIndex].totals.shots.ps - 0
        byu.penaltyAttempts += data[i].sogame.team[byuTeamIndex].totals.shots.psatt - 0
        byu.unassisted += data[i].sogame.team[byuTeamIndex].totals.goaltype.ua - 0
        byu.overtime += (data[i].sogame.team[byuTeamIndex].linescore.lineprd[2]) ? data[i].sogame.team[byuTeamIndex].linescore.lineprd[2].score - 0 : 0
        byu.overtime += (data[i].sogame.team[byuTeamIndex].linescore.lineprd[3]) ? data[i].sogame.team[byuTeamIndex].linescore.lineprd[3].score - 0 : 0

        oppo.penalty += data[i].sogame.team[oppoTeamIndex].totals.shots.ps - 0
        oppo.penaltyAttempts += data[i].sogame.team[oppoTeamIndex].totals.shots.psatt - 0
        oppo.unassisted += data[i].sogame.team[oppoTeamIndex].totals.goaltype.ua - 0
        oppo.overtime += (data[i].sogame.team[oppoTeamIndex].linescore.lineprd[2]) ? data[i].sogame.team[oppoTeamIndex].linescore.lineprd[2].score - 0 : 0
        oppo.overtime += (data[i].sogame.team[oppoTeamIndex].linescore.lineprd[3]) ? data[i].sogame.team[oppoTeamIndex].linescore.lineprd[3].score - 0 : 0
      }

      return [byu, oppo]
    },
    getPenalties (data) {
      const byu = {
        team: 'BYU',
        yellow: 0,
        red: 0
      }
      const oppo = {
        team: 'Opponents',
        yellow: 0,
        red: 0
      }

      for (let i = 0; i < data.length; i++) {
        const byuTeamIndex = this.getBYUTeamIndex(data[i])
        const oppoTeamIndex = this.getOPPOTeamIndex(data[i])

        byu.yellow += data[i].sogame.team[byuTeamIndex].totals.penalty.yellow - 0
        byu.red += data[i].sogame.team[byuTeamIndex].totals.penalty.red - 0

        oppo.yellow += data[i].sogame.team[oppoTeamIndex].totals.penalty.yellow - 0
        oppo.red += data[i].sogame.team[oppoTeamIndex].totals.penalty.red - 0
      }

      return [byu, oppo]
    },
    getAttendance (data) {
      const byu = {
        team: 'BYU',
        host: 0,
        total: 0,
        neutralTotal: 0
      }
      const oppo = {
        team: 'Opponents',
        host: 0,
        total: 0
      }
      const neutral = {
        total: 0,
        host: 0
      }

      const homeGames = data.filter(game => game.sogame.venue.homename === 'BYU' || game.sogame.venue.homeid === 'BYU')
      // for (let i = 0; i < homeGames.length; i++) console.log(homeGames[i].sogame.venue.homeid) show how many game host by BYU
      const awayGames = data.filter(game => game.sogame.venue.homename !== 'BYU' && game.sogame.venue.neutralgame === 'N')
      const neutralGames = data.filter(game => game.sogame.venue.homename !== 'BYU' && game.sogame.venue.neutralgame === 'Y')

      byu.total = this.getSumAttend(homeGames, 'venue', 'attend')
      byu.host = homeGames.length

      oppo.total = this.getSumAttend(awayGames, 'venue', 'attend')
      oppo.host = awayGames.length

      neutral.total = this.getSumAttend(neutralGames, 'venue', 'attend')
      neutral.host = neutralGames.length

      return [byu, oppo, neutral]
    },
    getSumAttend (games, categoryKey, statsKey) {
      let total = 0
      for (let i = 0; i < games.length; i++) {
        if (games[i] && games[i].sogame[categoryKey] && games[i].sogame[categoryKey][statsKey]) {
          total += games[i].sogame[categoryKey][statsKey] - 0
        }
      }
      return total
    },
    // Brayden code starts here
    setPlayerTotal () {
      const data = this.getAllPlayerData(this.fixData(this.seasonData))
      // console.log('got data in setPlayerTotal function, listed below')
      // console.log(data)
      var playerTotals = {
      }
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          var nid = data[i][j].player_nid
          if (!playerTotals[nid] && nid !== undefined && Number.isInteger(nid)) {
            var playerData = this.getPlayerData(data, nid)
            // console.log('data for player with nid ' + nid)
            playerTotals[nid] = {
              name: playerData[0].name,
              number: parseInt(playerData[0].uni),
              positions: this.getPos(playerData),
              gpgs: [this.getGP(playerData), this.getGS(playerData)],
              stats: {
                // empty objects to be populated in below for loop. If no stats are found for player, object will be deleted later
                goalie: {},
                goaltype: {},
                penalty: {},
                shots: {}
              }
            }
            for (let x = 0; x < playerData.length; x++) {
              for (var item in playerData[x]) { // iterates through each key in each player's game data
                if (typeof playerData[x][item] === 'object' && playerTotals[nid].stats[item]) { // if the key is an object (indicating that its a stat category), it processes that stat category object
                  for (var key in playerData[x][item]) {
                    if (!playerTotals[nid].stats[item][key]) { // checks if each stat key exists before running sum function on it
                      playerTotals[nid].stats[item][key] = this.getSumStat(playerData, item, key)
                    }
                  }
                } else if (typeof playerData[x][item] === 'object') {
                  // console.log('item missing: ' + item)
                }
              }
            }
          }
        }
      }
      // clears empty stat objects out of each player's stats
      for (var player in playerTotals) {
        for (var statType in playerTotals[player].stats) {
          if (Object.keys(playerTotals[player].stats[statType]).length === 0) {
            delete playerTotals[player].stats[statType]
          } else {
            let statTotal = 0
            for (var stat in playerTotals[player].stats[statType]) {
              statTotal += parseInt(playerTotals[player].stats[statType][stat])
            }
            if (statTotal === 0) {
              delete playerTotals[player].stats[statType]
            }
          }
        }
        if (playerTotals[player].stats.goalie) {
          playerTotals[player].stats.goalie.minutes = this.getMinutes(this.getPlayerData(data, player))
        }
      }
      // console.log(playerTotals)
      return playerTotals
    },
    getAllPlayerData (data) {
      var AllPlayerData = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].sogame.team[0].id === 'BYU' || data[i].sogame.team[0].id === 'BY' || data[i].sogame.team[0].name === 'Brigham Young University') {
          AllPlayerData[i] = (data[i].sogame.team[0].player)
        } else {
          AllPlayerData[i] = (data[i].sogame.team[1].player)
        }
      }
      // console.log('AllPlayerData: ')
      // console.log(AllPlayerData)
      return AllPlayerData
    },
    getPlayerData (data, nid) {
      var playerData = []
      nid = parseInt(nid)
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          if (data[i][j].player_nid === nid) {
            playerData.push(data[i][j])
          }
        }
      }
      //   console.log(playerData)
      return playerData
    },
    filterPlayers (data, category) {
      var players = {}
      for (var player in data) {
        if (data[player].stats[category]) {
          players[player] = data[player]
        }
      }
      return players
    },
    setTeamTotal (getHomeData, getOppData) {
      const data = getHomeData(this.fixData(this.seasonData))
      //   const oppData = getOppData(this.fixData(this.seasonData))
      //   console.log('data and oppdata below')
      //   console.log(data)
      //   console.log(oppData)
      const totals = {
        gpgs: data.length,
        shots: {},
        goaltype: {},
        penalty: {},
        goalie: {}
      }
      for (let x = 0; x < data.length; x++) {
        for (var item in data[x]) { // iterates through each key in each player's game data
          if (typeof data[x][item] === 'object' && totals[item]) { // if the key is an object (indicating that its a stat category), it processes that stat category object
            for (var key in data[x][item]) {
              if (!totals[item][key]) { // checks if each stat key exists before running sum function on it
                totals[item][key] = this.getSumStat(data, item, key)
              }
            }
          } else if (typeof data[x][item] === 'object') {
            // console.log('item missing: ' + item)
          }
        }
      }
      totals.goalie.minutes = this.getMinutes(data)
      return totals
    },
    getBYUData (data) {
      var dataBYU = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].sogame.team[0].id === 'BYU' || data[i].sogame.team[0].id === 'BY' || data[i].sogame.team[0].name === 'Brigham Young University') {
          // console.log('the first team was byu')
          dataBYU[i] = (data[i].sogame.team[0].totals)
        } else {
          // console.log('the second team was byu')
          dataBYU[i] = (data[i].sogame.team[1].totals)
        }
      }
      // console.log(dataBYU)
      return dataBYU
    },
    getOppData (data) {
      var dataOpp = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].sogame.team[0].id !== 'BYU' && data[i].sogame.team[0].id !== 'BY' && data[i].sogame.team[0].name !== 'Brigham Young University') {
          // console.log('the first team was the opponent')
          dataOpp[i] = (data[i].sogame.team[0].totals)
        } else {
          // console.log('the second team was the opponent')
          dataOpp[i] = (data[i].sogame.team[1].totals)
        }
      }
      // console.log(dataOpp)
      return dataOpp
    },
    fixData (data) {
      var fixedData = []
      for (let i = 0; i < data.length; i++) {
        // hopefully handles the issue where game arrays have an extra empty array before the actual info
        if (data[i][0]) {
          data[i] = data[i][0]
        }
        // removes all games that don't include sogame objects
        if (data[i].sogame) {
          fixedData.push(data[i])
        } else {
          // console.log('found an object without sogame')
        }
      }
      return fixedData
    },
    setGameData () {
      const data = this.fixData(this.seasonData)
      var games = []
      for (let i = 0; i < data.length; i++) {
        games.push({
          nid: data[i].nid,
          match: this.getOpponent(data[i]),
          date: data[i].sogame.venue.date,
          dateFull: new Date(data[i].event_date.split('T')[0]),
          score: this.getScore(data[i]),
          stats: this.getBYUGameData(data[i])
        }
        )
      }
      // sorts games by date
      const sortedGames = games.sort((a, b) => a.dateFull - b.dateFull)
      // console.log('games after sort')
      // console.log(sortedGames)
      return sortedGames
    },
    getBYUGameData (data) {
      var statsBYU = null
      if (data.sogame.team[0].id === 'BYU' || data.sogame.team[0].id === 'BY' || data.sogame.team[0].name === 'Brigham Young University') {
        statsBYU = data.sogame.team[0].totals
      } else if (data.sogame.team[1].id === 'BYU' || data.sogame.team[1].id === 'BY' || data.sogame.team[1].name === 'Brigham Young University') {
        statsBYU = data.sogame.team[1].totals
      }
      return statsBYU
    },
    getOpponent (data) {
      var oppName = ''
      if ((data.sogame.venue.homeid !== 'BYU' && data.sogame.venue.homeid !== 'BY' && data.sogame.venue.homename !== 'Brigham Young University') && data.sogame.venue.neutralgame !== 'Y') {
        // console.log(data.sogame.venue.homeid)
        // console.log(data.sogame.venue.neutralgame)
        oppName = oppName + '@ '
      }
      oppName = oppName + data.title
      return oppName
    },
    getScore (data) {
      var win = false
      var tie = false
      var score = {
        byuScore: {
          total: 0,
          shootout: 0
        },
        oppScore: {
          total: 0,
          shootout: 0
        }
      }
      // checks which team byu is, assigns regulation game scores
      if ((data.sogame.team[0].id === 'BYU') || (data.sogame.team[0].id === 'BY') || (data.sogame.team[0].name === 'Brigham Young University')) {
        score.byuScore.total = parseInt(data.sogame.team[0].linescore.score)
        score.oppScore.total = parseInt(data.sogame.team[1].linescore.score)
      } else if ((data.sogame.team[1].id === 'BYU') || (data.sogame.team[1].id === 'BY') || (data.sogame.team[1].name === 'Brigham Young University')) {
        score.byuScore.total = parseInt(data.sogame.team[1].linescore.score)
        score.oppScore.total = parseInt(data.sogame.team[0].linescore.score)
      } else {
        // console.log('error while getting score in game below')
        // console.log(data)
        return null
      }
      // assigns shootout totals if a shootout is needed
      if (data.sogame.shootouts) {
        for (let i = 0; i < data.sogame.shootouts.shootout.length; i++) {
          if ((data.sogame.shootouts.shootout[i].id === 'BYU' || data.sogame.shootouts.shootout[i].id === 'BY') && data.sogame.shootouts.shootout[i].result === 'MADE') {
            score.byuScore.shootout++
          } else if ((data.sogame.shootouts.shootout[i].id !== 'BYU' && data.sogame.shootouts.shootout[i].id !== 'BY') && data.sogame.shootouts.shootout[i].result === 'MADE') {
            score.oppScore.shootout++
          }
        }
      }
      // sets win to true if byu won in regulation or shootouts
      if ((score.byuScore.total > score.oppScore.total) || (score.byuScore.shootout > score.oppScore.shootout)) {
        win = true
      } else if (score.byuScore.total === score.oppScore.total && (!data.sogame.shootouts)) { // sets tie to true if scores are equal and there is no shootout (shootouts are only done in playoff games. regular season games don't have them, and can end in ties)
        tie = true
      }
      score = score.byuScore.total + '-' + score.oppScore.total
      return [score, win, tie]
    },
    getSumStat (data, categoryKey, statsKey) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i][categoryKey] && data[i][categoryKey][statsKey]) {
          total += parseInt(data[i][categoryKey][statsKey])
        } else {
          // console.log(statsKey + ' not found in ' + categoryKey)
        }
      }
      return total
    },
    getPct (num, denom) {
      if (denom === 0) return null
      const total = (num / denom).toFixed(3)
      return total
    },
    getAvg (num, denom) {
      if (denom === 0) return null
      const total = (num / denom).toFixed(1)
      return total
    },
    getPos (data) {
      // add methodology to check stats for positions that are unidentified (kicker/punter/kr/pr/oline etc)
      var positions = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].pos && positions.indexOf(data[i].pos) === -1 && data[i].pos !== '') {
          positions.push(data[i].pos)
        }
      }
      return positions
    },
    getGS (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gs) {
          total += parseInt(data[i].gs)
        }
      }
      return total
    },
    getGP (data) {
      let total = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].gp) {
          total += parseInt(data[i].gp)
        }
      }
      return total
    },
    getMinutes (data) {
      var minutes = 0
      var seconds = 0
      for (let i = 0; i < data.length; i++) {
        if (data[i] && data[i].goalie && data[i].goalie.minutes) {
          var goalieMinutes = data[i].goalie.minutes.split(':')
          minutes += parseInt(goalieMinutes[0])
          seconds += parseInt(goalieMinutes[1])
        }
      }
      minutes += Math.floor(seconds / 60)
      seconds = seconds % 60
      if (minutes !== 0) {
        // console.log(minutes + ':' + seconds)
        return (minutes + ':' + seconds).toString()
      } else {
        return null
      }
    },
    getRecord (data) {
      var record = {
        overall: {
          wins: 0,
          losses: 0,
          ties: 0
        },
        home: {
          wins: 0,
          losses: 0,
          ties: 0
        },
        away: {
          wins: 0,
          losses: 0,
          ties: 0
        },
        neutral: {
          wins: 0,
          losses: 0,
          ties: 0
        },
        wcc: {
          wins: 0,
          losses: 0,
          ties: 0
        }
      }
      var byuScore = 0
      var oppScore = 0
      for (let i = 0; i < data.length; i++) {
        if ((data[i].sogame.team[0].id === 'BYU') || (data[i].sogame.team[0].id === 'BY') || (data[i].sogame.team[0].name === 'Brigham Young University')) {
          byuScore = parseInt(data[i].sogame.team[0].linescore.score)
          oppScore = parseInt(data[i].sogame.team[1].linescore.score)
        } else {
          byuScore = parseInt(data[i].sogame.team[1].linescore.score)
          oppScore = parseInt(data[i].sogame.team[0].linescore.score)
        }
        // checks overall winner
        if (byuScore > oppScore) {
          record.overall.wins++
          // checks location
          if ((data[i].sogame.venue.homeid === 'BYU' || data[i].sogame.venue.homeid === 'BY' || data[i].sogame.venue.homename === 'Brigham Young University') && (!data[i].sogame.venue.neutralgame || data[i].sogame.venue.neutralgame === 'N')) {
            record.home.wins++
          } else if ((data[i].sogame.venue.visid === 'BYU' || data[i].sogame.venue.visid === 'BY' || data[i].sogame.venue.visname === 'Brigham Young University') && (!data[i].sogame.venue.neutralgame || data[i].sogame.venue.neutralgame === 'N')) {
            record.away.wins++
          } else if (data[i].sogame.venue.neutralgame === 'Y') {
            record.neutral.wins++
          }
          if (data[i].sogame.venue.leaguegame === 'Y') {
            record.wcc.wins++
          }
        } else if (oppScore > byuScore) {
          record.overall.losses++
          // checks location
          if ((data[i].sogame.venue.homeid === 'BYU' || data[i].sogame.venue.homeid === 'BY' || data[i].sogame.venue.homename === 'Brigham Young University') && (!data[i].sogame.venue.neutralgame || data[i].sogame.venue.neutralgame === 'N')) {
            record.home.losses++
          } else if ((data[i].sogame.venue.visid === 'BYU' || data[i].sogame.venue.visid === 'BY' || data[i].sogame.venue.visname === 'Brigham Young University') && (!data[i].sogame.venue.neutralgame || data[i].sogame.venue.neutralgame === 'N')) {
            record.away.losses++
          } else if (data[i].sogame.venue.neutralgame === 'Y') {
            record.neutral.losses++
          }
          if (data[i].sogame.venue.leaguegame === 'Y') {
            record.wcc.losses++
          }
        } else if (oppScore === byuScore) {
          if (data[i].sogame.shootouts) {
            // console.log('playoff game')
            // console.log(data[i])
            var byuSO = 0
            var oppSO = 0
            for (let q = 0; q < data[i].sogame.shootouts.shootout.length; q++) {
              if ((data[i].sogame.shootouts.shootout[q].id === 'BYU' || data[i].sogame.shootouts.shootout[q].id === 'BY') && data[i].sogame.shootouts.shootout[q].result === 'MADE') {
                byuSO++
              } else if ((data[i].sogame.shootouts.shootout[q].id !== 'BYU' || data[i].sogame.shootouts.shootout[q].id !== 'BY') && data[i].sogame.shootouts.shootout[q].result === 'MADE') {
                oppSO++
              }
            }
            if (byuSO > oppSO) {
              record.overall.wins++
              // checks location
              if ((data[i].sogame.venue.homeid === 'BYU' || data[i].sogame.venue.homeid === 'BY' || data[i].sogame.venue.homename === 'Brigham Young University') && (!data[i].sogame.venue.neutralgame || data[i].sogame.venue.neutralgame === 'N')) {
                record.home.wins++
              } else if ((data[i].sogame.venue.visid === 'BYU' || data[i].sogame.venue.visid === 'BY' || data[i].sogame.venue.visname === 'Brigham Young University') && (!data[i].sogame.venue.neutralgame || data[i].sogame.venue.neutralgame === 'N')) {
                record.away.wins++
              } else if (data[i].sogame.venue.neutralgame === 'Y') {
                record.neutral.wins++
              }
              if (data[i].sogame.venue.leaguegame === 'Y') {
                record.wcc.wins++
              }
            } else if (byuSO < oppSO) {
              record.overall.losses++
              // checks location
              if ((data[i].sogame.venue.homeid === 'BYU' || data[i].sogame.venue.homeid === 'BY' || data[i].sogame.venue.homename === 'Brigham Young University') && (!data[i].sogame.venue.neutralgame || data[i].sogame.venue.neutralgame === 'N')) {
                record.home.losses++
              } else if ((data[i].sogame.venue.visid === 'BYU' || data[i].sogame.venue.visid === 'BY' || data[i].sogame.venue.visname === 'Brigham Young University') && (!data[i].sogame.venue.neutralgame || data[i].sogame.venue.neutralgame === 'N')) {
                record.away.losses++
              } else if (data[i].sogame.venue.neutralgame === 'Y') {
                record.neutral.losses++
              }
              if (data[i].sogame.venue.leaguegame === 'Y') {
                record.wcc.losses++
              }
            }
          } else {
            // console.log('tie game')
            // console.log(data[i])
            record.overall.ties++
            if ((data[i].sogame.venue.homeid === 'BYU' || data[i].sogame.venue.homeid === 'BY' || data[i].sogame.venue.homename === 'Brigham Young University') && (!data[i].sogame.venue.neutralgame || data[i].sogame.venue.neutralgame === 'N')) {
              record.home.ties++
            } else if ((data[i].sogame.venue.visid === 'BYU' || data[i].sogame.venue.visid === 'BY' || data[i].sogame.venue.visname === 'Brigham Young University') && (!data[i].sogame.venue.neutralgame || data[i].sogame.venue.neutralgame === 'N')) {
              record.away.ties++
            } else if (data[i].sogame.venue.neutralgame === 'Y') {
              record.neutral.ties++
            }
          }
        }
      }
      return record
    }
  }
}
</script>
