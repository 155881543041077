<template>
  <div class="stats mb-5">
    <query
      :tempAthletesUrl="tempAthletesUrl"
      :tempGamesUrl="tempGamesUrl"
      :tempYearsUrl="tempYearsUrl"
      :tempCoachUrl="tempCoachUrl"
      v-for="(query, index) in queries"
      v-bind:key="query.key"
      @remove="removeQuery(index)"
    />
    <div class="text-center mt-5">
      <button id="qButton" type="button" class="btn btn-outline-primary" @click="add();">New Search <svg width="2rem" height="2rem" viewBox="0 0 16 16" class="bi bi-plus-square-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>
      </button>
    </div>
  </div>
</template>

<script>
import Query from '@/components/Query.vue'

// https://byucougars.byu-dept-athletics-dev.amazon.byu.edu/feeds/sport-years/sport_nid
// Ex: https://byucougars.byu-dept-athletics-dev.amazon.byu.edu/feeds/sport-years/1701
var tempYearsUrl = 'https://byucougars.com/feeds/sport-years'
var tempAthletesUrl = 'https://byucougars.com/feeds/athlete/year'
var tempGamesUrl = 'https://byucougars.com/feeds/game/year'
var tempCoachUrl = 'https://byucougars.com/feeds/coach/year'
if (window.location.href.includes('dev') || window.location.href.includes('file') || window.location.href.includes('localhost')) {
  tempYearsUrl = 'https://byucougars.byu-dept-athletics-dev.amazon.byu.edu/feeds/sport-years'
  tempAthletesUrl = 'https://byucougars.byu-dept-athletics-dev.amazon.byu.edu/feeds/athlete/year'
  tempGamesUrl = 'https://byucougars.byu-dept-athletics-dev.amazon.byu.edu/feeds/game/year'
  tempCoachUrl = 'https://byucougars.byu-dept-athletics-dev.amazon.byu.edu/feeds/coach/year'
}

// https://byucougars.com/feeds/athlete/year/sport_nid/year
// Ex: https://byucougars.com/feeds/athlete/year/1698/2014

// https://byucougars.com/feeds/game/year/sport_nid/year
// Ex: https://byucougars.com/feeds/game/year/1698/2014

export default {

  name: 'Statistics',
  data () {
    return {
      queries: [Query],
      uniqueKey: 0,
      tempAthletesUrl: tempAthletesUrl,
      tempGamesUrl: tempGamesUrl,
      tempYearsUrl: tempYearsUrl,
      tempCoachUrl: tempCoachUrl,
      athletes: []
    }
  },
  components: {
    Query
  },
  methods: {
    add () {
      // let quantity = this.events.length
      this.queries.push({
        key: this.uniqueKey++,
        query: Query
      })
    },
    removeQuery (index) {
      this.queries.splice(index, 1)
    }
  }
}
</script>

<style scoped>
#qButton {
  color: #003FA2;
  background-color: white;
  margin-top: 5%;
  font-size: 2rem;
}

#qButton:hover {
  color: white;
  background-color: #003FA2;
}
</style>
